import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import { addMenuVariant, editMenuVariant } from '../../../../../redux/actions/settingMenu';
import { FieldError } from '../../../../components/MessageToUser/MessageToUser';

const MenuVariantCreator = ({ closeModal }) => {
  const menuVariantInfo = useSelector((state) => state.menuSetting.menuVariantInfo);
  const serverError = useSelector((state) => state.messageToUser.errorMessage);
  const [localError, setLocalError] = useState({ message: '', type: '' });
  const error = localError.message ? localError : serverError;

  const dispatch = useDispatch();
  const [chapterName, setChapterName] = useState(menuVariantInfo ? menuVariantInfo.name : '');
  const onCreateVariant = async () => {
    if (!chapterName) setLocalError({ message: 'Введите название', type: 'createChapter' });
    else {
      let res;
      if (menuVariantInfo) {
        res = await dispatch(editMenuVariant(chapterName, menuVariantInfo.id));
      } else {
        res = await dispatch(addMenuVariant(chapterName));
      }
      if (res) closeModal();
    }
  };
  return (
    <div className="">
      <div className="modal-modifier__title ">
        {menuVariantInfo ? 'Редактирование раздела меню' : 'Создать новый раздел'}
      </div>
      <div className="popup-menu__form ">
        <input
          value={chapterName}
          onChange={(e) => setChapterName(e.target.value)}
          placeholder="Название"
          type="text "
          className="popup-menu__textform positionCreator"
        />
      </div>
      <div className="popup-menu__button-inner ">
        <div className="popup__button " onClick={onCreateVariant}>
          <a className="popup__btn ">{menuVariantInfo ? 'Сохранить' : 'Добавить'}</a>
        </div>
        <div className="popup__button popup__button--cancel" onClick={closeModal}>
          <a className="popup__btn ">Отмена</a>
        </div>
      </div>
      {error.type === 'createChapter' && (
        <FieldError message={error.message} y={0} setErrorMessage={setLocalError} />
      )}
    </div>
  );
};

export default React.memo(MenuVariantCreator);
