import React from 'react';

export const LinkIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8189 7.63619V11.4544C10.8189 11.7919 10.6848 12.1156 10.4461 12.3543C10.2074 12.593 9.88371 12.7271 9.54617 12.7271H2.54616C2.20862 12.7271 1.88489 12.593 1.64621 12.3543C1.40753 12.1156 1.27344 11.7919 1.27344 11.4544V4.45437C1.27344 4.11682 1.40753 3.7931 1.64621 3.55441C1.88489 3.31573 2.20862 3.18164 2.54616 3.18164H6.36435"
        stroke="#1E293B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.9082 1.27295H12.7264V5.09113"
        stroke="#1E293B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.72656 8.27295L12.7266 1.27295"
        stroke="#1E293B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
