import React, { useState } from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTakeAwayDesk } from '../../../../../redux/actions/setting';
import st from '../../../../LK/qrPage/QrCodeCreator/QrCodeCreator.module.css';

const SelectTakeawayMenuTable = ({ closeModal }) => {
  const dispatch = useDispatch();
  const [checkedDesk, setCheckedDesk] = useState('');
  const sections = useSelector((state) => state.setting.desks);
  const { settings } = useSelector((state) => state.organisation.data.info);

  function onSaveTakeAwayDesk() {
    const body = {
      desk_unit_id: checkedDesk,
    };
    dispatch(selectTakeAwayDesk(body));
    closeModal();
  }

  useMemo(() => {
    if (Array.isArray(sections) && sections.length) {
      for (const elem of sections) {
        const takeawayTable = elem?.desks.find((desk) => desk?.is_takeaway_table);
        if (takeawayTable?.is_takeaway_table) {
          setCheckedDesk(takeawayTable?.id);
          break;
        }
      }
    }
  }, [sections]);

  return (
    <>
      <h1 className={st.title}>
        Выберите {settings?.org_type === 2 ? 'номер' : 'стол'} для работы Меню навынос
      </h1>
      {(Array.isArray(sections) && (
        <div>
          {sections.map((item, i) => {
            if (item?.desks.length && item?.id)
              return (
                <div className="popup__qr-inner" key={i}>
                  <div className={`popup__qr-title ${st.sectionName}`}>{item.name}</div>
                  <div className={st.section}>
                    {item.desks.map((desk, i) => (
                      <div className={st.col_item} key={i} onClick={() => setCheckedDesk(desk?.id)}>
                        <div className={st.col_item_text}>{desk?.num}</div>
                        <input
                          readOnly
                          checked={checkedDesk === desk?.id}
                          type="checkbox"
                          className={st.radioBtn}
                          id={desk.id}
                        />
                        <label className="custom-radio" htmlFor={desk?.id} />
                      </div>
                    ))}
                  </div>
                </div>
              );
          })}
          <span
            style={{ marginTop: 20, display: 'inline-block' }}
            className="menu-settings__online-unload"
            onClick={onSaveTakeAwayDesk}
          >
            Сохранить
          </span>
        </div>
      )) ||
        null}
    </>
  );
};

export default React.memo(SelectTakeawayMenuTable);
