import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import store from '../store';
import { getAuthHeaders } from '../../api/auth';

export const inquirySlice = createSlice({
  name: 'inquiry',
  initialState: {
    inquiryLoading: false,
    policyData: {
      text: '',
      organisation_id: '',
      created_at: '',
    },
  },
  reducers: {
    setInquiryLoading: (state, action) => {
      return {
        ...state,
        inquiryLoading: action.payload,
      };
    },
    setPolicyData: (state, action) => {
      return {
        ...state,
        policyData: action.payload,
      };
    },
  },
});

const getOrgId = () => {
  const { profile } = store.getState();
  return profile.data.organisation.id;
};

export const getPolicyData = () => (dispatch) => {
  dispatch(setInquiryLoading(true));

  axios
    .get(`${process.env.REACT_APP_TENQ_API}/api/v0/owner/data/processing`, getAuthHeaders())
    .then((resp) => {
      const data = resp.data;
      dispatch(setPolicyData(data));
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(setInquiryLoading(false));
    });
};

export const getPolicyDataById = (id) => (dispatch) => {
  dispatch(setInquiryLoading(true));
  axios
    .get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/organisations/${id}/data/processing`,
      getAuthHeaders()
    )
    .then((resp) => {
      const data = resp.data;
      dispatch(setPolicyData(data));
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(setInquiryLoading(false));
    });
};

export const getPolicyDataPdf = () => async (dispatch) => {
  dispatch(setInquiryLoading(true));
  axios
    .get(`${process.env.REACT_APP_TENQ_API}/privacy-policy-organisation`, {
      responseType: 'blob',
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'privacy-policy.pdf');
      document.body.appendChild(link);
      link.click();
    });
};

export const createPolicyData = (data) => (dispatch) => {
  dispatch(setInquiryLoading(true));
  axios
    .post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/data/processing`,
      {
        text: data,
        organisation_id: getOrgId(),
        created_at: new Date(),
      },
      getAuthHeaders()
    )
    .then((resp) => {
      const data = resp.data;
      dispatch(setPolicyData(data));
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(setInquiryLoading(false));
    });
};

export const updatePolicyData = (data) => (dispatch) => {
  dispatch(setInquiryLoading(true));

  axios
    .put(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/data/processing`,
      {
        text: data,
      },
      getAuthHeaders()
    )
    .then((resp) => {
      const data = resp.data;
      dispatch(setPolicyData(data));
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(setInquiryLoading(false));
    });
};

export const deletePolicyData = () => (dispatch) => {
  dispatch(setInquiryLoading(true));
  axios
    .delete(`${process.env.REACT_APP_TENQ_API}/api/v0/owner/data/processing`, getAuthHeaders())
    .then((resp) => {
      dispatch(setPolicyData(resp));
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(setInquiryLoading(false));
    });
};

export const { setInquiryLoading, setPolicyData } = inquirySlice.actions;

export default inquirySlice.reducer;
