import React from 'react';

export const YouTubeIcon = () => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.6613 10.2003C33.6613 10.2003 33.3293 7.85615 32.3066 6.82686C31.0117 5.47217 29.5641 5.46553 28.9 5.38584C24.1453 5.04053 17.0066 5.04053 17.0066 5.04053H16.9934C16.9934 5.04053 9.85469 5.04053 5.1 5.38584C4.43594 5.46553 2.98828 5.47217 1.69336 6.82686C0.670703 7.85615 0.345312 10.2003 0.345312 10.2003C0.345312 10.2003 0 12.9562 0 15.7054V18.2819C0 21.0312 0.338672 23.787 0.338672 23.787C0.338672 23.787 0.670703 26.1312 1.68672 27.1604C2.98164 28.5151 4.68164 28.4687 5.43867 28.6147C8.16133 28.8737 17 28.9534 17 28.9534C17 28.9534 24.1453 28.9401 28.9 28.6015C29.5641 28.5218 31.0117 28.5151 32.3066 27.1604C33.3293 26.1312 33.6613 23.787 33.6613 23.787C33.6613 23.787 34 21.0378 34 18.2819V15.7054C34 12.9562 33.6613 10.2003 33.6613 10.2003ZM13.4871 21.4097V11.8538L22.6711 16.6483L13.4871 21.4097Z"
        fill="#8672E8"
      />
    </svg>
  );
};

export const YouTubeIconSmall = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8406 4.8002C15.8406 4.8002 15.6844 3.69707 15.2031 3.2127C14.5938 2.5752 13.9125 2.57207 13.6 2.53457C11.3625 2.37207 8.00313 2.37207 8.00313 2.37207H7.99687C7.99687 2.37207 4.6375 2.37207 2.4 2.53457C2.0875 2.57207 1.40625 2.5752 0.796875 3.2127C0.315625 3.69707 0.1625 4.8002 0.1625 4.8002C0.1625 4.8002 0 6.09707 0 7.39082V8.60332C0 9.89707 0.159375 11.1939 0.159375 11.1939C0.159375 11.1939 0.315625 12.2971 0.79375 12.7814C1.40313 13.4189 2.20313 13.3971 2.55938 13.4658C3.84063 13.5877 8 13.6252 8 13.6252C8 13.6252 11.3625 13.6189 13.6 13.4596C13.9125 13.4221 14.5938 13.4189 15.2031 12.7814C15.6844 12.2971 15.8406 11.1939 15.8406 11.1939C15.8406 11.1939 16 9.90019 16 8.60332V7.39082C16 6.09707 15.8406 4.8002 15.8406 4.8002ZM6.34688 10.0752V5.57832L10.6687 7.83457L6.34688 10.0752Z"
        fill="#8672E8"
      />
    </svg>
  );
};
