import { createSlice } from '@reduxjs/toolkit';

export const newQrcodeSlice = createSlice({
  name: 'newQrcode',
  initialState: {
    data: {
      name: '',
      purpose: '',
      link: '',
    },
  },
  reducers: {
    setNewQrcodeName: (state, data) => {
      state.data.name = data.payload;
    },
    setNewQrcodePurpose: (state, data) => {
      state.data.purpose = data.payload;
    },
    setNewQrcodeLink: (state, data) => {
      state.data.link = data.payload;
    },
  },
});

export const { setNewQrcodeName, setNewQrcodePurpose, setNewQrcodeLink } = newQrcodeSlice.actions;

export default newQrcodeSlice.reducer;
