import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useMemo } from 'react';
import InputMask from 'react-input-mask';
import { createShift, getSections, setCurrentDays } from '../../../../../redux/actions/shifts';
import closeIcon from '../../../../../images/icons/plus.svg';
import clear from '../../../../../images/clear.svg';
import { createDateIntervals } from '../../../../../utils/createDateIntervals';
import defaultAvatar from '../../../../../images/plugs/defaultAva.png';
import Button from '../../../atoms/Button';

const CreateShiftModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { calendar, currentDays } = useSelector((state) => state.shifts);
  const [startTimeValue, setStartTimeValue] = useState('');
  const [endTimeValue, setEndTimeValue] = useState('');
  const [currentSections, setCurrentSections] = useState([]);
  const [rotation] = useState(null);
  const sections = useSelector((state) => state.shifts.sections);
  const userCols = calendar.body.filter((col) => col[0].id === currentDays.userId)[0];
  const user = calendar.body.filter((col) => col[0].id === currentDays.userId)[0][0];
  const intervals = [
    {
      start: '07:00',
      end: '15:00',
    },
    {
      start: '15:00',
      end: '23:00',
    },
    {
      start: '23:00',
      end: '07:00',
    },
  ];

  const setTimeInterval = (interval) => {
    setStartTimeValue(interval.start);
    setEndTimeValue(interval.end);
  };

  const setCurrentSection = (id) => {
    setCurrentSections((v) => {
      let value;
      if (v.includes(id)) {
        value = v.filter((el) => el !== id);
      } else {
        value = [...v, id];
      }
      return value;
    });
  };

  const checkAll = () => {
    for (const element of sections) {
      setCurrentSections((v) => {
        let value;
        if (sections.length !== currentSections.length) {
          if (v.includes(element?.id)) {
            value = v;
          } else {
            value = [...v, element?.id];
          }
        } else {
          value = [];
        }
        return value;
      });
    }
  };

  // const onOrderRotation = () => {
  //   if (rotation === 'order') {
  //     setRotation(null);
  //     setCurrentSections([]);
  //   } else {
  //     setRotation('order');
  //     setCurrentSections(sections.map((s) => s.id));
  //   }
  // };

  // const onRandomRotation = () => {
  //   if (rotation === 'random') {
  //     setRotation(null);
  //     setCurrentSections([]);
  //   } else {
  //     setRotation('random');
  //     setCurrentSections(sections.map((s) => s.id));
  //   }
  // };

  const onCancel = () => {
    dispatch(
      setCurrentDays({
        userId: null,
        colsIndexes: [],
      })
    );
    closeModal();
  };

  useEffect(() => {
    return () => {
      dispatch(
        setCurrentDays({
          userId: null,
          colsIndexes: [],
        })
      );
    };
  }, []);

  const onCreateShift = async () => {
    const timeRegex = /^([01]\d|2[0-3]):[0-5]\d$/;
    const data = {
      user_id: currentDays.userId,
      time: {
        from: timeRegex.test(startTimeValue)
          ? startTimeValue
          : `${startTimeValue.replace('__', '0')}0`,
        to: timeRegex.test(endTimeValue) ? endTimeValue : `${endTimeValue.replace('__', '0')}0`,
      },
      sections: currentSections,
      days: currentDays.colsIndexes.map((colIndex) => userCols[colIndex].date),
    };
    const amin = startTimeValue.split(':')[0] * 60 + startTimeValue.split(':')[1] * 1;
    let bmin = endTimeValue.split(':')[0] * 60 + endTimeValue.split(':')[1] * 1;

    if (bmin + 60 * 12 < amin) {
      bmin += 60 * 24;
    }
    if (rotation) data.rotation = rotation;
    const res = await dispatch(createShift(data));
    setStartTimeValue(data?.time?.from);
    setEndTimeValue(data?.time?.to);
    if (res) {
      dispatch(setCurrentDays({ ...currentDays, colsIndexes: [] }));
      closeModal();
    }
  };

  useEffect(() => {
    dispatch(getSections());
  }, []);
  const startsWithTwo = { startTime: startTimeValue[0] === '2', endTime: endTimeValue[0] === '2' };
  const formatChars = useMemo(
    () => ({
      0: '[0-2]',
      1: startsWithTwo.startTime ? /[0-3]/ : /[0-9]/,
      2: '[0-5]',
      3: '[0-9]',
      4: startsWithTwo.endTime ? /[0-3]/ : /[0-9]/,
    }),
    [startsWithTwo]
  );
  const dateIntervals = [];

  const parsedIndexes = [...currentDays.colsIndexes];
  parsedIndexes
    .sort((a, b) => a - b)
    .map((colIndex) => {
      const date = userCols[colIndex].date_short;
      dateIntervals.push(date);
    });

  const dateIntervalsObj = createDateIntervals(dateIntervals);
  function formatPhoneNumber(phoneNumber) {
    return phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3 $4 $5');
  }
  const isTime = useMemo(() => {
    const timeRegex = /^([01]\d|2[0-3]):[0-5]\d$/;
    return timeRegex.test(endTimeValue) && timeRegex.test(startTimeValue);
  }, [endTimeValue, startTimeValue]);

  return (
    <div>
      <div className="personal-management__popup-title titleCreateShifts">
        Назначение смены
        <div className="personal-management__popup-close" onClick={onCancel}>
          <img src={clear} alt="" />
        </div>
      </div>
      <div className="popup-shift__info">
        <div className="popup-shift__info-header">
          <div className="popup-shift__info-image imgWrapper">
            <img
              src={
                user.avatar && typeof user.avatar !== 'object'
                  ? `${process.env.REACT_APP_TENQ_API}${user.avatar}`
                  : defaultAvatar
              }
              alt=""
              className="imgOfWrapper"
            />
          </div>
          <div className="popup-shift__info-textblock">
            <div className="popup-shift__info-name">{user.full_name}</div>
            <div className="popup-shift__info-post">{user.position}</div>
            <div className="popup-shift__info-post">
              {user.phone ? `${formatPhoneNumber(user?.phone)} ` : user.email}
            </div>
          </div>
        </div>
      </div>
      <div className="popup-shift__label">Выбранные дни</div>
      <div className="popup-shift__days">
        <div className="popup-shift__date-inner">
          {Object.keys(dateIntervalsObj).map((key, i) => {
            return (
              <div key={i}>
                <div className="personal-management__popup-day">
                  <span>{dateIntervalsObj[key]}</span>
                </div>
                <div className="personal-management__popup-day__mobile">
                  <span>{dateIntervalsObj[key]}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="popup-shift__form-inner">
        <div className="popup-shift__form">
          <div className="popup-shift__label">Начало смены</div>
          <div className="popup-shift__form__start_date">
            <InputMask
              mask="01:23"
              placeholder="__:__"
              onChange={(e) => {
                setStartTimeValue(e.target.value);
              }}
              formatChars={formatChars}
              value={startTimeValue}
            />
            <span onClick={() => setStartTimeValue('')}>
              <img src={closeIcon} alt="close" />
            </span>
          </div>
        </div>
        <div className="popup-shift__form">
          <div className="popup-shift__label">Конец смены</div>
          <div className="popup-shift__form__end_date">
            <InputMask
              mask="04:23"
              placeholder="__:__"
              formatChars={formatChars}
              value={endTimeValue}
              onChange={(e) => setEndTimeValue(e.target.value)}
            />
            <span onClick={() => setEndTimeValue('')}>
              <img src={closeIcon} alt="close" />
            </span>
          </div>
        </div>
      </div>
      <div className="popup-shift__preform-inner">
        {intervals.map((interval) => {
          const isActive = interval.start === startTimeValue && interval.end === endTimeValue;
          return (
            <div
              key={interval.start}
              onClick={() => setTimeInterval(interval)}
              className={
                isActive ? 'popup-shift__preform-item active' : 'popup-shift__preform-item'
              }
            >
              <span>
                {interval.start}-{interval.end}
              </span>
            </div>
          );
        })}
      </div>
      <div className="popup-shift__place">
        <div className="plainGrayText">Рабочая область</div>
        <div className="popup-shift__place-inner popup-shift__container">
          {(sections.length && (
            <div
              onClick={checkAll}
              className={`popup-shift__place-item ${
                sections.length === currentSections.length && 'active'
              }`}
            >
              <span>Все</span>
            </div>
          )) ||
            null}
          {sections.map((s) => {
            let sectionClass;
            if (rotation) {
              sectionClass = 'popup-shift__place-item inactive';
            } else if (currentSections.includes(s.id)) {
              sectionClass = 'popup-shift__place-item active';
            } else {
              sectionClass = 'popup-shift__place-item';
            }
            return (
              <div
                key={s.id}
                onClick={() => !rotation && setCurrentSection(s.id)}
                className={sectionClass}
              >
                <span>{s.name}</span>
              </div>
            );
          })}
          {!sections.length && (
            <div className="sidebar-personal__place-post">
              Выполните интеграцию с R-keeper для добавления рабочих областей
            </div>
          )}
        </div>
      </div>
      {
        //   (sections?.length && (
        //   <div>
        //     <div className="popup-menu__daily-inner">
        //       <div className="popup-menu__daily-text">Ежедневная ротация секции</div>
        //       <InputCheckBox boolValue={rotation === 'order'} changerValue={onOrderRotation} />
        //     </div>
        //     <div className="popup-menu__daily-inner">
        //       <div className="popup-menu__daily-text">Ежедневная случайная генерация секции</div>
        //       <InputCheckBox boolValue={rotation === 'random'} changerValue={onRandomRotation} />
        //     </div>
        //   </div>
        // )) ||
        //   null
      }
      <Button
        title="Назначить"
        className={
          currentSections.length && isTime
            ? 'popup-shift__button'
            : 'popup-shift__button popup-shift__button--inactive'
        }
        onClick={() => currentSections.length && isTime && onCreateShift()}
      />
    </div>
  );
};

export default React.memo(CreateShiftModal);
