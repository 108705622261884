import { createSlice } from '@reduxjs/toolkit';
import { menuAPI } from '../../api/setting';
import { setIsLoad } from './preloader';
import { setErrorMessage } from './messageToUser';
import { setModal, setModalType } from './modal';
import { addAlert } from './alerts';
import { setOrganisationInfo } from './organisation';

export const menuSettingSlice = createSlice({
  name: 'menuSetting',
  initialState: {
    menus: [],
    takeAwayMenu: [],
    onlineMenu: {},
    onlineMenuForImport: {
      main: {},
      rkeeper: {},
    },
    startList: {},
    stopList: {},
    menuSections: [],
    menuPositionInfo: null,
    searchResults: [],
    menuVariants: [],
    menuMainListDNDVersion: null,
    menuVariantInfo: null,
    selectedMenuVariant: null,
    selectedTakeawayMenuVariant: '',
    selectedMenuCategory: '',
    menuCategoryPagination: {},
    menuCategoryDishes: [],
    takeawayMenuSectionsDishes: [],
    menuCategoryDishesPagination: {},
    selectedMenuDish: null,
    deletingChapter: null,
    currentPageMainList: null,
    totalPagesMainList: null,
  },
  reducers: {
    setOnlineMenu: (state, data) => {
      state.onlineMenu = data.payload;
    },
    setOnlineMenuForImport: (state, data) => {
      state.onlineMenuForImport = data.payload;
    },
    setMenuSections: (state, data) => {
      state.menuSections = data.payload;
    },
    setMenuSectionsDishes: (state, data) => {
      state.menuCategoryDishes = data.payload;
    },
    setTakeawayMenuSectionsDishes: (state, data) => {
      state.takeawayMenuSectionsDishes = data.payload;
    },
    setMenuCategoryPagination: (state, data) => {
      state.menuCategoryPagination = data.payload;
    },
    setMenuCategoryDishesPagination: (state, data) => {
      state.menuCategoryDishesPagination = data.payload;
    },
    setMenuPositionInfo: (state, data) => {
      state.menuPositionInfo = data.payload;
    },
    setMenu: (state, data) => {
      state.menus = data.payload;
    },
    setMenuVariants: (state, data) => {
      state.menuVariants = data.payload;
    },
    setMenuMainListDNDVersion: (state, data) => {
      state.menuMainListDNDVersion = data.payload;
    },
    setMenuVariantInfo: (state, data) => {
      state.menuVariantInfo = data.payload;
    },
    setSelectedMenuVariant: (state, data) => {
      state.selectedMenuVariant = data.payload;
    },
    setSelectedTakeawayMenuVariant: (state, data) => {
      state.selectedTakeawayMenuVariant = data.payload;
    },
    setStartList: (state, data) => {
      state.startList = data.payload;
    },
    setStopList: (state, data) => {
      state.stopList = data.payload;
    },
    setSearchResults: (state, data) => {
      state.searchResults = data.payload;
    },
    setDeletingChapter: (state, data) => {
      state.deletingChapter = data.payload;
    },
    setCurrentPageMainList: (state, data) => {
      state.currentPageMainList = data.payload;
    },
    setTotalPagesMainList: (state, data) => {
      state.totalPagesMainList = data.payload;
    },
    setSelectedMenuCategory: (state, data) => {
      state.selectedMenuCategory = data.payload;
    },
    setSelectedMenuDish: (state, data) => {
      state.selectedMenuDish = data.payload;
    },
    setTakeAwayMenu: (state, data) => {
      state.takeAwayMenu = data.payload;
    },
  },
});

export const {
  setOnlineMenu,
  setOnlineMenuForImport,
  setMenuSections,
  setMenuPositionInfo,
  setMenu,
  setStartList,
  setStopList,
  setSearchResults,
  setMenuVariants,
  setSelectedMenuVariant,
  setSelectedTakeawayMenuVariant,
  setMenuVariantInfo,
  setMenuMainListDNDVersion,
  setDeletingChapter,
  setCurrentPageMainList,
  setTotalPagesMainList,
  setSelectedMenuCategory,
  setMenuCategoryPagination,
  setMenuCategoryDishesPagination,
  setSelectedMenuDish,
  setMenuSectionsDishes,
  setTakeawayMenuSectionsDishes,
  setTakeAwayMenu,
} = menuSettingSlice.actions;

export default menuSettingSlice.reducer;

export const getMenuVariants = () => async (dispatch) => {
  try {
    const response = await menuAPI.getMenu(false);
    dispatch(setMenuVariants(response?.data?.data?.result?.menu_sections));
    dispatch(
      setMenuMainListDNDVersion(
        response?.data?.data?.result?.menu_configuration?.drag_and_drop_version
      )
    );
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const setMenuVariantInfoForEdit = (info) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    dispatch(setMenuVariantInfo(info));
  } catch (err) {
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const addMenuVariant = (name) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const res = await menuAPI.addMenuVariant(name);
    dispatch(getMenuVariants());
    return res;
  } catch (err) {
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const editMenuVariant = (formData, menuMap_id) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const res = await menuAPI.editMenuSection(formData, menuMap_id);
    dispatch(getMenuVariants());
    dispatch(addAlert('Изменения сохранены', 'success'));
    return res;
  } catch (err) {
    dispatch(addAlert('Произошла ошибка', 'error'));
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const deleteMenuVariant = (id, action) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    await menuAPI.deleteMenuVariant(id, action);
    dispatch(getMenuVariants());
  } catch (err) {
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const moveMenuVariant = (body) => async (dispatch) => {
  try {
    await menuAPI.moveMenuVariant(body).then((res) => {
      dispatch(setMenuMainListDNDVersion(res?.data?.data?.result?.drag_and_drop_version));
    });
  } catch (err) {
    dispatch(getMenuVariants());
    console.log(err);
    console.log(err.response);
  }
};

export const getOnlineMenu =
  (variant, page, callback, isTakeaway) => async (dispatch, getState) => {
    try {
      if (variant) {
        if (!isTakeaway) {
          dispatch(setSelectedMenuVariant(variant));
        }
        const response = await menuAPI
          .getOnlineMenu(getState().profile.data.organisation.id, variant.id, page)
          .then((res) => {
            dispatch(setCurrentPageMainList(page));
            dispatch(setTotalPagesMainList(res.data.total_pages));
            dispatch(setOnlineMenu(res.data.data));
            callback && callback();
            return res;
          });
        return response;
      }
    } catch (err) {
      console.log(err);
      console.log(err.response);
    }
  };

export const getMenuCategories = (data) => async () => {
  try {
    const response = await menuAPI.getMenuCategories(data);
    return response?.data?.data;
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const getMenuCategoriesDishes = (data) => async () => {
  try {
    const response = await menuAPI.getMenuCategoriesDishes(data);
    return response?.data?.data;
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const getOnlineMenuForImport = () => async (dispatch, getState) => {
  try {
    const response = await menuAPI.getOnlineMenuForImport(
      getState().menuSetting.selectedMenuVariant.id
    );
    dispatch(
      setOnlineMenuForImport({
        main: response.data.main,
        rkeeper: response.data.rkeeper,
      })
    );
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const partlyImportMenuFromRkeeper = (menu_sections) => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  try {
    await menuAPI.partlyImportMenuFromRkeeper(
      menu_sections,
      getState().menuSetting.selectedMenuVariant.id
    );
    dispatch(setModal(false));
    dispatch(setModalType(''));
    dispatch(
      getOnlineMenu(
        getState().menuSetting.selectedMenuVariant,
        getState().menuSetting.currentPageMainList
      )
    );
  } catch (err) {
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const getMenuSections = () => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  dispatch(setMenuSections([]));

  try {
    const response = await menuAPI.getMenuSections(getState().menuSetting.selectedMenuVariant.id);

    if (response.data) {
      dispatch(setMenuSections(response.data.data));
    } else {
      dispatch(setMenuSections([]));
    }
  } catch (err) {
    const message = 'При загрузке данных произошла ошибка';
    dispatch(setErrorMessage({ message, type: 'createMenuPosition' }));
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const createMenuSections = (name, menuId) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await menuAPI.createMenuSection(name, menuId);
    return response;
  } catch (err) {
    const message = 'При отправке данных произошла ошибка';
    dispatch(setErrorMessage({ message, type: 'createChapter' }));
    console.log(err);
    console.log(err.response);
    throw new Error(err);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const editMenuSection = (formData, chapterId, callback) => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  try {
    const response = await menuAPI.editMenuSection(formData, chapterId).then((res) => {
      const { menuSections } = getState().menuSetting;
      console.log(menuSections);
      dispatch(
        setMenuSections(menuSections.map((el) => (el.id === chapterId ? res?.data?.data : el)))
      );
      return res?.data?.data;
    });
    callback && callback();
    return response;
  } catch (err) {
    if (getState().menuSetting.selectedMenuVariant) {
      dispatch(
        getOnlineMenu(
          getState().menuSetting.selectedMenuVariant,
          getState().menuSetting.currentPageMainList
        )
      );
    }
    dispatch(addAlert('Произошла ошибка', 'error'));
    console.log(err);
    console.log(err.response);
    throw new Error(err);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const deleteMenuSection = (menuSectionId, action, onError) => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  try {
    await menuAPI.deleteMenuSection(menuSectionId, action);
    dispatch(getMenuSections());
    dispatch(
      getOnlineMenu(
        getState().menuSetting.selectedMenuVariant,
        getState().menuSetting.currentPageMainList
      )
    );
    dispatch(getStartStopList('start_list'));
    dispatch(getStartStopList('stop_list'));
  } catch (err) {
    if (onError) {
      dispatch(setDeletingChapter(menuSectionId));
      onError();
    }
    const message = 'При отправке данных произошла ошибка';
    dispatch(setErrorMessage({ message, type: 'createMenuPosition' }));
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const createDish = (data) => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  try {
    const response = await menuAPI.createDish(data);
    dispatch(
      getOnlineMenu(
        getState().menuSetting.selectedMenuVariant,
        getState().menuSetting.currentPageMainList
      )
    );
    return response;
  } catch (err) {
    const message = 'При отправке данных произошла ошибка';
    dispatch(setErrorMessage({ message, type: 'createMenuPosition' }));
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const getDishInfo = (menuPositionId) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await menuAPI.getDishInfo(menuPositionId);
    dispatch(setMenuPositionInfo(response.data.data));
  } catch (err) {
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const updateDish = (menuPositionId, data, callback) => async (dispatch, getState) => {
  dispatch(setIsLoad(true));

  try {
    const response = await menuAPI.updateDish(menuPositionId, data).then(async (res) => {
      callback && callback(res?.data?.data || {});
      return res;
    });
    return response;
  } catch (err) {
    const body = {
      menu_section_id: getState().menuSetting.selectedMenuCategory?.id,
      takeaway: false,
      page: 1,
    };
    const menuCategoriesDishes = await dispatch(getMenuCategoriesDishes(body));
    dispatch(setMenuSectionsDishes(menuCategoriesDishes?.dishes));
    dispatch(setMenuCategoryDishesPagination(menuCategoriesDishes?.pagination));
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const addNameToOrder = (dishId, data, callback) => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  try {
    const response = await menuAPI.addNameToOrder(dishId, data).then(async (res) => {
      const menuCategoryDishes = getState().menuSetting.menuCategoryDishes;
      const modifiedData = menuCategoryDishes.map((item) => {
        return item.id !== dishId ? item : res?.data?.data;
      });
      dispatch(setMenuSectionsDishes(modifiedData));
      callback && callback();
      dispatch(addAlert('Изменения сохранены', 'success'));
      return res;
    });
    return response;
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const updateMainList = (data) => async (dispatch, getState) => {
  try {
    const response = await menuAPI.updateMainList(data);
    dispatch(setMenuMainListDNDVersion(response?.data?.data?.result?.drag_and_drop_version));
    return response;
  } catch (err) {
    dispatch(
      getOnlineMenu(
        getState().menuSetting.selectedMenuVariant,
        getState().menuSetting.currentPageMainList
      )
    );
    dispatch(addAlert('Возникла ошибка'));
    throw new Error(err);
  }
};

export const updateSectionList = (data, menuSectionId) => async (dispatch, getState) => {
  try {
    const response = await menuAPI.updateSectionList(data, menuSectionId).then((res) => {
      dispatch(setMenuMainListDNDVersion(res.data.data.drag_and_drop_version));
    });
    return response;
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
    const categories = await dispatch(
      getMenuCategories({
        menu_id: getState().menuSetting.selectedMenuVariant?.id,
        only_takeaway_menu: false,
        page: 1,
      })
    );
    dispatch(setMenuCategoryPagination(categories?.pagination));
    dispatch(setMenuSections(categories?.menu_sections));
  }
};

export const changeStartListPositionPriority = (menuPositionId, data) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    await menuAPI.changeStartListPositionPriority(menuPositionId, data);
    dispatch(getStartStopList('start_list'));
  } catch (err) {
    const message = 'При отправке данных произошла ошибка';
    dispatch(setErrorMessage({ message, type: 'createMenuPosition' }));
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const deleteDish = (menuPositionId) => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  try {
    const response = await menuAPI.deleteDish(menuPositionId);
    dispatch(
      getOnlineMenu(
        getState().menuSetting.selectedMenuVariant,
        getState().menuSetting.currentPageMainList
      )
    );
    dispatch(getStartStopList('start_list'));
    dispatch(getStartStopList('stop_list'));
    return response;
  } catch (err) {
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const editMenu = (use_app_link_menu, link_menu) => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  try {
    await menuAPI.editMenu(use_app_link_menu, link_menu).then((res) => {
      const organisationInfo = getState().organisation?.data?.info;
      dispatch(
        setOrganisationInfo({
          ...organisationInfo,
          settings: res.data.data,
        })
      );
    });
  } catch (err) {
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const updateMenuView = (data) => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  try {
    await menuAPI.updateMenuView(data).then((res) => {
      const organisationInfo = getState().organisation?.data?.info;
      dispatch(
        setOrganisationInfo({
          ...organisationInfo,
          settings: res.data.data,
        })
      );
    });
  } catch (err) {
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};
export const uploadMenuPdf = (data) => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  try {
    await menuAPI.uploadMenuPdf(data).then((res) => {
      const organisationInfo = getState().organisation?.data?.info;
      console.log(organisationInfo);
      dispatch(
        setOrganisationInfo({
          ...organisationInfo,
          settings: {
            ...res.data.data,
            use_app_link_menu: JSON.parse(res.data.data.use_app_link_menu),
          },
        })
      );
    });
  } catch (err) {
    if (err?.response?.status === 422) {
      dispatch(addAlert('Максимальный размер файла 10Мб'));
    }
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const getStartStopList = (typeMenuGroup) => async (dispatch, getState) => {
  try {
    const res = await menuAPI.getStartStopList(
      typeMenuGroup,
      getState().menuSetting.selectedMenuVariant.id
    );
    if (typeMenuGroup === 'start_list') {
      dispatch(setStartList(res.data.data));
    } else {
      dispatch(setStopList(res.data.data));
    }
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const addStartStopListPosition = (typeMenuGroup, data) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const res = await menuAPI.addStartStopListPosition(typeMenuGroup, data);
    if (typeMenuGroup === 'start_list') {
      dispatch(setStartList(res.data.data));
    } else {
      dispatch(setStopList(res.data.data));
    }
  } catch (err) {
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const deleteStartStopListPosition = (typeMenuGroup, menu_group_id) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const res = await menuAPI.deleteStartStopListPosition(menu_group_id);
    if (typeMenuGroup === 'start_list') {
      dispatch(setStartList(res.data.data));
    } else {
      dispatch(setStopList(res.data.data));
    }
  } catch (err) {
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const getSearchResults = (value, table) => async (dispatch) => {
  try {
    if (value) {
      const res = await menuAPI.getSearchResults(value, table);
      dispatch(setSearchResults(res.data.data));
    } else {
      dispatch(setSearchResults([]));
    }
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const syncRkeeper = () => async (dispatch) => {
  try {
    await menuAPI.syncRkeeper();
    await menuAPI.syncRkeeperDishes();
    dispatch(addAlert('Импорт успешно выполнен', 'success'));
    dispatch(getMenuVariants());
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const getTakeAwayMenu = (menuId) => async (dispatch, getState) => {
  const organisationId = getState().profile.data.organisation.id;
  try {
    const response = await menuAPI.getTakeAwayMenu(menuId, organisationId);
    dispatch(setTakeAwayMenu(response.data.data));
  } catch (err) {
    console.log(err);
  }
};

export const editTakeAwayMenu = (data) => async (dispatch) => {
  try {
    const response = await menuAPI.editTakeAwayMenu(data);
    dispatch(setTakeAwayMenu(response.data.data));
  } catch (err) {
    console.log(err);
  }
};

export const updateTakeawayMenu = (data) => (dispatch, getState) => {
  try {
    menuAPI.updateTakeawayMenu(data).then(() => {
      dispatch(addAlert('Изменения сохранены', 'success'));
      dispatch(
        getOnlineMenu(
          getState().menuSetting.selectedMenuVariant,
          getState().menuSetting.currentPageMainList
        )
      );
    });
  } catch (err) {
    dispatch(addAlert('Возникла ошибка'));
    console.log(err);
  }
};
