import { createSlice } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import { clubcardApi, groupsSettingsApi } from '../../api/clubcard';
import { setIsLoad } from './preloader';
import { setErrorMessage } from './messageToUser';
import { setClubcards } from './clubcard';
import { addAlert } from './alerts';

export const SUCCESS_IMPORT = 'SUCCESS-IMPORT';
export const UNSUCCESS_IMPORT = 'UNSUCCESS-IMPORT';
const loadState = () => {
  try {
    const serializedState = localStorage.getItem('currentProgram');
    if (serializedState === null) {
      return null;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};
export const groupsSettingsSlice = createSlice({
  name: 'groupsetting',
  initialState: {
    groups: [],
    currentProgram: loadState(),
    importResult: null,
  },
  reducers: {
    setCurrentProgram: (state, data) => {
      state.currentProgram = data.payload;
    },
    setGroups: (state, data) => {
      state.groups = data.payload;
    },
    setImportResult: (state, data) => {
      state.importResult = data.payload;
    },
  },
});

export const { setGroups, setCurrentProgram, setImportResult } = groupsSettingsSlice.actions;

export default groupsSettingsSlice.reducer;

export const getPrograms = () => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await clubcardApi.getClubcards();
    dispatch(setClubcards(response.data.data));
    dispatch(setIsLoad(false));
  } catch (err) {
    dispatch(setIsLoad(false));
    console.log(err);
    console.log(err.response);
  }
};

export const getGroups = (programId, page) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await groupsSettingsApi.getGroups(programId, page || 1);
    dispatch(
      setGroups(
        response.data.data.map((el) => ({
          ...el,
          amount_to_transfer: (Number(el?.amount_to_transfer) / 100).toLocaleString('ru'),
        }))
      )
    );
    dispatch(setIsLoad(false));
  } catch (err) {
    dispatch(setIsLoad(false));
    console.log(err);
    console.log(err.response);
  }
};

export const getClients = (programId, groupId, page) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await groupsSettingsApi.getClients(programId, groupId, page || 1);
    dispatch(setIsLoad(false));
    return response?.data;
  } catch (err) {
    dispatch(setIsLoad(false));
    console.log(err);
    console.log(err.response);
  }
};

export const getClientsCsv = (loyalCardId) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await groupsSettingsApi.getClientsCsv(loyalCardId);
    const contentType = response.headers['content-type'];
    const file = new Blob([response.data], { type: contentType });
    const name = 'clients.xlsx';
    saveAs(file, name);
    dispatch(setIsLoad(false));
  } catch (err) {
    dispatch(setIsLoad(false));
    console.log(err);
    console.log(err.response);
  }
};

export const createGroup = (data) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await groupsSettingsApi.createGroup(data);
    dispatch(getGroups(data.group));
    dispatch(setIsLoad(false));
    return response;
  } catch (err) {
    dispatch(setIsLoad(false));
    const message = 'При отправке данных произошла ошибка';
    dispatch(setErrorMessage({ message, type: 'createGroup' }));
    console.log(err);
    console.log(err.response);
  }
};

export const editGroup = (data, cardId) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await groupsSettingsApi.editGroup(data);
    dispatch(getGroups(cardId));
    dispatch(setIsLoad(false));
    return response;
  } catch (err) {
    dispatch(setIsLoad(false));
    const message = 'При отправке данных произошла ошибка';
    dispatch(setErrorMessage({ message, type: 'editGroup' }));
    console.log(err);
    console.log(err.response);
  }
};

export const deleteGroup = (programId, data) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await groupsSettingsApi.deleteGroup(data);
    console.log(response);
    dispatch(getGroups(programId));
    dispatch(setIsLoad(false));
  } catch (err) {
    dispatch(setIsLoad(false));
    const message = 'При отправке данных произошла ошибка';
    dispatch(setErrorMessage({ message, type: 'deleteGroup' }));
    console.log(err);
    console.log(err.response);
  }
};

export const deleteClient = (program, data) => async (dispatch) => {
  try {
    const response = await groupsSettingsApi.deleteClient(data);
    console.log(response);
    dispatch(getGroups(program));
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const regroupClient = (program, data) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await groupsSettingsApi.regroupClient(data);
    dispatch(getGroups(program));
    dispatch(addAlert('Клиент перемещен в другую группу', 'success'));
    return response;
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const importGroup = (data, programId) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    await groupsSettingsApi.importGroup(data);
    dispatch(getGroups(programId));
    dispatch(setIsLoad(false));
    dispatch(setImportResult({ type: SUCCESS_IMPORT }));
    dispatch(addAlert('Импорт успешно завершен', 'success'));
  } catch (err) {
    dispatch(setIsLoad(false));
    dispatch(addAlert('Импорт не выполнен', 'error'));
    dispatch(setImportResult({ type: UNSUCCESS_IMPORT }));
    console.log(err);
    console.log(err.response);
  }
};
