import * as React from 'react';

const WarningDangerIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#FF6262"
      stroke="#FF6262"
      d="M10 18.334a8.333 8.333 0 1 0 0-16.667 8.333 8.333 0 0 0 0 16.667Z"
    />
    <path stroke="#fff" d="M10 7v4M10 12v1M10 13.333h.008" />
  </svg>
);
export default React.memo(WarningDangerIcon);
