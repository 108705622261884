import { createSelector } from 'reselect';

const notificationsSelector = (state) => state.notifications;

export const getNotificationsSelector = createSelector(
  notificationsSelector,
  (state) => state.notifications
);

export const getNotificationPaginationSelector = createSelector(
  notificationsSelector,
  (state) => state.pagination
);

export const getNotificationUnreadSelector = createSelector(
  notificationsSelector,
  (state) => state.unread
);

export const getNotificationIsFetchingSelector = createSelector(
  notificationsSelector,
  (state) => state.isFetching
);
