import React from 'react';
import Lottie from 'lottie-react';
import loader from '../../../media/loader.json';
import s from './Preloader.module.css';

export const Preloader = () => {
  return (
    <div className={s.popup}>
      <Lottie animationData={loader} className={s.loader} loop />
    </div>
  );
};
