// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileModalHeader_header__KOa3O {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    margin-bottom: 10px;
}
.MobileModalHeader_headerBack__XGwB\\+ {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 15px;
    border: 2px solid #f2f5fa;
    box-sizing: border-box;
}
.MobileModalHeader_header__KOa3O button {
    border-radius: 10px;
    height: 40px;
    width: 40px;
    background-color: #F2F5FA;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    position: relative;
}
.MobileModalHeader_header__KOa3O button svg {
    width: 18px;
    margin: 0;
    transform: none;
}

.MobileModalHeader_header__KOa3O button .MobileModalHeader_noticeCount__2YRQZ {
    position: absolute;
    background: #8672E8;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-weight: 550;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -5px;
    right: -5px;
}`, "",{"version":3,"sources":["webpack://./src/App/features/atoms/MobileModalHeader/MobileModalHeader.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,8BAA8B;IAC9B,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,sBAAsB;AAC1B;AACA;IACI,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;IACT,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;IACT,WAAW;AACf","sourcesContent":[".header {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    text-align: center;\n    align-items: center;\n    margin-bottom: 10px;\n}\n.headerBack {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 50px;\n    height: 50px;\n    border-radius: 15px;\n    border: 2px solid #f2f5fa;\n    box-sizing: border-box;\n}\n.header button {\n    border-radius: 10px;\n    height: 40px;\n    width: 40px;\n    background-color: #F2F5FA;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border: 0;\n    position: relative;\n}\n.header button svg {\n    width: 18px;\n    margin: 0;\n    transform: none;\n}\n\n.header button .noticeCount {\n    position: absolute;\n    background: #8672E8;\n    border-radius: 50%;\n    width: 20px;\n    height: 20px;\n    font-weight: 550;\n    font-size: 12px;\n    line-height: 12px;\n    text-align: center;\n    color: #FFFFFF;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    top: -5px;\n    right: -5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `MobileModalHeader_header__KOa3O`,
	"headerBack": `MobileModalHeader_headerBack__XGwB+`,
	"noticeCount": `MobileModalHeader_noticeCount__2YRQZ`
};
export default ___CSS_LOADER_EXPORT___;
