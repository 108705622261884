import axios from 'axios';
import { getAuthHeaders } from './auth';

export const ordersApi = {
  getOrders: () =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/rkeeper/taken/orders?orders_type=all`,
      getAuthHeaders()
    ),
  getFilteredOrders: (filter) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/rkeeper/taken/orders?orders_type=all${filter}`,
      getAuthHeaders()
    ),
  getOrderDetail: (orderId) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/rkeeper/details/order?order_id=${orderId}`,
      getAuthHeaders()
    ),
  calculatePartialOrder: (data, organisationId) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/payment/${organisationId}/calculatePartialOrder`,
      data,
      getAuthHeaders()
    ),
  acceptanceOrder: (data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/rkeeper/order/acceptance`,
      data,
      getAuthHeaders()
    ),
  acceptanceAdditionOrder: (data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/rkeeper/orderAddition/acceptance`,
      data,
      getAuthHeaders()
    ),
  cancelOrder: (id) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/rkeeper/cancelOrder/${id}`,
      {},
      getAuthHeaders()
    ),
  closeTakeawayOrder: (id) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/rkeeper/closeRkeeperOrderTakeaway/${id}`,
      {},
      getAuthHeaders()
    ),
  getTakeAwayOrders: () =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/rkeeper/taken/orders?menu_type=only_takeaway&orders_type=all`,
      getAuthHeaders()
    ),
  takeToWorkOrder: (order_id) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/rkeeper/takeToWork`,
      {
        order_id,
      },
      getAuthHeaders()
    ),
  getRkeeperOrderInformation: (order_id) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/auth/getOrderRkeeper`,
      {
        order_id,
      },
      getAuthHeaders()
    ),
  deleteOrderDish: (order_id, data, type) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/rkeeper/deleteDishes/${order_id}`,
      {
        rkeeper_order_void_ident: 1,
        data,
        type,
      },
      getAuthHeaders()
    ),
  paidInCashOrder: (data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/receive/payment/cash`,
      data,
      getAuthHeaders()
    ),
};
