import { createSlice } from '@reduxjs/toolkit';
import { supportAPI } from '../../api/support';

export const supportSlice = createSlice({
  name: 'support',
  initialState: {
    supportList: {
      data: [],
      pagination: null,
    },
    isFetching: false,
  },
  reducers: {
    setSupportList: (state, data) => {
      state.supportList.data = [...state.supportList.data, ...data.payload.data];
      state.supportList.pagination = { ...data.payload.pagination };
    },
    setIsFetching: (state, data) => {
      state.isFetching = data.payload;
    },
  },
});

export const { setSupportList, setIsFetching } = supportSlice.actions;

export default supportSlice.reducer;

export const getSupportList = () => async (dispatch) => {
  try {
    const response = await supportAPI.getSupportList(1);
    dispatch(setSupportList(response.data.data));
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const getNewPageSupportList = (currentPage) => async (dispatch, getState) => {
  const { support } = getState();
  const { pagination } = support.supportList;
  try {
    if (
      !support.isFetching &&
      pagination != null &&
      pagination.current_page < pagination.total_pages &&
      currentPage === pagination.current_page
    ) {
      dispatch(setIsFetching(true));
      const response = await supportAPI.getSupportList(pagination.current_page + 1);
      dispatch(setSupportList(response.data.data));
      dispatch(setIsFetching(false));
    }
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};
