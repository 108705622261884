import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { deleteMenuSection } from '../../../../../redux/actions/settingMenu';
import { setModal, setModalType } from '../../../../../redux/actions/modal';
import s from '../../../atoms/Confirm/Confirm.module.css';
import Button from '../../../atoms/Button';
import ButtonCancel from '../../../atoms/ButtonCancel';
import { InputSelect } from '../../../../components/InputCustom/InputSelect/InputSelect';

const ConfirmDeletingChapter = ({ closeModal }) => {
  const { deletingChapter, menuSections } = useSelector((state) => state.menuSetting);
  const sectionsNames = menuSections.filter((el) => el.id !== deletingChapter).map((m) => m.name);
  const [action, setAction] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const dispatch = useDispatch();
  const onConfirm = () => {
    if ((action && selectedSection) || action === 'force') {
      dispatch(
        deleteMenuSection(
          deletingChapter,
          `?action=${
            action === 'force' ? 'force' : `transit&menu_section_id=${selectedSection.id}`
          }`
        )
      );
      dispatch(setModal(false));
      dispatch(setModalType(''));
    }
  };

  function onSelectSection(i) {
    setSelectedSection(menuSections.filter((el) => el.id !== deletingChapter)[i]);
    setAction('transit');
  }

  return (
    <>
      <div className={`${s.title} ${s.field}`}>Подтверждение</div>
      <div className={`${s.message} ${s.field}`}>
        В данном разделе еще есть блюда. Выберите действие.
      </div>

      <div className={s.wrapper}>
        <div className={s.radioWrapper}>
          <input
            checked={action === 'force'}
            onChange={() => setAction('force')}
            type="radio"
            id="forceDelete"
            name="radioNames"
          />
          <label htmlFor="forceDelete">Удалить раздел вместе с блюдами</label>
        </div>
        <div className={`${s.radioWrapper} ${s.radioWrapperWithoutMargin}`}>
          <input
            checked={action === 'transit'}
            onChange={() => setAction('transit')}
            type="radio"
            id="saveDishes"
            name="radioNames"
          />
          <label htmlFor="saveDishes">Перенести в другой раздел</label>

          <div className={s.selectWrapper}>
            <InputSelect
              defaultValue="Выберите раздел"
              value={selectedSection.name}
              selecter={onSelectSection}
              options={sectionsNames}
            />
          </div>
        </div>
      </div>
      <div className={`${s.btns} ${s.confirm} `}>
        <Button isDanger title="Удалить" onClick={onConfirm} />
        <ButtonCancel onClick={closeModal} />
      </div>
    </>
  );
};

ConfirmDeletingChapter.propTypes = {
  closeModal: PropTypes.func,
};

export default React.memo(ConfirmDeletingChapter);
