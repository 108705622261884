import React from 'react';

export const NoticeIcon = () => {
  return (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)">
        <path
          d="M12.8 8.944V6.4a4.8 4.8 0 0 0-4-4.728V.8a.8.8 0 0 0-1.6 0v.872a4.8 4.8 0 0 0-4 4.728v2.544A2.4 2.4 0 0 0 1.6 11.2v1.6a.8.8 0 0 0 .8.8h2.512a3.2 3.2 0 0 0 6.176 0H13.6a.8.8 0 0 0 .8-.8v-1.6a2.4 2.4 0 0 0-1.6-2.256ZM4.8 6.4a3.2 3.2 0 1 1 6.4 0v2.4H4.8V6.4Zm3.2 8a1.6 1.6 0 0 1-1.376-.8h2.752A1.6 1.6 0 0 1 8 14.4Zm4.8-2.4H3.2v-.8a.8.8 0 0 1 .8-.8h8a.8.8 0 0 1 .8.8v.8Z"
          fill="#94A3B8"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
