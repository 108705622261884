import { createSlice } from '@reduxjs/toolkit';
import { bonusesApi } from '../../api/bonuses';
import { setModal, setModalType } from './modal';
import { MODAL_TYPES } from '../../App/constants/modals';

export const bonusesSlice = createSlice({
  name: 'bonuses',

  initialState: {
    bonuses: [],
    currentBonus: null,
  },
  reducers: {
    setBonuses: (state, data) => {
      state.bonuses = data.payload;
    },
    setBonus: (state, data) => {
      state.currentBonus = data.payload;
    },
  },
});

export const { setBonuses, setBonus } = bonusesSlice.actions;

export default bonusesSlice.reducer;

export const getBonuses = () => async (dispatch) => {
  try {
    const response = await bonusesApi.getBonuses();
    dispatch(setBonuses(response.data.data));
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const openBonus = (id) => async (dispatch) => {
  try {
    const response = await bonusesApi.getBonus(id);
    dispatch(setBonus(response.data.data));
    dispatch(setModal(true));
    dispatch(setModalType(MODAL_TYPES.bonus));
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};
