import axios from 'axios';
import store from '../redux/store';

const getAuthHeaders = () => {
  const { session } = store.getState();
  return {
    headers: {
      Authorization: `Bearer ${session.token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
};

export const qrApi = {
  getQrCodes: (page, type) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/qrcode/getFilteredQr?page=${page}&filter=${type}&count=16`,
      getAuthHeaders()
    ),
  getEmployeesForQrCode: () =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/employee/exists-qrcode`,
      getAuthHeaders()
    ),
  createQrCode: (name, organisation) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/organisations/${organisation}/qrcodes?name=${name}`,
      {},
      getAuthHeaders()
    ),
  deleteQr: (id) =>
    axios.delete(`${process.env.REACT_APP_TENQ_API}/api/v0/qrcode?id=${id}`, getAuthHeaders()),
  saveQR: (formData) =>
    axios.post(`${process.env.REACT_APP_TENQ_API}/api/v0/saveQrCode`, formData, getAuthHeaders()),
  editQr: (formData) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/qrcode/refactorQr`,
      formData,
      getAuthHeaders()
    ),
};

export const posmaterialApi = {
  getPosmaterials: () =>
    axios.get(`${process.env.REACT_APP_TENQ_API}/api/v0/owner/pos`, getAuthHeaders()),
  downloadPosmaterial: (type, qr_code_id, pos_settings_id) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/pos/download/${type}?qr_code_id=${qr_code_id}&pos_settings_id=${pos_settings_id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${store.getState().session.token}`,
        },
        responseType: 'blob',
      }
    ),
};
