import axios from 'axios';
import store from '../redux/store';

const getAuthHeaders = () => {
  const { session } = store.getState();
  return {
    headers: {
      Authorization: `Bearer ${session.token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
};

export const supportAPI = {
  getSupportList: (page) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/support/list?page=${page}`,
      getAuthHeaders()
    ),
};
