import { createSelector } from 'reselect';

const sessionSelector = (state) => state.session;
export const getSessionAutorizedSelector = createSelector(
  sessionSelector,
  (state) => state.autorized
);
export const getSessionTokenSelector = createSelector(sessionSelector, (state) => state.token);
export const getSessionIsTooManyRequestSelector = createSelector(
  sessionSelector,
  (state) => state.isTooManyRequest
);
export const getSessionLoginSelector = createSelector(sessionSelector, (state) => state.login);
export const getSessionPasswordSelector = createSelector(
  sessionSelector,
  (state) => state.password
);
export const getSessionPositionsSelector = createSelector(
  sessionSelector,
  (state) => state.positions
);
export const getSessionCompletedFieldsSelector = createSelector(
  sessionSelector,
  (state) => state.completedFields
);
export const getSessionPoleSelector = createSelector(sessionSelector, (state) => state.role);
export const getSessionInviteHashSelector = createSelector(
  sessionSelector,
  (state) => state.inviteHash
);
export const getSessionDataFromSocSelector = createSelector(
  sessionSelector,
  (state) => state.dataFromSoc
);
export const getSessionAccountInfoSelector = createSelector(
  sessionSelector,
  (state) => state.accountInfo
);
export const getSessionFlagsSelector = createSelector(sessionSelector, (state) => state.flags);
export const getSessionHashSelector = createSelector(sessionSelector, (state) => state.hash);
