import React from 'react';

export const TGIcon = () => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34 17C34 26.3888 26.3888 34 17 34C7.61116 34 0 26.3888 0 17C0 7.61116 7.61116 0 17 0C26.3888 0 34 7.61116 34 17ZM17.6092 12.5501C15.9557 13.2379 12.651 14.6614 7.69518 16.8205C6.89043 17.1406 6.46886 17.4536 6.43049 17.7598C6.36563 18.2771 7.01351 18.4808 7.89574 18.7583C8.01575 18.796 8.1401 18.8351 8.26758 18.8765C9.13557 19.1587 10.3032 19.4888 10.9102 19.5019C11.4608 19.5138 12.0753 19.2868 12.7537 18.8209C17.3841 15.6953 19.7744 14.1154 19.9244 14.0813C20.0303 14.0573 20.177 14.0271 20.2764 14.1154C20.3758 14.2038 20.366 14.3711 20.3555 14.416C20.2913 14.6896 17.7481 17.054 16.4321 18.2775C16.0218 18.659 15.7308 18.9295 15.6713 18.9913C15.538 19.1297 15.4022 19.2607 15.2716 19.3865C14.4653 20.1639 13.8605 20.7468 15.3051 21.6988C15.9993 22.1563 16.5548 22.5345 17.109 22.912C17.7142 23.3241 18.3179 23.7352 19.099 24.2472C19.2979 24.3776 19.488 24.5131 19.6731 24.6451C20.3774 25.1472 21.0102 25.5983 21.792 25.5264C22.2463 25.4846 22.7155 25.0574 22.9538 23.7835C23.517 20.7728 24.624 14.2495 24.8798 11.5614C24.9022 11.3259 24.874 11.0245 24.8514 10.8921C24.8287 10.7598 24.7814 10.5713 24.6094 10.4318C24.4058 10.2665 24.0913 10.2316 23.9507 10.2341C23.3113 10.2454 22.3303 10.5865 17.6092 12.5501Z"
        fill="#8672E8"
      />
    </svg>
  );
};

export const TGIconSmall = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.28668 5.90595C7.50857 6.2296 5.95342 6.89946 3.62126 7.91555C3.24255 8.06615 3.04417 8.21348 3.02611 8.35754C2.99559 8.601 3.30048 8.69687 3.71565 8.82742C3.77212 8.84517 3.83064 8.86357 3.89062 8.88307C4.29909 9.01585 4.84855 9.17118 5.13419 9.17735C5.3933 9.18295 5.68249 9.07613 6.00176 8.85689C8.18077 7.386 9.30559 6.64254 9.37621 6.62651C9.42603 6.6152 9.49507 6.60099 9.54184 6.64256C9.58862 6.68414 9.58402 6.76288 9.57907 6.784C9.54887 6.91276 8.35209 8.02539 7.73275 8.60118C7.53967 8.78068 7.40272 8.90801 7.37472 8.93709C7.312 9.00223 7.24808 9.06385 7.18665 9.12307C6.80718 9.48888 6.52261 9.76321 7.20241 10.2112C7.52909 10.4265 7.7905 10.6045 8.05129 10.7821C8.33611 10.976 8.62019 11.1695 8.98774 11.4104C9.08138 11.4718 9.17082 11.5356 9.25792 11.5977C9.58938 11.834 9.88717 12.0463 10.2551 12.0124C10.4688 11.9928 10.6896 11.7917 10.8018 11.1922C11.0668 9.77543 11.5878 6.70564 11.7081 5.44065C11.7187 5.32982 11.7054 5.18798 11.6948 5.12572C11.6841 5.06345 11.6618 4.97474 11.5809 4.90907C11.4851 4.83129 11.3371 4.81489 11.2709 4.81606C10.97 4.82136 10.5084 4.98188 8.28668 5.90595Z"
        fill="#8672E8"
      />
    </svg>
  );
};
