import React from 'react';

const SelectArrow = () => {
  return (
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.25 0.5L14 2.3342L7 9.5L0 2.3342L1.75 0.5L7 5.9L12.25 0.5Z" fill="#94A3B8" />
    </svg>
  );
};

export default React.memo(SelectArrow);
