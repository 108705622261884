import Axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { clubcardFormsApi } from '../../api/clubcardForms';
import { addAlert } from './alerts';
import { setErrorMessage } from './messageToUser';
import { setIsLoad } from './preloader';

export const clubcardFormsSlice = createSlice({
  name: 'clubcardForms',
  initialState: {
    clubcardForms: [],
    selectedFormId: '',
    previewData: null,
  },
  reducers: {
    setClubcardForms: (state, data) => {
      state.clubcardForms = data.payload;
    },
    setSelectedFormId: (state, data) => {
      state.selectedFormId = data.payload;
    },
    setPreviewData: (state, data) => {
      state.previewData = data.payload;
    },
  },
});

export const { setClubcardForms, setSelectedFormId, setPreviewData } = clubcardFormsSlice.actions;

export default clubcardFormsSlice.reducer;

export const getClubcardForms = (callback) => async (dispatch) => {
  try {
    dispatch(setIsLoad(true));
    const response = await clubcardFormsApi.getClubcardForms();
    dispatch(setClubcardForms(response.data.data));
    callback && callback(response.data.data);
  } catch (err) {
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const createClubcardForm = (formId, params, callback, type) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    await clubcardFormsApi.createClubcardForm(formId, params, type).then((res) => {
      dispatch(addAlert(type === 'add' ? 'Анкета создана' : 'Анкета изменена', 'success'));
      callback(res.data.data);
    });
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
    dispatch(
      setErrorMessage({
        message:
          (err.response && err.response.data && err.response.data.message) || 'Что-то пошло не так',
        type: 'loyalty',
      })
    );
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const uploadPrivacyPolicy = (endPoint) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await Axios.get(`${process.env.REACT_APP_TENQ_API}${endPoint}`, {
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement('a');
    a.href = url;
    a.download = 'privacy_policy.pdf';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (err) {
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};
export const getEditClubcardFormInfo = (id, callback) => async () => {
  try {
    const response = await clubcardFormsApi.getEditClubcardFormInfo(id).then((res) => {
      callback && callback(res.data);
      return res.data;
    });
    return response;
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const deleteClubcardForm = (id, callback) => async (dispatch) => {
  try {
    const response = await clubcardFormsApi.deleteClubcardForm(id).then((res) => {
      callback && callback();
      dispatch(getClubcardForms());
      return res;
    });
    console.log(response);
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const checkClubcardFormName = (name) => async () => {
  try {
    const response = await clubcardFormsApi.checkClubcardFormName(name).then((res) => {
      return res;
    });
    return response.data.data.is_name_exists;
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};
