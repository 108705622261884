import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './MessageToUser.module.css';
import { setErrorMessage } from '../../../redux/actions/messageToUser';

export const MessageToUser = () => {
  const message = useSelector((state) => state.error.errorMessage);
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(setErrorMessage(''));
  };
  return (
    <>
      {message && (
        <div className={s.container}>
          <span className={s.x} onClick={onClose}>
            x
          </span>
          <span className={s.message}>{message}</span>
        </div>
      )}
    </>
  );
};
export const FieldError = ({
  message,
  y = 0,
  setErrorMessage,
  type,
  onSetSingleStateErrMsg,
  position = 'absolute',
}) => {
  const styleError = {
    position,
    fontWeight: '550',
    transform: `translateY(${y + 6}px)`,
    fontSize: '12px',
    lineHeight: '12px',
    color: '#FF5950',
    whiteSpace: position === 'absolute' ? 'nowrap' : '',
    background: 'transparent',
    height: 0,
  };

  const timeoutRef = useRef(null);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      if (type) {
        onSetSingleStateErrMsg();
      } else {
        setErrorMessage({ message: '', type: '' });
      }
    }, 3500);
  }, [message]);
  return <div style={styleError}>{message}</div>;
};

export const FieldMessage = ({ setSingleStateFieldMsg, message, y = 0, setFieldMessage }) => {
  const styleField = {
    position: 'absolute',
    fontWeight: '550',
    transform: `translateY(${y}px)`,
    fontSize: '12px',
    lineHeight: '12px',
    color: '#46c67c',
  };
  useEffect(() => {
    if (setFieldMessage) {
      setTimeout(() => {
        if (setSingleStateFieldMsg) {
          setSingleStateFieldMsg();
        } else {
          setFieldMessage({ message: '', type: '' });
        }
      }, 3000);
    }
  }, []);
  return <div style={styleField}>{message}</div>;
};
