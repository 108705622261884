import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import style from './reserveTable.module.css';
import { getCurrentSelectedTable } from '../../../../../redux/selectors/tables';

const ReserveTable = ({ closeModal }) => {
  const currentSelected = useSelector(getCurrentSelectedTable);
  return (
    <div>
      <div className={style.wrapper}>
        <div className={style.subTitle}>
          Стол №{currentSelected.num && currentSelected.num} будет убран в резерв
        </div>
      </div>
      <div className={style.buttonsWrapper}>
        <div className={style.buttonContent}>
          <button onClick={closeModal} type="button" className={style.backButton}>
            Отмена
          </button>
        </div>
        <div className={style.buttonContent}>
          <button type="button" className={`${style.saveButton} `}>
            Продолжить
          </button>
        </div>
      </div>
    </div>
  );
};
ReserveTable.propTypes = {
  closeModal: PropTypes.func,
};
export default React.memo(ReserveTable);
