import { createSlice } from '@reduxjs/toolkit';
import { loyaltyApi } from '../../api/loyalty';
import { setErrorMessage } from './messageToUser';
import { setIsLoad } from './preloader';

export const loyaltySlice = createSlice({
  name: 'loyalty',
  initialState: {
    promotions: [],
    dndVersion: null,
    showModalCreatePromo: false,
    showModalEditPromo: false,
    editPromoId: null,
    canSendRequest: true,
  },
  reducers: {
    setPromotions: (state, data) => {
      state.promotions = data.payload;
    },
    setDndVersion: (state, data) => {
      state.dndVersion = data.payload;
    },
    setShowModalCreatePromo: (state, data) => {
      state.showModalCreatePromo = data.payload;
    },
    setShowModalEditPromo: (state, data) => {
      state.showModalEditPromo = data.payload.show;
      state.editPromoId = data.payload.promoId;
    },
    setCanSendRequest: (state, data) => {
      state.canSendRequest = data.payload;
    },
  },
});

export const {
  setPromotions,
  setShowModalCreatePromo,
  setShowModalEditPromo,
  setCanSendRequest,
  setDndVersion,
} = loyaltySlice.actions;

export default loyaltySlice.reducer;

export const getPromotions = () => async (dispatch, getState) => {
  try {
    const response = await loyaltyApi.getPromotions(getState().profile.data.organisation.id);
    dispatch(setPromotions(response.data.promotions));
    dispatch(setDndVersion(response.data.drag_and_drop_version));
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const createPromotion = (promotion) => async (dispatch, getState) => {
  dispatch(setCanSendRequest(false));
  try {
    const dndVersion = getState().loyalty.dndVersion;
    if (dndVersion !== null && promotion) {
      promotion.append('drag_and_drop_version', dndVersion);
    }
    await loyaltyApi.createPromotion(promotion);
    dispatch(setShowModalCreatePromo(false));
    dispatch(getPromotions());
    dispatch(setCanSendRequest(true));
  } catch (err) {
    dispatch(setCanSendRequest(true));
    const message = 'При отправке данных произошла ошибка';
    dispatch(setErrorMessage({ message, type: 'promo' }));
    console.log(err);
    console.log(err.response);
  }
};

export const updatePromotion = (promotion, promotionId) => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  dispatch(setCanSendRequest(false));
  try {
    const dndVersion = getState().loyalty.dndVersion;
    if (dndVersion !== null && promotion) {
      promotion.append('drag_and_drop_version', dndVersion);
    }
    await loyaltyApi.updatePromotion(promotion, promotionId);
    dispatch(setShowModalEditPromo(false));
    dispatch(getPromotions());
    dispatch(setCanSendRequest(true));
    dispatch(setIsLoad(false));
  } catch (err) {
    dispatch(setIsLoad(false));
    dispatch(setCanSendRequest(true));
    const message = 'При отправке данных произошла ошибка';
    dispatch(setErrorMessage({ message, type: 'promo' }));
    console.log(err);
    console.log(err.response);
  }
};

export const changePriority = (formData, promotionId) => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  dispatch(setCanSendRequest(false));
  try {
    const dndVersion = getState().loyalty.dndVersion;
    if (dndVersion !== null && formData) {
      formData.append('drag_and_drop_version', dndVersion);
    }
    await loyaltyApi.updatePromotion(formData, promotionId);
    dispatch(getPromotions());
    dispatch(setCanSendRequest(true));
    dispatch(setIsLoad(false));
  } catch (err) {
    dispatch(setIsLoad(false));
    dispatch(setCanSendRequest(true));
    console.log(err);
    console.log(err.response);
  }
};

export const deletePromotion = (id) => async (dispatch, getState) => {
  try {
    await loyaltyApi.deletePromotion(id, getState().loyalty.dndVersion);
    dispatch(getPromotions());
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};
