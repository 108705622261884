// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../images/icons/radio.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomRadio_radioBtnBox__3spQl {
    display: flex;
    align-items: center;
    gap: 15px;
    padding-left: 12px;
    height: 42px;
    background-color: #FFFFFF;
    border-radius: 10px;
    cursor: pointer;
    border: 2px solid #F2F5FA;
}

.CustomRadio_radioBtText__9NGG8 {
    font-weight: 550;
    font-size: 14px;
    line-height: 20px;
    color: #1E293B;
}

.CustomRadio_radioBtn__-pDFE {
    display: none;
}

.CustomRadio_radioBtn__-pDFE + label {
    display: inline-flex;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
}

.CustomRadio_radioBtn__-pDFE + label::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #d8dde4;
    border-radius: 20px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 197%;
    background-color: #E2E8F0;
}

.CustomRadio_radioBtn__-pDFE:checked + label::before {
    background-color: #A3DD34;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    border: 3px solid #A3DD34;
}
`, "",{"version":3,"sources":["webpack://./src/App/features/atoms/CustomRadio/CustomRadio.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,oBAAoB;IACpB,yBAAiB;YAAjB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,cAAc;IACd,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,4BAA4B;IAC5B,kCAAkC;IAClC,qBAAqB;IACrB,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,yDAA2D;IAC3D,yBAAyB;AAC7B","sourcesContent":[".radioBtnBox {\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    padding-left: 12px;\n    height: 42px;\n    background-color: #FFFFFF;\n    border-radius: 10px;\n    cursor: pointer;\n    border: 2px solid #F2F5FA;\n}\n\n.radioBtText {\n    font-weight: 550;\n    font-size: 14px;\n    line-height: 20px;\n    color: #1E293B;\n}\n\n.radioBtn {\n    display: none;\n}\n\n.radioBtn + label {\n    display: inline-flex;\n    user-select: none;\n    cursor: pointer;\n}\n\n.radioBtn + label::before {\n    content: '';\n    display: inline-block;\n    width: 18px;\n    height: 18px;\n    flex-shrink: 0;\n    flex-grow: 0;\n    border: 1px solid #d8dde4;\n    border-radius: 20px;\n    background-repeat: no-repeat;\n    background-position: center center;\n    background-size: 197%;\n    background-color: #E2E8F0;\n}\n\n.radioBtn:checked + label::before {\n    background-color: #A3DD34;\n    background-image: url('../../../../images/icons/radio.svg');\n    border: 3px solid #A3DD34;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioBtnBox": `CustomRadio_radioBtnBox__3spQl`,
	"radioBtText": `CustomRadio_radioBtText__9NGG8`,
	"radioBtn": `CustomRadio_radioBtn__-pDFE`
};
export default ___CSS_LOADER_EXPORT___;
