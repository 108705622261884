import { createSlice } from '@reduxjs/toolkit';
import { setErrorMessage } from './messageToUser';
import { setIsLoad } from './preloader';
import { ordersApi } from '../../api/orders';
import { setModal, setOrderDetailsModal } from './modal';
import { addAlert } from './alerts';
import { callRkeeperCommand } from './integrationRkeeper';

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: {
    orders: [],
    takeAwayOrders: 0,
    notTakeAwayOrders: 0,
    isRkeeperInfoLoaded: false,
    isDiscountApplied: false,
    selectedOrder: null,
    orderDetail: null,
    statusFilter: ['0', '1', '2'],
    statusUpdate: false,
    dateFilter: {
      period: 'day',
      from: '',
      to: '',
      isCurrentPeriod: false,
      translatePeriods: {
        day: 'Сегодня',
        week: 'Неделя',
        month: 'Месяц',
      },
    },
  },
  reducers: {
    setOrders: (state, data) => {
      state.orders = data.payload;
    },
    setSelectedOrder: (state, data) => {
      state.selectedOrder = data.payload;
    },
    setOrderDetail: (state, data) => {
      state.orderDetail = data.payload;
    },
    setPeriod: (state, data) => {
      state.dateFilter.period = data.payload;
    },
    setFrom: (state, data) => {
      state.dateFilter.from = data.payload;
    },
    setTo: (state, data) => {
      state.dateFilter.to = data.payload;
    },
    setIsCurrentPeriod: (state, data) => {
      state.dateFilter.isCurrentPeriod = data.payload;
    },
    setStatusUpdate: (state, data) => {
      state.dateFilter.statusUpdate = data.payload;
    },
    setStatusFilter: (state, data) => {
      state.statusFilter = data.payload;
    },
    clearDateFilter: (state) => {
      state.dateFilter = {
        ...state.dateFilter,
        period: 'day',
        from: '',
        to: '',
        isCurrentPeriod: false,
      };
    },
    clearStatusOfFilter: (state) => {
      state.statusFilter = [];
    },
    setTakeAwayOrders: (state, data) => {
      state.takeAwayOrders = data.payload;
    },
    setNotTakeAwayOrders: (state, data) => {
      state.notTakeAwayOrders = data.payload;
    },
    setIsRkeeperInfoLoaded: (state, data) => {
      state.isRkeeperInfoLoaded = data.payload;
    },
    setIsDiscountApplied: (state, data) => {
      state.isDiscountApplied = data.payload;
    },
  },
});

export const {
  setOrders,
  setStatusFilter,
  setIsCurrentPeriod,
  setStatusUpdate,
  setSelectedOrder,
  setFrom,
  setTo,
  setPeriod,
  setOrderDetail,
  clearDateFilter,
  clearStatusOfFilter,
  setTakeAwayOrders,
  setIsRkeeperInfoLoaded,
  setIsDiscountApplied,
  setNotTakeAwayOrders,
} = ordersSlice.actions;

export default ordersSlice.reducer;

export const getOrders = () => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await ordersApi.getOrders();
    response && dispatch(setOrders(response.data.data));
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const getCurrentPeriod = (filter) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    await ordersApi.getFilteredOrders(filter).then((res) => {
      dispatch(setTakeAwayOrders(res?.data?.count_takeaway_orders || 0));
      dispatch(setNotTakeAwayOrders(res?.data?.count_not_takeaway_orders || 0));
      dispatch(setOrders(res.data.data));
    });
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const getOrderDetail = (id, status, disableLoader) => async (dispatch) => {
  if (!disableLoader) {
    dispatch(setIsLoad(true));
  }
  try {
    if (status && ['Дозаказ', 'Принято', 'Принято и оплачено'].includes(status)) {
      dispatch(callRkeeperCommand('sync_order', true, null, { order_id: id }));
    }
    const response = await ordersApi.getOrderDetail(id);
    if (response) {
      dispatch(setOrderDetail(response.data.data));
      dispatch(setOrderDetailsModal(true));
    }
  } catch (err) {
    const message = 'При загрузке данных произошла ошибка';
    dispatch(setErrorMessage({ message, type: 'saveShift' }));
    console.log(err);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const calculatePartialOrder = (body, orgId) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const res = await ordersApi.calculatePartialOrder(body, orgId);
    return res;
  } catch (err) {
    const message = 'Возникла ошибка';
    dispatch(addAlert(message));
    console.log(err);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const sendOrderAnswer = (body, message) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    await ordersApi.acceptanceOrder(body).then(() => {
      setTimeout(() => dispatch(setStatusUpdate(new Date())), 1000);

      dispatch(setModal(false));
      dispatch(addAlert(message, 'success'));
    });
  } catch (err) {
    console.log(err);
    dispatch(addAlert('Возникла ошибка', 'error'));
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const sendOrderAdditionAnswer = (body, message) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    await ordersApi.acceptanceAdditionOrder(body).then(() => {
      setTimeout(() => dispatch(setStatusUpdate(new Date())), 1000);
      dispatch(setModal(false));
      dispatch(addAlert(message, 'success'));
    });
  } catch (err) {
    console.log(err);
    dispatch(addAlert('Возникла ошибка', 'error'));
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const cancelOrder = (id, message) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    await ordersApi.cancelOrder(id).then(() => {
      dispatch(addAlert(message, 'success'));
      setTimeout(() => dispatch(setStatusUpdate(id)), 1000);
    });
  } catch (err) {
    console.log(err);
    dispatch(addAlert('Возникла ошибка', 'error'));
  } finally {
    dispatch(setIsLoad(false));
  }
};
export const closeTakeawayOrder = (id, message, callback) => async (dispatch) => {
  try {
    await ordersApi.closeTakeawayOrder(id).then(() => {
      setTimeout(() => dispatch(setStatusUpdate(id)), 1000);
      callback && callback();
    });
  } catch (err) {
    console.log(err);
    dispatch(addAlert('Возникла ошибка', 'error'));
  }
};

export const getTakeAwayOrders = () => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await ordersApi.getTakeAwayOrders();
    if (response) {
      const orders = response.data.data;
      dispatch(setTakeAwayOrders(orders));
    }
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const getRkeeperOrderInformation = (order_id) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    await ordersApi.getRkeeperOrderInformation(order_id);
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const deleteOrderDish = (order_id, data, type, callback) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    await ordersApi.deleteOrderDish(order_id, data, type).then(() => {
      callback && callback();
    });
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const takeToWorkOrder = (order_id, callback) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    await ordersApi
      .takeToWorkOrder(order_id)
      .then(() => {
        callback && callback();
      })
      .catch((err) => new Error(err));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const paidInCashOrder = (data, message, callback) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    await ordersApi.paidInCashOrder(data).then(() => {
      dispatch(addAlert(message, 'success'));
      callback && callback();
    });
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
  } finally {
    dispatch(setIsLoad(false));
  }
};
