import { useCallback, useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getOrderDetail as fetchOrderDetail,
  setIsRkeeperInfoLoaded,
  setIsDiscountApplied,
  setOrderDetail,
  paidInCashOrder,
  closeTakeawayOrder,
  sendOrderAnswer,
  sendOrderAdditionAnswer,
  setStatusUpdate,
  calculatePartialOrder,
} from '../../../../../redux/actions/orders';
import { getOrderDetail } from '../../../../../redux/selectors/orders';
import { setOrderDetailsModal } from '../../../../../redux/actions/modal';
import { addAlert } from '../../../../../redux/actions/alerts';
import { callRkeeperCommand } from '../../../../../redux/actions/integrationRkeeper';

export const useOrderDetail = () => {
  const dispatch = useDispatch();

  const { isRkeeperInfoLoaded, isDiscountApplied } = useSelector((state) => state.orders);
  const orderDetail = useSelector(getOrderDetail);
  const [partPay, setPartPay] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [partPaidSum, setPartPaidSum] = useState('');
  const [scanType, setScanType] = useState('');
  const userAgent = String(navigator?.userAgent || navigator?.vendor || window?.opera);
  const isMobile =
    userAgent.includes('iPhone') ||
    userAgent.includes('Android') ||
    userAgent.includes('iPad') ||
    userAgent.includes('HUAWEI');
  const getOrderStatusBackColor = useCallback((status) => {
    switch (status) {
      case 'Новое':
      case 'Дозаказ':
        return '#FB923C';
      case 'Принято':
      case 'Принято и оплачено':
        return '#A3DD34';
      case 'Отклонено':
        return '#F15252';
      case 'Оплачено':
      case 'Завершено':
        return '#8672E8';
      default:
        return '#FB923C';
    }
  }, []);

  const onScanQr = (resultValue) => {
    try {
      const slug = JSON.parse(resultValue)?.slug;
      if (!slug) {
        throw new Error('Invalid QR Code');
      } else {
        setScanType('');
        dispatch(
          callRkeeperCommand(
            'discount_apply',
            false,
            () => {
              setTimeout(() => {
                dispatch(fetchOrderDetail(orderDetail?.id));
              }, 4000);
            },
            {
              loyal_user_card_slug: slug,
              order_id: orderDetail?.id,
            }
          )
        );
      }
    } catch {
      dispatch(addAlert('Неверный QR-код'));
    }
  };

  const handleClick = (index) => {
    if (index < orderDetail?.parts_paid) return;

    if (activeIndex === index) {
      setActiveIndex(null);
      setPartPaidSum('');
    } else {
      setActiveIndex(index);
      onPartPaidSum(index);
    }
  };
  const orderStatusBackColor = useMemo(() => {
    const status = orderDetail?.status;
    return { backgroundColor: getOrderStatusBackColor(status) };
  }, [orderDetail?.status, getOrderStatusBackColor]);

  const isAllowTimeLabel = useMemo(() => {
    const { status, complete_order_time, is_takeaway_order } = orderDetail;
    return !['Оплачено', 'Завершено'].includes(status) && complete_order_time && is_takeaway_order;
  }, [orderDetail]);

  const onclickCancel = useCallback(() => {
    dispatch(setOrderDetailsModal(false));
    dispatch(setIsRkeeperInfoLoaded(false));
    dispatch(setStatusUpdate(new Date()));
  }, [dispatch]);

  const onCancelAfterPaid = useCallback(() => {
    dispatch(setStatusUpdate(new Date()));
    onclickCancel();
  }, [dispatch]);

  const onCancelOrder = useCallback(
    (type) => {
      const message = `${type} ${
        orderDetail?.number && orderDetail?.number !== 'null' ? `#${orderDetail?.number}` : ''
      } отправлен в R-kepeer для удаления`;
      dispatch(
        callRkeeperCommand(
          'cancel_order',
          true,
          () => {
            setTimeout(() => dispatch(setStatusUpdate(new Date())), 1500);

            dispatch(setOrderDetailsModal(false));
            dispatch(addAlert(message, 'success'));
          },
          {
            order_id: orderDetail.id,
          }
        )
      );
      onclickCancel();
    },
    [dispatch, orderDetail, onclickCancel]
  );

  const onCloseTakeawayOrder = useCallback(() => {
    dispatch(
      closeTakeawayOrder(orderDetail.id, '', () => {
        dispatch(setOrderDetailsModal(false));
      })
    );
  }, [dispatch, orderDetail]);

  const onPaidInCashOrder = useCallback(() => {
    if (orderDetail?.payment_type === 'single') {
      const message = `Произведена оплата наличными по заказу #${orderDetail?.number}`;
      const body = {
        order_id: orderDetail?.id,
        type: orderDetail?.payment_type,
      };
      dispatch(paidInCashOrder(body, message, onCancelAfterPaid));
    } else {
      setPartPay(true);
    }
  }, [dispatch, orderDetail, onclickCancel]);

  const onPartPaidInCashOrder = useCallback(() => {
    const message = `Произведена оплата наличными по заказу #${orderDetail?.number}`;
    const body = {
      order_id: orderDetail?.id,
      type: orderDetail?.payment_type,
      parts_total: orderDetail?.parts_total,
      parts_number: activeIndex + 1 - orderDetail?.parts_paid,
      is_last_part: activeIndex + 1 === orderDetail?.parts_total,
    };
    dispatch(paidInCashOrder(body, message, onclickCancel));
  }, [dispatch, orderDetail, onclickCancel, activeIndex]);

  const handleOrder = useCallback(
    (answer, isAdditionalOrder = false) => {
      const orderType = isAdditionalOrder
        ? 'Дозаказ'
        : orderDetail?.status === 'Новое' || orderDetail?.status === 'Принято'
        ? 'Заказ'
        : 'Дозаказ';
      const body = {
        order_id: orderDetail?.id,
        acceptance: answer,
      };
      const orderNum =
        orderDetail?.number && orderDetail?.number !== 'null' ? `#${orderDetail?.number}` : '';
      const message = `${orderType} ${orderNum} ${
        answer === 'confirm'
          ? 'принят'
          : answer === 'decline' && orderDetail?.status !== 'Дозаказ'
          ? 'отправлен в R-kepeer для удаления'
          : 'отклонен'
      }`;
      if (isAdditionalOrder) {
        dispatch(
          answer
            ? callRkeeperCommand(
                'owner_additional_order',
                true,
                () => {
                  setTimeout(() => dispatch(setStatusUpdate(new Date())), 1000);

                  dispatch(setOrderDetailsModal(false));
                  dispatch(addAlert(`Дозаказ ${orderDetail?.number} принят`, 'success'));
                },
                body
              )
            : sendOrderAdditionAnswer(body, message)
        );
      } else {
        dispatch(
          answer
            ? callRkeeperCommand(
                'owner_create_order',
                true,
                () => {
                  setTimeout(() => dispatch(setStatusUpdate(new Date())), 1000);

                  dispatch(setOrderDetailsModal(false));
                  dispatch(addAlert(message, 'success'));
                },
                body
              )
            : sendOrderAnswer(body, message)
        );
      }
    },
    [dispatch, orderDetail]
  );
  const onPartPaidSum = useCallback(
    (index) => {
      const { parts_paid, parts_total, id, organisation_id } = orderDetail || {};
      const extraPartsPaid = index + 1 - parts_paid;
      const body = {
        parts_total,
        parts_number: extraPartsPaid,
        order_id: id,
        type: 'equal_parts',
        is_last_part: extraPartsPaid + parts_paid === parts_total,
      };
      dispatch(calculatePartialOrder(body, organisation_id)).then((res) => {
        const partialPaidSum = (res?.data?.amount / 100).toString();
        setPartPaidSum(partialPaidSum);
      });
    },
    [orderDetail]
  );
  const onOrderHandler = useCallback(
    (answer) => {
      handleOrder(answer, false);
    },
    [handleOrder]
  );

  const onOrderAdditionHandler = useCallback(
    (answer) => {
      handleOrder(answer, true);
    },
    [handleOrder]
  );

  const isEnabledDeleteDish = useMemo(() => {
    const allowedStatuses = ['Оплачено', 'Отклонено', 'Завершено'];
    return !allowedStatuses.includes(orderDetail?.status) && !orderDetail.is_takeaway_order;
  }, [orderDetail]);

  useEffect(() => {
    if (isRkeeperInfoLoaded) {
      dispatch(setIsRkeeperInfoLoaded(false));
      dispatch(fetchOrderDetail(orderDetail?.id, undefined, true));
    }
    if (isDiscountApplied) {
      dispatch(setIsDiscountApplied(false));
      dispatch(fetchOrderDetail(orderDetail?.id, undefined, true));
    }
  }, [isRkeeperInfoLoaded, isDiscountApplied, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setOrderDetail({}));
    };
  }, [dispatch]);
  const paidProgress = useMemo(() => {
    return (Number(orderDetail?.paid_sum) / Number(orderDetail?.total_sum)) * 100;
  }, [orderDetail?.paid_sum, orderDetail?.total_sum]);
  const productNeedScan = useMemo(() => {
    const dishes = orderDetail?.order?.basic_dishes;
    const newDishes = orderDetail?.order?.new_dishes;
    const dishesNeedScan = dishes
      ?.map((dish, index) => (dish?.is_dish_marking ? { ...dish, scanned: false, index } : null))
      .filter(Boolean);

    const newDishesNeedScan = newDishes
      ?.map((dish, index) => (dish?.is_dish_marking ? { ...dish, scanned: false, index } : null))
      .filter(Boolean);
    return (
      (dishesNeedScan?.length > 0 && dishesNeedScan) ||
      (newDishesNeedScan?.length > 0 && newDishesNeedScan)
    );
  }, [orderDetail?.order?.basic_dishes, orderDetail?.order?.new_dishes]);
  return {
    orderStatusBackColor,
    isAllowTimeLabel,
    isEnabledDeleteDish,
    onclickCancel,
    onCancelOrder,
    onCloseTakeawayOrder,
    onPaidInCashOrder,
    onOrderHandler,
    onOrderAdditionHandler,
    orderDetail,
    paidProgress,
    partPay,
    setPartPay,
    handleClick,
    activeIndex,
    onPartPaidInCashOrder,
    partPaidSum,
    productNeedScan,
    onScanQr,
    scanType,
    setScanType,
    isMobile,
  };
};
