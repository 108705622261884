import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QRCodeStyling from 'qr-code-styling';
import { v4 as uuidv4 } from 'uuid';
import s from './InviteEmployee.module.css';
import Input from '../../../atoms/Input';
import { InputSelect } from '../../../../components/InputCustom/InputSelect/InputSelect';
import InputTel from '../../../atoms/InputTel';
import { FieldError } from '../../../../components/MessageToUser/MessageToUser';
import Button from '../../../atoms/Button';
import { addPersonalWithQr } from '../../../../../redux/actions/organisation';
import ButtonCancel from '../../../atoms/ButtonCancel';
import useOutsideDetect from '../../../../hooks/useOutsideDetect';
import { setInviteData } from '../../../../../redux/actions/management';
import {
  getInviteDataSelector,
  getPositionsSelector,
} from '../../../../../redux/selectors/management';

const InviteEmployee = ({ closeModal }) => {
  const dispatch = useDispatch();
  const inviteData = useSelector(getInviteDataSelector);

  const [qRCodeStylingInstance, setQRCodeStylingInstance] = useState(false);
  const [closeIt, setCloseIt] = useState(false);
  const popUpRef = useOutsideDetect(() => setCloseIt(true));

  const positions = useSelector(getPositionsSelector);
  const isLoad = useSelector((state) => state.preloader.isLoad);
  const [selectedPositionId, setSelectedPositionId] = useState(inviteData.selectedPositionId);
  const selectedPosition = useMemo(
    () => positions.find((position) => position.id === selectedPositionId),
    [positions, selectedPositionId]
  );

  const [mode, setMode] = useState('tel');
  const [phone, setPhone] = useState(inviteData.phone);
  const [email, setEmail] = useState(inviteData.email || '');

  const [hash, setHash] = useState('');
  const [name, setName] = useState(inviteData.name);
  const [surname, setSurname] = useState(inviteData.surname);

  const serverError = useSelector((state) => state.messageToUser.errorMessage);
  const [localError, setLocalError] = useState({ message: '', type: '' });
  const error = localError.message ? localError : serverError;

  const onChangeMode = useCallback(() => {
    setMode((mode) => (mode === 'tel' ? 'email' : 'tel'));
  }, [mode]);

  const loginLabel = useMemo(() => {
    return (
      <span>
        {mode === 'tel' ? 'Телефон' : 'Email'} сотрудника (или
        <span onClick={onChangeMode} style={{ cursor: 'pointer', color: '#8672e8' }}>
          {mode === 'tel' ? ' Email' : ' телефон'}
        </span>
        )
      </span>
    );
  }, [mode]);

  useEffect(() => {
    const qrHash = uuidv4();
    const qrCode = new QRCodeStyling({
      width: 300,
      height: 300,
      type: 'png',
      data: `${process.env.REACT_APP_TENQ_PAYMENT}/${qrHash}`,
      dotsOptions: {
        color: '#5300a2',
        type: 'square',
      },
      cornersSquareOptions: {
        type: 'square',
      },
      imageOptions: {
        crossOrigin: 'anonymous',
        margin: 0.5,
      },
    });
    setQRCodeStylingInstance(qrCode);
    setHash(qrHash);
  }, []);

  const onAddPersonal = async (e) => {
    const formData = new FormData();
    const qrFile = await qRCodeStylingInstance.getRawData('png');
    formData.append('position_id', selectedPositionId);
    formData.append('hash', hash);
    formData.append('surname', surname);
    formData.append('name', name);
    formData.append('type_qr_code', 'user');
    formData.append('file', qrFile);
    if (!e.keyCode || e.keyCode === 13) {
      if (mode === 'tel') {
        const code = phone.code;
        const number = phone.number.replace(/\s/g, '');
        if (code === '+') {
          setLocalError({ message: 'Введите код страны', type: 'invite' });
        } else if (!number) {
          setLocalError({ message: 'Введите номер телефона', type: 'invite' });
        } else if (number.length !== 10) {
          setLocalError({
            message: 'Длина номера телефона должны быть не меньше 10',
            type: 'invite',
          });
        } else if (selectedPositionId === null) {
          setLocalError({ message: 'Выберите должность', type: 'invite' });
        } else if (!name || !surname) {
          setLocalError({ message: 'Введите имя и фамилию', type: 'invite' });
        } else {
          formData.append('phone', code.substr(1) + number);
          dispatch(addPersonalWithQr(formData));
        }
      }
      if (mode === 'email') {
        if (!email) {
          setLocalError({ message: 'Введите email', type: 'invite' });
        } else if (!/^.+@.+\..+$/g.test(email)) {
          setLocalError({
            message: 'Введите действительный email',
            type: 'invite',
          });
        } else if (selectedPositionId === null) {
          setLocalError({ message: 'Выберите должность', type: 'invite' });
        } else if (!name || !surname) {
          setLocalError({ message: 'Введите имя и фамилию', type: 'invite' });
        } else {
          formData.append('email', email);
          dispatch(addPersonalWithQr(formData));
        }
      }
    }
  };

  useEffect(() => {
    if (closeIt) {
      dispatch(
        setInviteData({
          phone: {
            code: '+7',
            number: '',
          },
          email: '',
          name: '',
          surname: '',
          selectedPositionId: null,
        })
      );
      closeModal(null, true);
    }
  }, [closeIt]);

  const onClearEmail = useCallback(() => {
    setEmail('');
  }, []);
  const onChangeEmail = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const handleChange = (value, setValue) => {
    if (value?.length <= 20) {
      setValue(value);
    }
  };
  if (isLoad) return null;
  return (
    <div ref={popUpRef}>
      <div className={`${s.title} ${s.field}`}>Добавление сотрудника</div>
      {positions.length > 0 ? (
        <>
          <Input
            value={name}
            onChange={(e) => handleChange(e?.target?.value, setName)}
            className={s.field}
            clearIconTop={30}
            label="Имя"
            isBorder
            onClear={() => setName('')}
          />
          <Input
            value={surname}
            onChange={(e) => handleChange(e?.target?.value, setSurname)}
            className={s.field}
            clearIconTop={30}
            label="Фамилия"
            isBorder
            onClear={() => setSurname('')}
          />
          <InputSelect
            value={selectedPosition && selectedPosition.name}
            selecter={(i) => setSelectedPositionId(positions[i].id)}
            options={positions.map((p) => p.name)}
            top="65px"
            label="Должность"
            className={s.field}
          />
          {mode === 'tel' ? (
            <InputTel
              label={loginLabel}
              className={s.field}
              value={phone}
              onChange={setPhone}
              onKeyDown={onAddPersonal}
            />
          ) : (
            <Input
              label={loginLabel}
              value={email}
              onChange={onChangeEmail}
              containerClassName={`${s.field} ${s.mailInputContainer}`}
              isBorder
              onClear={onClearEmail}
              clearIconTop="60%"
            />
          )}
          {error.type === 'invite' && (
            <FieldError message={error.message} setErrorMessage={setLocalError} y={-20} />
          )}
          <div className={s.btns}>
            <Button onClick={onAddPersonal} title="Добавить" />
            <ButtonCancel onClick={() => setCloseIt(true)} />
          </div>
        </>
      ) : (
        <>
          <div className={`${s.message} ${s.field}`}>
            Чтобы добавить сотрудника, небходимо сначала добавить должность во вкладке Должности
          </div>
          <Button onClick={() => setCloseIt(true)} />
        </>
      )}
    </div>
  );
};

export default React.memo(InviteEmployee);
