import axios from 'axios';
import store from '../redux/store';
import { setSession } from '../redux/actions/session';
import { getProfile } from './profile';
import { setErrorMessage } from '../redux/actions/messageToUser';
import { addAlert } from '../redux/actions/alerts';
import { setMaindata } from '../redux/actions/constructor';

export const getAuthHeaders = () => {
  const { session } = store.getState();
  return {
    headers: {
      Authorization: `Bearer ${session.token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
};
const getLoginHeaders = () => {
  let { login } = store.getState().session;
  if (login.length === 12 && login.search(/\+7\d{10}/) !== -1) {
    login = login.slice(1);
  }
  return {
    headers: { login },
  };
};

export const logout = () => {
  const { session } = store.getState();
  const auth = {
    headers: {
      Authorization: `Bearer ${session.token}`,
    },
  };
  axios
    .post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/auth/logout`,
      { token: session.token, f_token: localStorage?.fcm },
      auth
    )
    .then(() => {
      store.dispatch(
        setSession({
          autorized: false,
          token: null,
        })
      );
      store.dispatch(
        setMaindata({
          fon: '',
          logo: '',
          name: '',
          description: '',
          theme: '',
          buttons: [],
          socilaItems: [],
          dragVersion: '',
          dragVersionButtons: '',
          use_app_link: false,
          organisation_id: '',
          rkeeper_station_active: false,
        })
      );
      window.localStorage.clear();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const login = ({ login, password }) => {
  axios
    .post(`${process.env.REACT_APP_TENQ_API}/api/v0/auth/login`, { login, password })
    .then((res) => {
      store.dispatch(
        setSession({
          autorized: true,
          token: res.data.data.token,
        })
      );
      localStorage.session = res.data.data.token;
      localStorage.auth = true;
      store.dispatch(setErrorMessage({ message: '', type: '' }));
      getProfile();
    })
    .catch(() => {
      store.dispatch(addAlert('Пароль введен неверно'));
    });
};

export const authAPI = {
  loginCheck: (login) =>
    axios.post(`${process.env.REACT_APP_TENQ_API}/api/v0/auth/register/check?login=${login}`),
  getAccountInfo: (login) =>
    axios.post(`${process.env.REACT_APP_TENQ_API}/api/v0/auth/login/user?login=${login}`),
  getCaptcha: () => axios.get(`${process.env.REACT_APP_TENQ_API}/api/v0/captcha/image`),
};

export const registrationAPI = {
  sendLogin: (login, captcha, captcha_hash) =>
    axios.post(`${process.env.REACT_APP_TENQ_API}/api/v0/auth/register`, {
      login,
      captcha,
      captcha_hash,
    }),
  resendCode: (login) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/auth/register/resend/code?login=${login}`,
      {},
      getLoginHeaders()
    ),
  sendInviteLogin: (login, hash) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/auth/register?login=${login}&hash=${hash}`
    ),
  sendCode: (code) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/auth/register/verify/code`,
      { code },
      getLoginHeaders()
    ),
  getPositions: () =>
    axios.get(`${process.env.REACT_APP_TENQ_API}/api/v0/organisations/positions`, getAuthHeaders()),
  sendGender: (data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/auth/register/store`,
      data,
      getLoginHeaders()
    ),
  sendPassword: (password, password_confirmation) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/auth/register/password`,
      { password, password_confirmation },
      getLoginHeaders()
    ),
  getHash: () => axios.get(`${process.env.REACT_APP_TENQ_API}/api/v0/getHash`, getAuthHeaders()),
};

export const recoveryAPI = {
  sendLogin: (login) =>
    axios.post(`${process.env.REACT_APP_TENQ_API}/api/v0/auth/password/reset?login=${login}`),
  sendCode: (code) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/auth/password/reset/verify/code`,
      { code },
      getLoginHeaders()
    ),
};
