import React from 'react';
import stl from './CustomRadio.module.css';

const CustomRadio = ({ title, onClick, checked, id }) => {
  return (
    <div className={stl.radioBtnBox} onClick={() => onClick(id)}>
      <input readOnly checked={checked} type="radio" className={stl.radioBtn} id={id} />
      <label htmlFor={id} />
      <div className={stl.radioBtText}>{title}</div>
    </div>
  );
};

export default React.memo(CustomRadio);
