import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { setModalType } from '../../../../../redux/actions/modal';
import { MODAL_TYPES } from '../../../../constants/modals';
import Confirm from '../../../atoms/Confirm';

const ConfirmClosing = ({ closeModal }) => {
  const dispatch = useDispatch();
  return (
    <Confirm
      onConfirm={(e) => closeModal(e, true)}
      onCancel={() => dispatch(setModalType(MODAL_TYPES.inviteEmployee))}
    />
  );
};

ConfirmClosing.propTypes = {
  closeModal: PropTypes.func,
};

export default React.memo(ConfirmClosing);
