import axios from 'axios';
import store from '../redux/store';

const getAuthHeaders = () => {
  const { session } = store.getState();
  return {
    headers: { Authorization: `Bearer ${session.token}` },
  };
};

export const loyaltyApi = {
  getPromotions: (organisationId) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/auth/organisations/promotions?organisation_id=${organisationId}&visible_all=true`
    ),
  createPromotion: (promotion) => {
    const { session } = store.getState();
    const auth = {
      headers: {
        Authorization: `Bearer ${session.token}`,
        'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryyqGPFxqwYnG4Fyar',
      },
    };
    console.log(auth);
    return axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/create`,
      promotion,
      getAuthHeaders()
    );
  },
  updatePromotion: (promotion, promotionId) => {
    const { session } = store.getState();
    const auth = {
      headers: {
        Authorization: `Bearer ${session.token}`,
        'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryyqGPFxqwYnG4Fyar',
      },
    };
    console.log(auth);
    return axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/update/${promotionId}`,
      promotion,
      getAuthHeaders()
    );
  },
  deletePromotion: (id, dndVersion) =>
    axios.delete(`${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/delete/${id}`, {
      ...getAuthHeaders(),
      params: { drag_and_drop_version: dndVersion },
    }),
};
