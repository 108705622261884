import React, { useState } from 'react';
import SelectArrow from '../../../../assets/Icons/constructor/SelectArrow';
import styles from './customSelect.module.css';

const CustomSelect = ({ values, selected, handleChange }) => {
  const [open, setOpen] = useState(false);
  return (
    <ul role="presentation" onClick={() => setOpen(!open)} className={styles.custsomSelectList}>
      <li className={styles.customSelectWrapper}>
        <span>
          {selected || 'Выберите карту'}
          <div className={open ? styles.arrowRotate : styles.SelectArrow}>
            <SelectArrow />
          </div>
        </span>
        <ul className={`${styles.customSelectSub} ${open ? styles.active : ''}`}>
          {(values &&
            values.length &&
            values.map((element, index) => {
              return (
                <li
                  key={index}
                  role="presentation"
                  onClick={() => handleChange(element.id, element.name)}
                >
                  {element.name}
                </li>
              );
            })) || <li>Не найдено анкет</li>}
        </ul>
      </li>
    </ul>
  );
};

export default React.memo(CustomSelect);
