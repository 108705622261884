import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import s from './PositionCreator.module.css';
import Input from '../../../atoms/Input';
import Button from '../../../atoms/Button';
import ButtonCancel from '../../../atoms/ButtonCancel';
import {
  createPosition,
  editPosition,
  setDeletingPosition,
  setEditablePosition,
} from '../../../../../redux/actions/management';
import { getEditablePositionSelector } from '../../../../../redux/selectors/management';
import { setModalType } from '../../../../../redux/actions/modal';
import { MODAL_TYPES } from '../../../../constants/modals';
import clear from '../../../../../images/clear.svg';
import { addAlert } from '../../../../../redux/actions/alerts';

const PositionCreator = ({ closeModal }) => {
  const dispatch = useDispatch();
  const editablePosition = useSelector(getEditablePositionSelector);
  const isEdit = Object.keys(editablePosition).length > 0;
  const [name, setName] = useState(editablePosition.name || '');
  const [description, setDescription] = useState(editablePosition.description || '');

  const onClose = () => {
    closeModal();
    dispatch(setEditablePosition({}));
  };

  const onSave = () => {
    if (!name) {
      dispatch(addAlert('Введите наименование должности'));
    } else if (isEdit) {
      dispatch(editPosition(editablePosition.id, { name, description }));
    } else {
      dispatch(createPosition({ name, description }));
    }
  };

  useEffect(() => {
    setName(editablePosition.name || '');
    setDescription(editablePosition.description || '');
  }, [editablePosition]);

  const onDelete = () => {
    const posId = editablePosition?.id;
    dispatch(setModalType(MODAL_TYPES.deletingPosition));
    dispatch(setDeletingPosition(posId));
  };

  const onClearPositionName = () => {
    if (editablePosition?.name !== 'Администратор') {
      setName('');
    }
  };

  return (
    <div>
      <div className={`${s.title} ${s.field}`}>
        {isEdit ? 'Редактирование должности' : 'Добавление должности'}
      </div>
      <Input
        value={name}
        onChange={(e) => {
          if (e?.target?.value?.length <= 20) setName(e.target.value);
        }}
        className={`${s.field} ${
          (editablePosition?.name === 'Администратор' || editablePosition?.name === 'Официант') &&
          s.disabledField
        }`}
        clearIconTop={28.5}
        label="Наименование"
        isBorder
        onClear={onClearPositionName}
        withoutClearBtn={
          editablePosition?.name === 'Администратор' || editablePosition?.name === 'Официант'
        }
        disabled={
          editablePosition?.name === 'Администратор' || editablePosition?.name === 'Официант'
        }
      />
      <div className={s.label}>
        <span>Описание</span>
        <span>{description.length} / 500</span>
      </div>
      <div className={s.inputBox}>
        <textarea
          value={description}
          onChange={(e) => {
            if (e?.target?.value?.length <= 500) setDescription(e.target.value);
          }}
          className={s.description}
        />
        {description && (
          <div onClick={() => setDescription('')} className={s.clear}>
            <img src={clear} alt="" />
          </div>
        )}
      </div>
      <div className={s.btns}>
        <Button onClick={onSave} title="Сохранить" isActive={!!name} />
        <ButtonCancel onClick={onClose} />
      </div>
      {isEdit &&
        editablePosition?.name !== 'Администратор' &&
        editablePosition?.name !== 'Официант' && (
          <ButtonCancel
            title="Удалить должность"
            onClick={onDelete}
            className={`${s.deleteBtn} ${s.elementSpace}`}
          />
        )}
    </div>
  );
};

export default React.memo(PositionCreator);

PositionCreator.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
