import { useDispatch, useSelector } from 'react-redux';
import s from './ImportGroupsModal.module.css';
import {
  setImportResult,
  SUCCESS_IMPORT,
  UNSUCCESS_IMPORT,
} from '../../../../../redux/actions/groupsSettings';
import { getImportResult } from '../../../../../redux/selectors/groupSettings';
import Button from '../../../atoms/Button';
import CheckedGreenIcon from '../../../../../images/icons/checkedGreen';
import WarningDangerIcon from '../../../../../images/icons/warningDanger';

export const ImportGroupsModal = ({ width, closeModal }) => {
  const dispatch = useDispatch();
  const importResult = useSelector(getImportResult);

  return (
    <div className="">
      {importResult?.type === SUCCESS_IMPORT && (
        <div className={s.header}>
          <CheckedGreenIcon />
          <div className={s.title} style={{ width: `${width}px` }}>
            Импорт завершен
          </div>
        </div>
      )}
      {importResult?.type === UNSUCCESS_IMPORT && (
        <div className={s.header}>
          <WarningDangerIcon />
          <div className={s.title} style={{ width: `${width}px` }}>
            Ошибка при загрузке
          </div>
        </div>
      )}
      <div className={s.text}>{importResult?.message}</div>
      <Button
        title="OК"
        onClick={() => {
          closeModal();
          dispatch(setImportResult({}));
        }}
      />
    </div>
  );
};
