// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SuccessModal_messageBlock__a38YC {
    display: flex;
}
.SuccessModal_text__KUjhY {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    margin-right: 50px;
}
.SuccessModal_logo__zNswG {
    width: 40px;
}
.SuccessModal_button__avmi9 {
     margin-top: 20px;
 }

@media (max-width: 1000px) {
    .SuccessModal_text__KUjhY {
        font-size: 18px;
        line-height: 25px;
    }
}`, "",{"version":3,"sources":["webpack://./src/App/features/organisms/Modals/SuccessModal/SuccessModal.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,WAAW;AACf;AACA;KACK,gBAAgB;CACpB;;AAED;IACI;QACI,eAAe;QACf,iBAAiB;IACrB;AACJ","sourcesContent":[".messageBlock {\n    display: flex;\n}\n.text {\n    font-weight: 700;\n    font-size: 20px;\n    line-height: 26px;\n    margin-right: 50px;\n}\n.logo {\n    width: 40px;\n}\n.button {\n     margin-top: 20px;\n }\n\n@media (max-width: 1000px) {\n    .text {\n        font-size: 18px;\n        line-height: 25px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageBlock": `SuccessModal_messageBlock__a38YC`,
	"text": `SuccessModal_text__KUjhY`,
	"logo": `SuccessModal_logo__zNswG`,
	"button": `SuccessModal_button__avmi9`
};
export default ___CSS_LOADER_EXPORT___;
