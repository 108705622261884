import { createSlice } from '@reduxjs/toolkit';

export const messageToUserSlice = createSlice({
  name: 'messageToUser',
  initialState: {
    errorMessage: { message: '', type: '' },
    headerTitle: '',
    operationResultMessage: '',
  },
  reducers: {
    setErrMessage: (state, data) => {
      state.errorMessage = data.payload;
    },
    setHeaderTitle: (state, data) => {
      state.headerTitle = data.payload;
    },
    setOperationResultMessage: (state, data) => {
      state.operationResultMessage = data.payload;
    },
  },
});

export const { setErrMessage, setHeaderTitle, setOperationResultMessage } =
  messageToUserSlice.actions;

export default messageToUserSlice.reducer;

export const setErrorMessage = (error) => (dispatch) => {
  dispatch(setErrMessage(error));
  setTimeout(() => {
    dispatch(setErrMessage(''));
  }, 3000);
};

export const setTitleHeader = (title) => (dispatch) => {
  dispatch(setHeaderTitle(title));
};
