import React from 'react';
import { useSelector } from 'react-redux';
import { LinkArrow } from '../../../../../../assets/Icons/linkArrow';
import { getApplicationSettingsState } from '../../../../../../redux/selectors/constructor';
import { APPLICATION_SET_UP } from '../../../../../constants/constructor';
import styles from './howToSetUpApplication.module.css';
import { ModalCheck, ModalCheckMobile } from '../../../../../../assets/Icons/modalCheck';

const HowToSetUpApplication = ({ closeModal }) => {
  const allowedSettings = useSelector(getApplicationSettingsState);

  return (
    <div className={styles.modalContainer}>
      <h2 className={styles.modalTitle}>Как настроить ваше приложение?</h2>
      <p className={styles.modalSubTitle}>
        Для полноценной работы вам необходимо произвести следующие настройки:
      </p>
      <ul className={styles.modalList}>
        {APPLICATION_SET_UP.map((current, index) => {
          return (
            <li key={current.title} className={styles.modalListItem}>
              <div className={styles.modalListItemInner}>
                <div className={styles.innerHeader}>
                  <span className={styles.innerNumber}>
                    {Object.keys(allowedSettings).length && allowedSettings[current.id] ? (
                      <>
                        <div className={styles.checkIconDesktop}>
                          <ModalCheck />
                        </div>
                        <div className={styles.checkIconMobile}>
                          <ModalCheckMobile />
                        </div>
                      </>
                    ) : (
                      index + 1
                    )}
                  </span>
                  <p className={styles.innerText}>{current.title}</p>
                </div>
                <a
                  href={current.href}
                  target="_blank"
                  className={styles.innerLink}
                  rel="noreferrer"
                >
                  Перейти{' '}
                  <span>
                    <LinkArrow />
                  </span>
                </a>
              </div>
            </li>
          );
        })}
      </ul>
      <button onClick={closeModal} type="button" className={styles.modalButton}>
        Понятно!
      </button>
    </div>
  );
};

export default React.memo(HowToSetUpApplication);
