import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getCurrentBonusSelector } from '../../../../../redux/selectors/bonuses';
import s from './bonus.module.css';
import { LinkIcon } from '../../../../../assets/Icons/linkIcon';
import MobileModalHeader from '../../../atoms/MobileModalHeader';

const Bonus = ({ closeModal }) => {
  const currentBonus = useSelector(getCurrentBonusSelector);

  return (
    <div>
      <div className={s.header}>
        <MobileModalHeader onClose={closeModal} />
      </div>
      {currentBonus.image && (
        <div className={`imgWrapper ${s.banner}`}>
          <img
            src={`${process.env.REACT_APP_TENQ_API}${currentBonus.image}`}
            className="imgOfWrapper"
            alt=""
          />
        </div>
      )}
      <div className={s.name}>{currentBonus.name}</div>
      <div className={s.descr}>{currentBonus.descr}</div>
      <div className={s.linkTitle}>Реферальная ссылка</div>
      <div className={s.link}>
        <div>
          {currentBonus.group_link_vk ||
            currentBonus.group_link_insta ||
            currentBonus.group_link_fb}
        </div>
        <a
          href={
            currentBonus.group_link_vk ||
            currentBonus.group_link_insta ||
            currentBonus.group_link_fb
          }
          target="_blank"
          rel="noreferrer"
        >
          <LinkIcon />
        </a>
      </div>
    </div>
  );
};

Bonus.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default memo(Bonus);
