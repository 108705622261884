import React from 'react';

export const LinkArrow = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.7431 1.75739C11.7431 1.20511 11.2954 0.757394 10.7431 0.757395L1.74309 0.757395C1.19081 0.757395 0.743094 1.20511 0.743094 1.75739C0.743094 2.30968 1.19081 2.75739 1.74309 2.75739L9.74309 2.75739L9.74309 10.7574C9.74309 11.3097 10.1908 11.7574 10.7431 11.7574C11.2954 11.7574 11.7431 11.3097 11.7431 10.7574L11.7431 1.75739ZM2.96492 10.9498L11.4502 2.4645L10.036 1.05029L1.55071 9.53557L2.96492 10.9498Z"
        fill="#1E293B"
      />
    </svg>
  );
};
