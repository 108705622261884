import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import store from '../store';
import { addAlert } from './alerts';

const getAuthHeaders = () => {
  const { session } = store.getState();
  return {
    headers: {
      Authorization: `Bearer ${session.token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
};
export const tablesSlice = createSlice({
  name: 'tables',
  initialState: {
    listLoading: false,
    tables: [],
    steps: [],
    sections: [],
    currentSelectedTable: '',
    sameNameing: false,
  },
  reducers: {
    setTables: (state, action) => {
      return {
        ...state,
        tables: action.payload,
      };
    },
    setSteps: (state, action) => {
      return {
        ...state,
        steps: action.payload,
      };
    },
    setSections: (state, action) => {
      return {
        ...state,
        sections: [action.payload],
      };
    },
    setCurrentSelectedTable: (state, action) => {
      return {
        ...state,
        currentSelectedTable: action.payload,
      };
    },
    setListLoading: (state, action) => {
      return {
        ...state,
        listLoading: action.payload,
      };
    },
    setSameNaming: (state, action) => {
      return {
        ...state,
        sameNameing: action.payload,
      };
    },
  },
});

const updateData = (resp, dispacth) => {
  const currentUnsorted = resp.data.data.find((curr) => !curr.id);
  const currentSortedSections = resp.data.data.filter((curr) => !!curr.id);
  currentSortedSections.length && dispacth(setSections(currentSortedSections));
  const currentStepPassed = currentSortedSections.length ? 3 : 1;
  if (currentUnsorted || currentSortedSections.length) {
    dispacth(setSteps(Array.from({ length: currentStepPassed }, (_, i) => i + 1)));
  }
  dispacth(setTables(currentUnsorted.desks));
};

export const importTablesFromRkeeper = () => (dispatch) => {
  dispatch(setListLoading(true));
  axios
    .get(`${process.env.REACT_APP_TENQ_API}/api/v0/rkeeper/importTables`, getAuthHeaders())
    .then((res) => {
      if (res.data.data) {
        dispatch(getTablesRequest());
      }
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      dispatch(setListLoading(false));
    });
};

export const getTablesRequest = () => (dispacth) => {
  dispacth(setListLoading(true));
  axios
    .get(`${process.env.REACT_APP_TENQ_API}/api/v0/settings/desks`, getAuthHeaders())
    .then((resp) => {
      const currentUnsorted = resp.data.data.find((curr) => !curr.id);
      const currentSortedSections = resp.data.data.filter((curr) => !!curr.id);
      currentSortedSections.length && dispacth(setSections(currentSortedSections));
      const currentStepPassed = currentSortedSections.length ? 3 : 1;
      if (currentUnsorted || currentSortedSections.length) {
        dispacth(setSteps(Array.from({ length: currentStepPassed }, (_, i) => i + 1)));
      }
      dispacth(setTables(currentUnsorted.desks));
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispacth(setListLoading(false));
    });
};

export const addUnsortedTables = (current, closeModal) => (dispacth) => {
  dispacth(setListLoading(true));
  closeModal();

  axios
    .post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/settings/desks`,
      [{ name: '', desks_qty: current }],
      getAuthHeaders()
    )
    .then((resp) => {
      updateData(resp, dispacth);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispacth(setListLoading(false));
    });
};
export const deleteCurrentTable = (currentTableId, recalculating, closeModal) => (dispatch) => {
  dispatch(setListLoading(true));
  closeModal();
  axios
    .delete(
      `${
        process.env.REACT_APP_TENQ_API
      }/api/v0/settings/desks/unit/${currentTableId}/delete?recalculating=${Number(recalculating)}`,
      getAuthHeaders()
    )
    .then((resp) => {
      updateData(resp, dispatch);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(setListLoading(false));
    });
};
export const addSectionRequest = (name, tables, closeModal) => (dispatch) => {
  dispatch(setListLoading(true));
  axios
    .post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/settings/desks`,
      [{ name, desks_qty: 0, desk_units: tables }],
      getAuthHeaders()
    )
    .then((resp) => {
      updateData(resp, dispatch);
      dispatch(setSameNaming(false));
      closeModal();
    })
    .catch((err) => {
      if (err.response.status) {
        dispatch(setSameNaming(true));
      }
    })
    .finally(() => {
      dispatch(setListLoading(false));
    });
};
export const updateSectionRequest = (name, sectionId, tables, closeModal) => (dispatch) => {
  dispatch(setListLoading(true));
  axios
    .put(
      `${process.env.REACT_APP_TENQ_API}/api/v0/settings/desks/${sectionId}/update`,
      { name, desks_qty: 0, desk_units: tables },
      getAuthHeaders()
    )
    .then((resp) => {
      if (resp) {
        dispatch(addAlert('Изменения сохранены', 'success'));
      }
      closeModal();
      updateData(resp, dispatch);
    })
    .catch((err) => {
      dispatch(
        addAlert(
          err?.response?.data?.errors?.name
            ? 'Такое название секции уже существует'
            : 'Возникла ошибка',
          'error'
        )
      );
      console.log(err);
    })
    .finally(() => {
      dispatch(setListLoading(false));
    });
};
export const deleteCurrentSectionRequest = (currentSectionId, newSections) => (dispatch) => {
  dispatch(setListLoading(true));
  axios
    .delete(
      `${process.env.REACT_APP_TENQ_API}/api/v0/settings/desks/${currentSectionId}/destroy`,
      getAuthHeaders()
    )
    .then(() => {
      dispatch(getTablesRequest());
      dispatch(setSections(newSections));
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(setListLoading(false));
    });
};
export const addCurrentTableToSectionRequest = (tableId, droppableId) => (dispatch) => {
  axios
    .put(
      `${process.env.REACT_APP_TENQ_API}/api/v0/settings/desks/attach/${tableId}/${droppableId}`,
      {},
      getAuthHeaders()
    )
    .then((resp) => {
      updateData(resp, dispatch);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const {
  setTables,
  setSteps,
  setSections,
  setCurrentSelectedTable,
  setListLoading,
  setSameNaming,
} = tablesSlice.actions;

export default tablesSlice.reducer;
