import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  changeAdminWaitingTime,
  changeBillSettings,
  changeButtonsActivity,
  changeMenu,
  changeSelectedLoyality,
  changeWeiterWaitingTime,
  changeWiFi,
  chnageAllowedFeedBacks,
  chnageTipGettingMethod,
  editVisitorCanApplyDiscount,
} from '../../../../../redux/actions/constructor';
import CheckBox from '../../../atoms/CheckBox';
import CustomSelect from '../../../atoms/CustomSelect';
import styles from './ConstructoButtonWidgets.module.css';
import { SvgRedirectIcon } from '../../../../../assets/Icons/SvgRedirectIcon';
import { getOrganisationInfoSelector } from '../../../../../redux/selectors/organisation';
import {
  getButtonData,
  getConstructorMaindata,
  getCurrentMode,
} from '../../../../../redux/selectors/constructor';
import RadioPickerWidget from './RadioPickerWidget';
import TimePickerWidget from './TimePickerWidget';
import InfoToolTip from '../../../atoms/InfoToolTip';
import { addAlert } from '../../../../../redux/actions/alerts';

export const CallWaiter = ({
  isSaveBtn,
  currentBtn,
  setEditingBtnId,
  customAction,
  position,
  item,
}) => {
  const buttonsData = useSelector(getButtonData);
  const dispatch = useDispatch();
  const [seconds, setSeconds] = useState(0);
  const [minute, setMinute] = useState(1);

  const onSetMinutes = (value) => {
    setMinute(value);
    setEditingBtnId(item.attribute);
  };

  const onSetSeconds = (value) => {
    setSeconds(value);
    setEditingBtnId(item.attribute);
  };

  useEffect(() => {
    if (isSaveBtn && currentBtn === item.attribute) {
      const currTimeInSeconds = minute * 60 + seconds;
      if (customAction) {
        customAction(currTimeInSeconds);
      } else {
        dispatch(changeWeiterWaitingTime(currTimeInSeconds));
      }
    }
  }, [isSaveBtn]);

  useEffect(() => {
    if (buttonsData.waiter_waiting_time > 0 && !item?.custom_button) {
      const second = buttonsData.waiter_waiting_time % 60;
      setMinute((buttonsData.waiter_waiting_time - second) / 60);
      setSeconds(second);
    }
  }, [buttonsData]);

  useEffect(() => {
    if (item && item?.custom_button && item?.custom_button_time > 0) {
      const second = item?.custom_button_time % 60;
      setMinute((item?.custom_button_time - second) / 60);
      setSeconds(second);
    }
  }, [item]);

  return (
    <TimePickerWidget
      seconds={seconds}
      minutes={minute}
      setMinute={onSetMinutes}
      setSeconds={onSetSeconds}
      title={`Укажите время, за которое ${position || 'официант'} подойдет к клиенту`}
    />
  );
};
export const PayTip = ({ isSaveBtn, currentBtn, setEditingBtnId, item }) => {
  const dispatch = useDispatch();
  const mainData = useSelector(getConstructorMaindata);

  const [active, setActive] = useState(mainData.on_waiter_account);

  const handleClick = (current) => {
    if (current.value !== active) {
      setActive(current.value);
      setEditingBtnId(item.attribute);
    }
  };

  const pickerItems = [
    { label: 'Заведению', value: 'заведение' },
    { label: 'Сотруднику', value: 'сотрудник' },
  ];

  useEffect(() => {
    setActive(!mainData.on_waiter_account ? 'заведение' : 'сотрудник');
  }, [mainData]);

  useEffect(() => {
    if (isSaveBtn && currentBtn === item.attribute) {
      const enabled = active === 'сотрудник';
      dispatch(chnageTipGettingMethod(enabled));
    }
  }, [isSaveBtn]);
  return (
    <RadioPickerWidget
      items={pickerItems}
      setActive={handleClick}
      active={active}
      title="Выберите, куда будут отправляться чаевые от клиента"
    />
  );
};

export const CallAdministrator = ({ isSaveBtn, currentBtn, setEditingBtnId, item }) => {
  const buttonsData = useSelector(getButtonData);
  const [seconds, setSeconds] = useState(0);
  const [minute, setMinute] = useState(1);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isSaveBtn && currentBtn === item.attribute) {
      const currTimeInSeconds = minute * 60 + seconds;
      dispatch(changeAdminWaitingTime(currTimeInSeconds));
    }
  }, [isSaveBtn]);
  useEffect(() => {
    if (buttonsData.admin_waiting_time > 0) {
      const second = buttonsData.admin_waiting_time % 60;
      setMinute((buttonsData.admin_waiting_time - second) / 60);
      setSeconds(second);
    }
  }, [buttonsData]);

  return (
    <TimePickerWidget
      setMinute={(value) => {
        setMinute(value);
        setEditingBtnId(item.attribute);
      }}
      setSeconds={(value) => {
        setSeconds(value);
        setEditingBtnId(item.attribute);
      }}
      seconds={seconds}
      minutes={minute}
      title="Укажите время, за которое администратор подойдет к клиенту"
    />
  );
};

export const MenuHendling = ({ editingBtnId, isSaveBtn, currentBtn, setEditingBtnId, item }) => {
  const dispatch = useDispatch();
  const mainData = useSelector(getConstructorMaindata);
  const { settings } = useSelector(getOrganisationInfoSelector);

  const [active, setActive] = useState(mainData.use_app_link);
  const menuPickerItems = [
    {
      label: 'Файл меню заведения',
      value: false,
    },
    {
      label: 'Онлайн меню',
      value: true,
    },
  ];
  const handleClick = (el) => {
    if (!el.value && !settings.menu_file?.name) {
      dispatch(addAlert('Загрузите файл меню заведения в разделе Меню', 'error'));
      return;
    }
    setEditingBtnId(item.attribute);
    setActive(el.value);
  };

  useEffect(() => {
    if (isSaveBtn && currentBtn === editingBtnId) {
      dispatch(changeMenu(active));
    }
  }, [isSaveBtn, editingBtnId]);

  useEffect(() => {
    setActive(mainData.use_app_link);
  }, [mainData]);

  return (
    <RadioPickerWidget
      items={menuPickerItems}
      setActive={handleClick}
      active={active}
      title="Выберите вариант меню"
    />
  );
};

export const Responses = ({ isSaveBtn, currentBtn, setEditingBtnId, item, handleOpen }) => {
  const dispatch = useDispatch();
  const currentMode = useSelector(getCurrentMode);
  const buttonsData = useSelector(getButtonData);
  const [cheks, setCheks] = useState(buttonsData.feedback);
  const establishment = cheks.organisation_feedback_is_allowed;
  const employees = cheks.employee_feedback_is_allowed;
  const yamaps = cheks.yamaps_feedback_is_allowed;
  useEffect(() => {
    setCheks(buttonsData.feedback);
  }, []);

  const handleCheck = (property) => {
    if (property === 'organisation_feedback_is_allowed') {
      setCheks((prev) => {
        return {
          ...prev,
          organisation_feedback_is_allowed: !cheks[property],
          yamaps_feedback_is_allowed: false,
        };
      });
    } else {
      setCheks((prev) => {
        return {
          ...prev,
          [property]: !cheks[property],
          organisation_feedback_is_allowed:
            property === 'yamaps_feedback_is_allowed' || prev.organisation_feedback_is_allowed,
        };
      });
    }
    setEditingBtnId(item.attribute);
  };
  useEffect(() => {
    if (isSaveBtn && currentBtn === item.attribute) {
      const properties = [];
      if (
        buttonsData.feedback.organisation_feedback_is_allowed !==
        cheks.organisation_feedback_is_allowed
      ) {
        properties.push({
          apiPart: 'organisationFeedbackIsAllowed',
          property: 'organisation_feedback_is_allowed',
          value: cheks.organisation_feedback_is_allowed,
        });
      }
      if (buttonsData.feedback.dishes_feedback_is_allowed !== cheks.dishes_feedback_is_allowed) {
        properties.push({
          apiPart: 'dishesFeedbackIsAllowed',
          property: 'dishes_feedback_is_allowed',
          value: cheks.dishes_feedback_is_allowed,
        });
      }
      if (
        buttonsData.feedback.employee_feedback_is_allowed !== cheks.employee_feedback_is_allowed
      ) {
        properties.push({
          apiPart: 'employeeFeedbackIsAllowed',
          property: 'employee_feedback_is_allowed',
          value: cheks.employee_feedback_is_allowed,
        });
      }
      if (buttonsData.feedback.yamaps_feedback_is_allowed !== cheks.yamaps_feedback_is_allowed) {
        properties.push({
          apiPart: 'yamapsFeedbackIsAllowed',
          property: 'yamaps_feedback_is_allowed',
          value: cheks.yamaps_feedback_is_allowed,
        });
      }
      dispatch(chnageAllowedFeedBacks(properties, currentMode));
    }
  }, [isSaveBtn]);
  useEffect(() => {
    if (isSaveBtn && !establishment && !employees && !yamaps) {
      handleOpen();
      dispatch(changeButtonsActivity(item.attribute, false, currentMode));
    }
  }, [establishment, employees, isSaveBtn]);
  return (
    <div className={`grayContainer ${styles.subIContent}`} onClick={(e) => e.stopPropagation()}>
      <div className="d-flex">
        <h2 className="plainGrayText">Выберите, кому клиент может оставлять отзывы</h2>
        <InfoToolTip text="Укажите в адресе заведения место на карте и выберите конкретное заведение, чтобы клиенты могли оставлять отзывы в Яндекс Картах" />
      </div>
      <div className={styles.reviewRow}>
        <div
          className={styles.checkItem}
          onClick={() => handleCheck('organisation_feedback_is_allowed')}
        >
          <CheckBox title="Заведению" value={establishment} border={false} />
        </div>
        <div className={styles.checkItem} onClick={() => handleCheck('yamaps_feedback_is_allowed')}>
          <CheckBox title="Заведению в Яндекс Картах" value={yamaps} border={false} />
        </div>
        <div
          className={styles.checkItem}
          onClick={() => handleCheck('employee_feedback_is_allowed')}
        >
          <CheckBox title="Сотрудникам" value={employees} border={false} />
        </div>
      </div>
    </div>
  );
};

export const PayBill = ({ isSaveBtn, currentBtn, setEditingBtnId, handleOpen, item }) => {
  const dispatch = useDispatch();
  const currentMode = useSelector(getCurrentMode);
  const mainData = useSelector(getConstructorMaindata);
  const [billSettings, setBillSettings] = useState({
    orderPayment: !!mainData.is_allowed_order_payment,
    divideByParts: !!mainData.is_allowed_divide_order_into_parts,
    divideByDishes: !!mainData.is_allowed_divide_order_into_positions,
  });
  const onToggle = (key) => {
    if (key === 'orderPayment') {
      setBillSettings((prev) => ({
        orderPayment: !prev[key],
        divideByParts: false,
        divideByDishes: false,
      }));
    } else {
      setBillSettings((prev) => ({ ...prev, [key]: !prev[key], orderPayment: true }));
    }

    setEditingBtnId(item.attribute);
  };

  useEffect(() => {
    if (isSaveBtn && currentBtn === item.attribute) {
      handleOpen();
      dispatch(
        changeBillSettings(currentMode, {
          is_allowed_order_payment: billSettings.orderPayment,
          is_allowed_divide_order_into_parts: billSettings.divideByParts,
          is_allowed_divide_order_into_positions: billSettings.divideByDishes,
        })
      );
      if (
        !billSettings.orderPayment &&
        !billSettings.divideByDishes &&
        !billSettings.divideByParts
      ) {
        dispatch(changeButtonsActivity(item.attribute, false, currentMode));
      }
    }
  }, [isSaveBtn, currentBtn]);

  return (
    <div
      className={`grayContainer ${styles.payBillContent} ${styles.subIContent}`}
      onClick={(e) => e.stopPropagation()}
    >
      <h2 className="plainGrayText">Укажите функционал, доступный клиенту при оплате счета</h2>
      <div
        onClick={() => onToggle('orderPayment')}
        className={`${styles.checkItem} whiteContainer`}
      >
        <CheckBox title="Оплата счета" border={false} value={billSettings.orderPayment} />
      </div>

      {currentMode === 'in' && (
        <>
          <div
            onClick={() => onToggle('divideByParts')}
            className={`${styles.checkItem} whiteContainer`}
          >
            <CheckBox
              title="Деление заказа на части"
              border={false}
              value={billSettings.divideByParts}
            />
          </div>

          <div
            onClick={() => onToggle('divideByDishes')}
            className={`${styles.checkItem} whiteContainer`}
          >
            <CheckBox
              title="Деление заказа на позиции"
              border={false}
              value={billSettings.divideByDishes}
            />
          </div>
        </>
      )}
    </div>
  );
};

export const Loyality = ({ isSaveBtn, currentBtn, item, setEditingBtnId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isVisitorCanApplyDiscount = useSelector(
    (state) => state?.organisation?.data?.info?.is_visitor_can_apply_discount
  );
  const buttonsData = useSelector(getButtonData);
  const [selected, setSelected] = useState({});
  const [canApplyDiscount, setCanApplyDiscount] = useState(isVisitorCanApplyDiscount);
  const handleChnage = (id, name) => {
    setEditingBtnId(selected?.id === id ? '' : 'is_enabled_btn_get_loyalty_card');
    setSelected({
      id,
      name,
    });
  };
  useEffect(() => {
    if (buttonsData.loyal_card_selected) {
      setSelected(buttonsData.loyal_card_selected);
    }
  }, [buttonsData]);

  useEffect(() => {
    if (isSaveBtn && currentBtn === item.attribute) {
      if (selected?.id && selected?.id !== buttonsData?.loyal_card_selected?.id) {
        dispatch(changeSelectedLoyality(selected.id));
      }
      const body = {
        is_visitor_can_apply_discount: canApplyDiscount,
      };
      dispatch(editVisitorCanApplyDiscount(body));
    }
  }, [isSaveBtn]);

  const handleCheck = () => {
    setEditingBtnId('is_enabled_btn_get_loyalty_card');
    setCanApplyDiscount(!canApplyDiscount);
  };

  return (
    <div className={`grayContainer ${styles.subIContent}`} onClick={(e) => e.stopPropagation()}>
      {(buttonsData?.loyalityAll?.length > 0 && (
        <div className={styles.actionBox}>
          <div className={styles.actionBoxLeft}>
            <label className={`plainGrayText ${styles.actionLabel}`}>
              Выберите карту, которую будут использовать клиенты
            </label>
            <CustomSelect
              handleChange={handleChnage}
              selected={selected.name}
              values={buttonsData.loyalityAll}
            />
          </div>
          <div className={styles.actionBoxLeft}>
            <label className="plainGrayText">
              Включите возможность самостоятельного применения клиентами скидки по карте
            </label>
            <div
              className={`${styles.checkItem} ${styles.discountCheckItem}`}
              onClick={() => handleCheck('employee_feedback_is_allowed')}
            >
              <CheckBox title="Клиенты могут вводить PIN" value={canApplyDiscount} border={false} />
            </div>
          </div>
        </div>
      )) ||
        (buttonsData?.loyalityAll?.length === 0 && (
          <div
            className={styles.loyalityEmptyHintBox}
            onClick={() => history.push('/clubcard/forms')}
          >
            <span className="plainDangerText">
              Для включения этой кнопки у клиента, перейдите в раздел и настройте его
            </span>
            <div className={styles.redirectBtn}>
              <SvgRedirectIcon />
              <span className="plainDangerText">Перейти</span>
            </div>
          </div>
        ))}
    </div>
  );
};

export const WiFi = ({ isSaveBtn, currentBtn, setEditingBtnId, item }) => {
  const dispatch = useDispatch();
  const buttonsData = useSelector(getButtonData);
  const [wifiData, setWifiData] = useState({
    login: '',
    pass: '',
  });
  useEffect(() => {
    setWifiData(() => ({
      login: buttonsData.wifi_login || '',
      pass: buttonsData.wifi_password || '',
    }));
  }, [buttonsData]);

  useEffect(() => {
    if (isSaveBtn && currentBtn === item.attribute) {
      dispatch(changeWiFi(wifiData.login, wifiData.pass));
    }
  }, [isSaveBtn]);

  const handleChange = (event) => {
    if (event.target?.value?.length <= 20) {
      setEditingBtnId(item.attribute);
      setWifiData((prev) => {
        return {
          ...prev,
          [event.target.name]: event.target.value,
        };
      });
    }
  };

  return (
    <div className={`grayContainer ${styles.subIContent}`} onClick={(e) => e.stopPropagation()}>
      <div className={`${styles.wifiRow} d-flex`}>
        <div className={`${styles.wifiItem} ${styles.wifiCol}`}>
          <label className="plainGrayText">
            Имя точки доступа
            <input
              name="login"
              onChange={handleChange}
              value={wifiData.login}
              type="text"
              placeholder="Имя точки доступа"
            />
          </label>
        </div>

        <div className={`${styles.wifiItem} ${styles.wifiCol}`}>
          <label className="plainGrayText">
            Пароль
            <input
              name="pass"
              onChange={handleChange}
              value={wifiData.pass}
              type="text"
              placeholder="Пароль"
            />
          </label>
        </div>
      </div>
    </div>
  );
};
