import { createSlice } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { posmaterialApi, qrApi } from '../../api/qrcodes';
import { setIsLoad } from './preloader';
import { setErrorMessage } from './messageToUser';
import { getAuthHeaders } from '../../api/auth';
import { addAlert } from './alerts';

export const qrcodeSlice = createSlice({
  name: 'qrcode',
  initialState: {
    data: {
      qrcodes: [],
      qrCodesForCreator: [],
      employeesForQrCode: [],
    },
    posmaterials: {
      pos: [],
    },
    integrationCode: 'null',
    materialImage: '',
    qrPage: null,
    qrLastPage: 1,
    qrFilterType: 'all',
    isQrLoading: false,
  },
  reducers: {
    setDataQrcodes: (state, data) => {
      state.data.qrcodes = data.payload;
    },
    setQrCodesForCreator: (state, data) => {
      state.data.qrCodesForCreator = data.payload;
    },
    setPosmaterials: (state, data) => {
      state.posmaterials = data.payload;
    },
    setIntegrationCode: (state, data) => {
      state.integrationCode = data.payload;
    },
    setMaterialImage: (state, action) => {
      state.materialImage = action.payload;
    },
    setEmployeesForQrCode: (state, action) => {
      state.data.employeesForQrCode = action.payload;
    },
    setQrPage: (state, action) => {
      state.qrPage = action.payload;
    },
    setQrFilterType: (state, action) => {
      state.qrFilterType = action.payload;
    },
    setQrLastPage: (state, action) => {
      state.qrLastPage = action.payload;
    },
    setIsQrLoading: (state, action) => {
      state.isQrLoading = action.payload;
    },
  },
});

export const {
  setDataQrcodes,
  setQrCodesForCreator,
  setPosmaterials,
  setIntegrationCode,
  setMaterialImage,
  setEmployeesForQrCode,
  setQrFilterType,
  setQrLastPage,
  setQrPage,
  setIsQrLoading,
} = qrcodeSlice.actions;

export default qrcodeSlice.reducer;

export const getQrCodes = (type, isFirst, callback) => async (dispatch, getState) => {
  dispatch(setIsQrLoading(true));
  const { qrcode } = getState();
  try {
    if (qrcode.qrLastPage !== qrcode.qrPage || isFirst) {
      const response = await qrApi.getQrCodes(
        isFirst ? 1 : qrcode.qrPage + 1,
        type || qrcode.qrFilterType
      );
      const newQrData = response.data?.data || [];
      const qrCodesList = isFirst ? newQrData : [...qrcode.data.qrcodes, ...newQrData];
      callback && callback(qrCodesList);
      dispatch(setDataQrcodes(qrCodesList));
      dispatch(setQrLastPage(response.data?.last_page));
      dispatch(setQrPage(isFirst ? 1 : qrcode.qrPage + 1));
    }
  } catch (err) {
    dispatch(addAlert('Возникла ошибка'));
    console.log(err);
  } finally {
    dispatch(setIsQrLoading(false));
  }
};

export const getEmployeesForQrCode = () => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    await qrApi
      .getEmployeesForQrCode()
      .then((res) => {
        dispatch(setEmployeesForQrCode(res.data.data));
        dispatch(setIsLoad(false));
      })
      .catch((err) => new Error(err));
  } catch (err) {
    dispatch(setIsLoad(false));
    console.error(err);
    console.error(err.response);
  }
};

export const getQrCodesForCreator = () => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  const { profile } = getState();
  try {
    if (profile.data.organisation) {
      const response = await qrApi.getQrCodes();
      dispatch(setQrCodesForCreator(response.data.data.qrcodes));
    }
    dispatch(setIsLoad(false));
  } catch (err) {
    dispatch(setIsLoad(false));
    console.log(err);
    console.log(err.response);
  }
};

export const saveQR = (formData, qrType, pageType, qrFilterType) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const res = await qrApi.saveQR(formData);
    console.log(qrFilterType, qrType);
    if (
      qrType === 'all' ||
      (qrFilterType === 'desk' && qrType === 'desks') ||
      (qrFilterType === 'user' && qrType === 'organisation_users')
    ) {
      dispatch(getQrCodes(qrType, true));
    }
    if (pageType !== 'register') {
      dispatch(addAlert('QR-код создан', 'success'));
    }
    return res;
  } catch (err) {
    dispatch(
      addAlert(
        err?.response?.data?.errors?.name ||
          err?.response?.data?.errors ||
          'При отправке данных произошла ошибка',
        'error'
      )
    );
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const editQr = (formData, qrType) => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  try {
    const res = await qrApi.editQr(formData);
    const { qrcode } = getState();

    if (qrType) {
      dispatch(
        setDataQrcodes(
          qrcode.data.qrcodes.map((qr) => (qr.id === res?.data?.data?.id ? res.data.data : qr))
        )
      );
      dispatch(setIsLoad(false));
      dispatch(addAlert('QR-код изменен', 'success'));
      return res;
    }
  } catch (err) {
    dispatch(setIsLoad(false));
    const message = 'При отправке данных произошла ошибка';
    dispatch(setErrorMessage({ message, type: 'createQrCode' }));
    console.log(err);
    console.log(err.response);
  }
};

export const deleteQr = (id) => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  try {
    await qrApi.deleteQr(id);

    const { qrcode } = getState();
    dispatch(getQrCodes(qrcode?.qrFilterType, true));
    dispatch(addAlert('QR-код удален', 'success'));
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const getPosmaterials = () => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await posmaterialApi.getPosmaterials();
    dispatch(setPosmaterials(response.data.data));
    dispatch(setIsLoad(false));
  } catch (err) {
    dispatch(setIsLoad(false));
    console.log(err);
    console.log(err.response);
  }
};

export const downloadPosmaterial = (type, qrId, posId, qrName) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await posmaterialApi.downloadPosmaterial(type, qrId, posId);
    const contentType = response.headers['content-type'];
    const file = new Blob([response.data], { type: contentType });
    let name = '';
    if (contentType === 'application/pdf') {
      name = `${qrName}.pdf`;
    } else if (contentType === 'image/png') {
      name = `${qrName}.png`;
    }
    saveAs(file, name);
    dispatch(setIsLoad(false));
  } catch (err) {
    dispatch(setIsLoad(false));
    console.log(err);
    console.log(err.response);
  }
};

export const getCurrentQrCodeForDownloading = (id) => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_TENQ_API}/api/v0/downloadQr?id=${id}`, getAuthHeaders())
    .then((res) => {
      dispatch(setMaterialImage(res.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const orderDesign = (message, close) => (dispatch) => {
  dispatch(setIsLoad(true));
  axios
    .post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/pos/sendCustomDesignOrder`,
      message,
      getAuthHeaders()
    )
    .then(() => {
      dispatch(setIsLoad(false));
      console.log('Message Sent!');
      close();
    })
    .catch((err) => {
      dispatch(setIsLoad(false));
      console.log(err);
      close();
    });
};
