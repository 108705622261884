// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reserveTable_wrapper__MRt36 {
    background-color: #F2F5FA;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
}

.reserveTable_subTitle__mVOcF {
    font-family: 'HelveticaNeueCyr';
    font-weight: 550;
    font-size: 14px;
    line-height: 14px;
    color: #4B5563;
}

.reserveTable_saveButton__nvST8{
    border: none;
    background-color: #8672E8;
    color: #fff;
}

.reserveTable_backButton__e544L{
    border: 2px solid #8672E8;
    color: #8672E8;
    background-color: #fff;
}


.reserveTable_buttonsWrapper__-FHkD{
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    cursor: pointer;
}
.reserveTable_buttonsWrapper__-FHkD .reserveTable_buttonContent__qtJx7 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 10px;
    padding-right: 10px;
}
.reserveTable_buttonsWrapper__-FHkD .reserveTable_buttonContent__qtJx7 button {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    padding: 10px 15px;
    border-radius: 10px;
    font-weight: 550;
}`, "",{"version":3,"sources":["webpack://./src/App/features/organisms/Modals/ReserveTable/reserveTable.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,+BAA+B;IAC/B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,sBAAsB;AAC1B;;;AAGA;IACI,aAAa;IACb,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,aAAa;IACb,cAAc;IACd,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".wrapper {\n    background-color: #F2F5FA;\n    border-radius: 12px;\n    padding: 20px;\n    margin-bottom: 20px;\n}\n\n.subTitle {\n    font-family: 'HelveticaNeueCyr';\n    font-weight: 550;\n    font-size: 14px;\n    line-height: 14px;\n    color: #4B5563;\n}\n\n.saveButton{\n    border: none;\n    background-color: #8672E8;\n    color: #fff;\n}\n\n.backButton{\n    border: 2px solid #8672E8;\n    color: #8672E8;\n    background-color: #fff;\n}\n\n\n.buttonsWrapper{\n    display: flex;\n    flex-wrap: wrap;\n    margin-left: -10px;\n    margin-right: -10px;\n    cursor: pointer;\n}\n.buttonsWrapper .buttonContent {\n    flex: 0 0 50%;\n    max-width: 50%;\n    padding-left: 10px;\n    padding-right: 10px;\n}\n.buttonsWrapper .buttonContent button {\n    width: 100%;\n    height: 45px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 45px;\n    padding: 10px 15px;\n    border-radius: 10px;\n    font-weight: 550;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `reserveTable_wrapper__MRt36`,
	"subTitle": `reserveTable_subTitle__mVOcF`,
	"saveButton": `reserveTable_saveButton__nvST8`,
	"backButton": `reserveTable_backButton__e544L`,
	"buttonsWrapper": `reserveTable_buttonsWrapper__-FHkD`,
	"buttonContent": `reserveTable_buttonContent__qtJx7`
};
export default ___CSS_LOADER_EXPORT___;
