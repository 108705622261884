import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addAlert } from '../../../../../redux/actions/alerts';
import { applyDiscountForClubcard } from '../../../../../redux/actions/clubcard';
import { getGroups, setGroups } from '../../../../../redux/actions/groupsSettings';
import { setModal, setModalType } from '../../../../../redux/actions/modal';
import { getGroupsSelector } from '../../../../../redux/selectors/groupSettings';
import Button from '../../../atoms/Button';
import ButtonCancel from '../../../atoms/ButtonCancel';
import Input from '../../../atoms/Input';
import s from '../AddSumClientGroup/AddSumClientGroup.module.css';

const ClubcardGroupTransferSettings = ({ closeModal }) => {
  const dispatch = useDispatch();
  const groups = useSelector(getGroupsSelector);
  const onSave = () => {
    if (!groups?.length) return null;

    const groupsData = {
      data: groups.map((el) => ({
        id: el.id,
        amount_to_transfer: Number(String(el.amount_to_transfer).replace(/\D/g, '')) * 100 || '0',
        loyal_card_id: el?.loyal_card_id,
      })),
      edited_loyal_card_id: groups[0]?.loyal_card_id,
    };

    if (groups?.length === 1) {
      dispatch(
        applyDiscountForClubcard(groupsData, () => {
          dispatch(setModal(false));
          dispatch(setModalType(''));
          dispatch(addAlert('Изменения сорханены', 'success'));
        })
      );
    } else {
      const validatedGroups = groups.filter((el, idx) => {
        const currentAmount = parseInt(String(el.amount_to_transfer).replace(/\s/g, ''), 10);
        const prevAmount =
          idx > 0
            ? parseInt(String(groups[idx - 1].amount_to_transfer).replace(/\s/g, ''), 10)
            : -Infinity;

        return currentAmount > prevAmount;
      });

      if (validatedGroups?.length === groups?.length) {
        dispatch(
          applyDiscountForClubcard(groupsData, () => {
            dispatch(getGroups(groups[0]?.loyal_card_id));
            dispatch(setModal(false));
            dispatch(setModalType(''));
            dispatch(addAlert('Изменения сорханены', 'success'));
          })
        );
      } else {
        dispatch(addAlert('Сумма последующего шага должна быть больше предыдущего', 'error'));
      }
    }
  };
  return (
    <div>
      <p className={s.description}>
        Укажите необходимую сумму для переноса клиентов в соответствующую группу скидок
      </p>

      {(groups?.length &&
        groups.map((el, idx) => (
          <DiscountItem groups={groups} key={idx} idx={idx} group={el} />
        ))) || <p className={s.noneTitle}>Клиенты отсутствуют</p>}

      <div className={s.btns}>
        <Button title="Сохранить" className={s.btn} onClick={onSave} />
        <ButtonCancel onClick={closeModal} className={s.btn} />
      </div>
    </div>
  );
};

const DiscountItem = ({ groups, group, idx }) => {
  const dispatch = useDispatch();

  const onChangeSum = (value) => {
    const numWithoutSpaces = Number(value.replace(/\D/g, ''));
    const formattedNum = numWithoutSpaces.toLocaleString('ru');
    dispatch(
      setGroups(
        groups.map((el, i) => (i === idx ? { ...el, amount_to_transfer: formattedNum } : el))
      )
    );
  };
  useEffect(() => {
    return () => {
      onChangeSum('');
    };
  }, []);
  return (
    <div className={s.discount}>
      <span className={s.percentDiscount}>{group?.discount}%</span>
      <Input
        disabled={!idx}
        placeholder={idx ? '' : 'Базовая'}
        onChange={(e) => onChangeSum(e.target.value)}
        value={idx ? group.amount_to_transfer : ''}
        isBorder
        onClear={() => onChangeSum('')}
        containerClassName={s.percentInput}
        clearIconTop={12}
      />
    </div>
  );
};

export default React.memo(ClubcardGroupTransferSettings);
