import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '../../../../assets/Icons/infoIcon';
import styles from './InfoToolTip.module.css';

const InfoToolTip = ({ text, className, direction }) => {
  return (
    <div className={`${styles.main} ${className}`} onClick={(e) => e.stopPropagation()}>
      <InfoIcon />
      <div
        className={`${
          (direction === 'left' && styles.tooltipContentRight) || styles.tooltipContent
        }`}
      >
        {text}
      </div>
    </div>
  );
};

InfoToolTip.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

InfoToolTip.defaultProps = {
  text: '',
  className: '',
};

export default React.memo(InfoToolTip);
