/*eslint-disable*/
import {createSlice} from '@reduxjs/toolkit';
import {integrationRKeeperAPI} from '../../api/integrationRKeeper';
import {setIsLoad} from './preloader';
import {getQrFile} from "../../utils/qrCodeCreator";
import { addAlert } from './alerts'

export const integrationRkeeperSlice = createSlice({
    name: 'integrationRkeeper',

    initialState: {
        isSuccessConnection: false,
        importedRestaurants: [],
        isSuccessIntegration: false,
        importedCurrencies: [],
        isSuccessSaveCurrency: false,
        isSuccessSaveCashier: false,
        isSuccessGenerateQrCodes: false,
        isSuccessImportEmployees: false,
        desksWithoutQr: [],
        importedEmployees: [],
        selectedImportedEmployee: {
            name: "",
            email: "",
            phone: "",
            rkeeper_employee_ident: "",
            rkeeper_employee_code: "",
            guid: "",
            position_name: ""
        },
        cashierRkeeperEmployees: []
    },

    reducers: {
        setIsSuccessConnection: (state, data) => {
            state.isSuccessConnection = data.payload;
        },
        setImportedRestaurants: (state, data) => {
            state.importedRestaurants = data.payload;
        },
        setIsSuccessIntegration: (state, data) => {
            state.isSuccessIntegration = data.payload;
        },
        setImportedCurrencies: (state, data) => {
            state.importedCurrencies = data.payload;
        },
        setIsSuccessSaveCurrency: (state, data) => {
            state.isSuccessSaveCurrency = data.payload;
        },
        setIsSuccessSaveCashier: (state, data) => {
            state.isSuccessSaveCashier = data.payload;
        },
        setIsSuccessGenerateQrCodes: (state, data) => {
            state.isSuccessGenerateQrCodes = data.payload;
        },
        setDesksWithoutQrs: (state, data) => {
            state.desksWithoutQr = data.payload;
        },
        setImportedEmployees: (state, data) => {
            state.importedEmployees = data.payload;
        },
        setSelectedImportedEmployee: (state, data) => {
            state.selectedImportedEmployee = data.payload
        },
        setCashierRkeeperEmployees: (state, data)  => {
            state.cashierRkeeperEmployees = data.payload
        },
    },
});

export const {
    setIsSuccessConnection,
    setImportedRestaurants,
    setIsSuccessIntegration,
    setImportedCurrencies,
    setIsSuccessSaveCurrency,
    setIsSuccessSaveCashier,
    setIsSuccessGenerateQrCodes,
    setDesksWithoutQrs,
    setImportedEmployees,
    setSelectedImportedEmployee,
    setCashierRkeeperEmployees
} = integrationRkeeperSlice.actions;

export default integrationRkeeperSlice.reducer;

export const callRkeeperCommand = (command, stopLoadingOnFinish, callback, props) => async (dispatch) => {
    dispatch(setIsLoad(true));
    try {
        await integrationRKeeperAPI.callRkeeperCommand(command, props).then(() => {
            callback && callback()
        })
    } catch {
        dispatch(addAlert('Возникла ошибка'));
        dispatch(setIsLoad(false));
    } finally {
        if(stopLoadingOnFinish) {
            dispatch(setIsLoad(false));
        }
    }
};

export const importStations = () => async (dispatch) => {
    dispatch(setIsLoad(true));
    try {
        await integrationRKeeperAPI.importStations();
    } catch (err) {
        console.log(err);
    }

};

export const onSetImportedRestaurants = () => async (dispatch) => {
    try {
        const res = await integrationRKeeperAPI.getStations();
        if (res?.data?.data) {
            dispatch(setImportedRestaurants(res.data.data));
        }
    } catch (err) {
        console.log(err);
    } finally {
        dispatch(setIsLoad(false));
    }
};

export const onSetTables = () => async (dispatch) => {
    dispatch(setIsLoad(true));
    try {
        await integrationRKeeperAPI.getTables();
    } catch (err) {
        console.log(err);
    }
};

export const getDesksWithoutQr = () => async (dispatch) => {
    try {
        const res = await integrationRKeeperAPI.getDesksWithoutQr();
        if (res?.data?.data) {
            dispatch(setDesksWithoutQrs(res.data.data));
        }
    } catch (err) {
        console.log(err);
    } finally {
        dispatch(setIsLoad(false));
    }
};

export const saveImportedStation = (data, callback) => async (dispatch) => {
    dispatch(setIsLoad(true));
    try {
        await integrationRKeeperAPI.saveCurrentStation(data).then((res) => {
            dispatch(setIsSuccessIntegration(res));
            callback && callback();
            dispatch(addAlert('Импорт заведения и станции выполнен успешно', 'success'));
        });
    } catch (err) {
        console.log(err);
    } finally {
        dispatch(setIsLoad(false));
    }
};

async function generateFormData (desks, iter, orgType) {
    let formData = new FormData();
    formData.append('type_qr_code', 'desk');
    for (let d = 0; d < desks.length; d++) {
        const [qrFile, hash] = await getQrFile(`${process.env.REACT_APP_TENQ_PAYMENT}/`);
        formData.append(`data[${iter}][hash]`, hash);
        formData.append(`data[${iter}][dataId]`, desks[d].id);
        formData.append(`data[${iter}][name]`, `${orgType === 2 ? 'Номер' : 'Стол'} ${desks[d].num}`);
        formData.append(`data[${iter}][file]`, qrFile);
        iter++
    }
    return {formData, iter}
}

async function divideArr (sizeDesks, size, desksArr) {
    const newDesksArr = [];
    for (let j = 0; j <Math.ceil(sizeDesks); j++){
        newDesksArr[j] = desksArr.slice((j * size), (j * size) + size);
    }
    return newDesksArr
}

export const generateQrCodes = (desksArr) => async (dispatch, getState) => {
    dispatch(setIsLoad(true));
    try {
        const orgType = getState()?.organisation?.data?.info?.settings?.org_type;
        let i = 0;
        let res = null
        let size = 10;
        
        let sizeDesks = desksArr.length / size
        let isCheck = desksArr && Array.isArray(desksArr) && desksArr.length < 11
        if (isCheck) {
            const {formData} = await generateFormData(desksArr, i, orgType)
            res = await integrationRKeeperAPI.saveQrMultiple(formData);
        } else {
            const newDesksArr = await divideArr(sizeDesks, size, desksArr)
            for(let k = 0; k < newDesksArr.length;) {
                res = null
                const {formData, iter} = await generateFormData(newDesksArr[k], i, orgType)
                res = await integrationRKeeperAPI.saveQrMultiple(formData);
                if (res){
                    i = iter
                    k++
                }
            }
        }
        if(res) {
            dispatch(getDesksWithoutQr())
            dispatch(setIsSuccessGenerateQrCodes(true));
            dispatch(addAlert('Генерация QR-кодов выполнена успешно', 'success'));
        }
    } catch (err) {
        console.log(err);
    } finally {
        dispatch(setIsLoad(false));
    }
};

export const getOrgIp = (callback) => async (dispatch) => {
    dispatch(setIsLoad(true))
    try {
        await integrationRKeeperAPI.getOrgIp().then(({ data }) => {
            callback && callback(data?.data?.result?.connection_details)
        })
    } catch (error) {
        console.log(err);
    } finally {
        dispatch(setIsLoad(false))
    }
} 


export const updateOrgIp = (ip, port, callback) => async (dispatch) => {
    dispatch(setIsLoad(true))
    try {
        await integrationRKeeperAPI.updateOrgIp(ip,port).then(() => {
            callback && callback()
        })
    } catch (error) {
        console.log(err);
    } finally {
        dispatch(setIsLoad(false))
    }
} 

export const getCurrencies = () => async (dispatch) => {
    dispatch(setIsLoad(true));
    try {
        await integrationRKeeperAPI.getCurrencies();
    } catch (err) {
        console.log(err);
    }
};

export const getDiscounts = (callback) => async (dispatch) => {
    dispatch(setIsLoad(true));
    try {
        await integrationRKeeperAPI.getDiscounts()
        .then(() => {
            callback && callback()
        })
        .catch(err => new Error(err));
    } catch (err) {
        console.log(err);
    } finally {
        dispatch(setIsLoad(true));
    }
};

export const onSetImportedCurrencies = (data) => async (dispatch) => {
    try {
        dispatch(setImportedCurrencies(data));
    } catch (err) {
        console.log(err);
    } finally {
        dispatch(setIsLoad(false));
    }
};


export const saveCurrentCurrency = (cashCurrency, nonCashCurrency, callback) => async (dispatch) => {
    dispatch(setIsLoad(true));
    try {
        const res = await integrationRKeeperAPI.saveCurrentCurrency(cashCurrency, nonCashCurrency);
        if (res) {
            callback && callback()
            dispatch(addAlert('Импорт валюты выполнен успешно', 'success'))
            dispatch(setIsSuccessSaveCurrency(true));
        }
    } catch (err) {
        console.log(err);
    } finally {
        dispatch(setIsLoad(false));
    }
};

export const saveCurrentCashier = (dishId) => async (dispatch) => {
    dispatch(setIsLoad(true));
    try {
        await integrationRKeeperAPI.saveCurrentCashier(dishId).then(() => {
            dispatch(addAlert('Интеграция с R-keeper выполнена успешно', 'success'));
            dispatch(setIsSuccessSaveCashier(true));
        })
    } catch (err) {
        console.log(err);
        dispatch(addAlert('Возникла ошибка', 'error'))

    } finally {
        dispatch(setIsLoad(false));
    }
};

export const importEmployees = () => async (dispatch) => {
    dispatch(setIsLoad(true));
    try {
        await integrationRKeeperAPI.importEmployees()
    } catch (err) {
        console.log(err);
        dispatch(addAlert('Возникла ошибка', 'error'))

    }
};

export const getEmployeesFromTemp = () => async (dispatch) => {
    dispatch(setIsLoad(true));
    try {
        await integrationRKeeperAPI.getEmployeesFromTemp().then((res) => {
            dispatch(setImportedEmployees(res.data))
        })
    } catch (err) {
        console.log(err);
        dispatch(addAlert('Возникла ошибка', 'error'))
    } finally {
        dispatch(setIsLoad(false))
    }
};

export const saveImportEmployees = (data, callback) => async (dispatch) => {
    dispatch(setIsLoad(true));
    try {
        await integrationRKeeperAPI.saveImportEmployees(data).then(() => {
            callback && callback()
            dispatch(addAlert('Импорт сотрудников выполнен', 'success'))
        })
    } catch (err) {
        dispatch(addAlert(err?.response?.data?.errors || 'Возникла ошибка', 'error'))
    } finally {
        dispatch(setIsLoad(false))
    }
};

export const getCashierRkeeperEmployees = () => async (dispatch) => {
    dispatch(setIsLoad(true));
    try {
        await integrationRKeeperAPI.getCashierRkeeperEmployees().then((res) => {
            dispatch(setCashierRkeeperEmployees(res.data))
        })
    } catch (err) {
        console.log(err);
        dispatch(addAlert('Возникла ошибка', 'error'))
    } finally {
        dispatch(setIsLoad(false))
    }
};
