import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getNotificationUnreadSelector } from '../../../../redux/selectors/notifications';
import { BackArrow } from '../../../../assets/Icons/backArrow';
import { NoticeIcon } from '../../../../assets/Icons/noticeIcon';
import s from './MobileModalHeader.module.css';

const MobileModalHeader = ({ onClose }) => {
  const unreadNotifications = useSelector(getNotificationUnreadSelector);
  return (
    <div className={s.header}>
      <div className={s.headerBack} onClick={onClose}>
        <BackArrow />
      </div>
      <button type="button" aria-label="header-button">
        <NoticeIcon />
        {!!unreadNotifications && <span className={s.noticeCount}>{unreadNotifications}</span>}
      </button>
    </div>
  );
};

MobileModalHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default React.memo(MobileModalHeader);
