import { createSelector } from 'reselect';

const messegeToUserSelector = (state) => state.messageToUser;

export const getМessegeToUsererrorMessageSelector = createSelector(
  messegeToUserSelector,
  (state) => state.errorMessage
);

export const getМessegeToUserheaderTitleSelector = createSelector(
  messegeToUserSelector,
  (state) => state.headerTitle
);

export const getМessegeToUserOperationResultMessageSelector = createSelector(
  messegeToUserSelector,
  (state) => state.operationResultMessage
);
