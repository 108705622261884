// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImportGroupsModal_header__k2B1a {
  display: flex;
  column-gap: 10px;
  justify-content: center;
  align-items: center;
}
.ImportGroupsModal_title__LEBRM {
  width: 400px;
  font-weight: 700;
  font-size: 20px;
  margin-top: 2px;
}
.ImportGroupsModal_text__9ryHY {
  font-weight: 550;
  font-size: 14px;
  line-height: 20px;
  color: #94a3b8;
  margin-bottom: 20px;
}
.ImportGroupsModal_logo__jWLnB {
  position: absolute;
  top: 24px;
  right: 20px;
}
.ImportGroupsModal_button__aoEYp {
  width: 100%;
  height: 46px;
  margin-top: 40px;
  background: linear-gradient(
          90.66deg,
          #6279e6 0.57%,
          #847aea 48%,
          #8867e6 96.43%
  );
  border-radius: 12px;
  border: 1px solid #f0f3f8;
  padding: 16px 14px;
  line-height: 14px;
  color: #fff;
  text-align: center;
}
.ImportGroupsModal_buttonText__XgYbU {
  text-decoration: none;
  transition: all 0.5s;
  position: relative;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  font-weight: 550;
}`, "",{"version":3,"sources":["webpack://./src/App/features/organisms/Modals/ImportGroupsModal/ImportGroupsModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb;AACA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB;;;;;GAKC;EACD,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,qBAAqB;EACrB,oBAAoB;EACpB,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".header {\n  display: flex;\n  column-gap: 10px;\n  justify-content: center;\n  align-items: center;\n}\n.title {\n  width: 400px;\n  font-weight: 700;\n  font-size: 20px;\n  margin-top: 2px;\n}\n.text {\n  font-weight: 550;\n  font-size: 14px;\n  line-height: 20px;\n  color: #94a3b8;\n  margin-bottom: 20px;\n}\n.logo {\n  position: absolute;\n  top: 24px;\n  right: 20px;\n}\n.button {\n  width: 100%;\n  height: 46px;\n  margin-top: 40px;\n  background: linear-gradient(\n          90.66deg,\n          #6279e6 0.57%,\n          #847aea 48%,\n          #8867e6 96.43%\n  );\n  border-radius: 12px;\n  border: 1px solid #f0f3f8;\n  padding: 16px 14px;\n  line-height: 14px;\n  color: #fff;\n  text-align: center;\n}\n.buttonText {\n  text-decoration: none;\n  transition: all 0.5s;\n  position: relative;\n  cursor: pointer;\n  color: #fff;\n  font-size: 14px;\n  font-weight: 550;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `ImportGroupsModal_header__k2B1a`,
	"title": `ImportGroupsModal_title__LEBRM`,
	"text": `ImportGroupsModal_text__9ryHY`,
	"logo": `ImportGroupsModal_logo__jWLnB`,
	"button": `ImportGroupsModal_button__aoEYp`,
	"buttonText": `ImportGroupsModal_buttonText__XgYbU`
};
export default ___CSS_LOADER_EXPORT___;
