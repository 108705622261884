// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.redirectionModals_modalContainer__ye-qS {
    padding: 4px 0;
}

.redirectionModals_modalTitle__hAjFS {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #1E293B;
}

.redirectionModals_modalBody__ZQfYG {
    padding: 18px 20px;
    margin-bottom: 20px;
    font-weight: 550;
    font-size: 14px;
    line-height: 16px;
    color: #94A3B8;
    background: #F2F5FA;
    border-radius: 12px;
}

.redirectionModals_modalButtons__BgqwH {
    display: flex;
    align-items: center;
    margin-left: -10px;
    margin-right: -10px;
}

.redirectionModals_modalButton__NmZuy {
    padding-left: 10px;
    padding-right: 10px;
    flex: 0 0 50%;
    max-width: 50%;
}

.redirectionModals_modalButton__NmZuy button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    height: 45px;
    color: #fff;
    background: #8672E8;
    border-radius: 10px;
    border: 2px solid #8672E8;
    outline: none;
    font-weight: 550;
}

.redirectionModals_modalButton__NmZuy button.redirectionModals_outlinedButton__rsVDi {
    border: 2px solid #8672E8;
    background-color: transparent;
    color: #8672E8;
}`, "",{"version":3,"sources":["webpack://./src/App/features/organisms/Modals/Constructor/RedirectionModals/redirectionModals.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,6BAA6B;IAC7B,cAAc;AAClB","sourcesContent":[".modalContainer {\n    padding: 4px 0;\n}\n\n.modalTitle {\n    margin-bottom: 20px;\n    font-weight: 700;\n    font-size: 20px;\n    line-height: 20px;\n    color: #1E293B;\n}\n\n.modalBody {\n    padding: 18px 20px;\n    margin-bottom: 20px;\n    font-weight: 550;\n    font-size: 14px;\n    line-height: 16px;\n    color: #94A3B8;\n    background: #F2F5FA;\n    border-radius: 12px;\n}\n\n.modalButtons {\n    display: flex;\n    align-items: center;\n    margin-left: -10px;\n    margin-right: -10px;\n}\n\n.modalButton {\n    padding-left: 10px;\n    padding-right: 10px;\n    flex: 0 0 50%;\n    max-width: 50%;\n}\n\n.modalButton button {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 10px 15px;\n    height: 45px;\n    color: #fff;\n    background: #8672E8;\n    border-radius: 10px;\n    border: 2px solid #8672E8;\n    outline: none;\n    font-weight: 550;\n}\n\n.modalButton button.outlinedButton {\n    border: 2px solid #8672E8;\n    background-color: transparent;\n    color: #8672E8;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": `redirectionModals_modalContainer__ye-qS`,
	"modalTitle": `redirectionModals_modalTitle__hAjFS`,
	"modalBody": `redirectionModals_modalBody__ZQfYG`,
	"modalButtons": `redirectionModals_modalButtons__BgqwH`,
	"modalButton": `redirectionModals_modalButton__NmZuy`,
	"outlinedButton": `redirectionModals_outlinedButton__rsVDi`
};
export default ___CSS_LOADER_EXPORT___;
