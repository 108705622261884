// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UnsuccessModal_text__HPwiw {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
}
.UnsuccessModal_button__Z9pur {
    margin-top: 20px;
}

@media (max-width: 1000px) {
    .UnsuccessModal_text__HPwiw {
        font-size: 18px;
        line-height: 25px;
    }
}`, "",{"version":3,"sources":["webpack://./src/App/features/organisms/Modals/UnsuccessModal/UnsuccessModal.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,eAAe;QACf,iBAAiB;IACrB;AACJ","sourcesContent":[".text {\n    font-weight: 700;\n    font-size: 20px;\n    line-height: 26px;\n}\n.button {\n    margin-top: 20px;\n}\n\n@media (max-width: 1000px) {\n    .text {\n        font-size: 18px;\n        line-height: 25px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `UnsuccessModal_text__HPwiw`,
	"button": `UnsuccessModal_button__Z9pur`
};
export default ___CSS_LOADER_EXPORT___;
