import axios from 'axios';
import store from '../redux/store';

const getAuthHeaders = () => {
  const { session } = store.getState();
  return {
    headers: {
      Authorization: `Bearer ${session.token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
};

export const settingAPI = {
  getDesks: () =>
    axios.get(`${process.env.REACT_APP_TENQ_API}/api/v0/settings/desks`, getAuthHeaders()),
  getNewDesks: () =>
    axios.get(`${process.env.REACT_APP_TENQ_API}/api/v0/settings/new/desks`, getAuthHeaders()),
  saveDesks: (desks) =>
    axios.post(`${process.env.REACT_APP_TENQ_API}/api/v0/settings/desks`, desks, getAuthHeaders()),
  selectTakeAwayDesk: (desk) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/settings/desks/updateIsTakeaway`,
      desk,
      getAuthHeaders()
    ),
  editSection: (id, name, desks_qty) =>
    axios.put(
      `${process.env.REACT_APP_TENQ_API}/api/v0/settings/desks/${id}/update?desks_qty=${desks_qty}&name=${name}`,
      {},
      getAuthHeaders()
    ),
  deleteSection: (sectionId) =>
    axios.delete(
      `${process.env.REACT_APP_TENQ_API}/api/v0/settings/desks/${sectionId}/destroy`,
      getAuthHeaders()
    ),
  getSubscriptions: () =>
    axios.get(`${process.env.REACT_APP_TENQ_API}/api/v0/settings/subscribe`, getAuthHeaders()),
  sendTypeSubscription: (provider) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/settings/subscribe/${
        provider.provider === 'telegram' && !provider.is_subscribe
          ? provider.provider
          : `provider/${provider.id}/update`
      }`,
      provider.provider === 'telegram' && !provider.is_subscribe
        ? {}
        : { is_subscribe: !provider?.is_subscribe },
      getAuthHeaders()
    ),
  sendEventSubscription: (event, value) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/settings/subscribe/event/${event}/update?value=${value}`,
      {},
      getAuthHeaders()
    ),
  changePassword: (password, password_confirmation) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/main/profile/password?password=${password}&password_confirmation=${password_confirmation}`,
      {},
      getAuthHeaders()
    ),
  verifyEmailCode: (code) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/main/profile/emailVerify`,
      {
        code,
      },
      getAuthHeaders()
    ),
  resendEmailCode: () =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/main/profile/emailVerify/resend`,
      {},
      getAuthHeaders()
    ),
  getEmailCode: () =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/main/profile/emailVerify/sendCode`,
      {},
      getAuthHeaders()
    ),
  getOrganisation: () =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/main/organisation`,
      getAuthHeaders()
    ),
  updateOrganisation: (name, address, coordinates, description, widgetMapOrganisationId) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/main/organisation`,
      coordinates?.length
        ? {
            name,
            address,
            description,
            latitude: coordinates[0].toString(),
            longitude: coordinates[1].toString(),
            yandex_widget_organisation_id: widgetMapOrganisationId,
          }
        : {
            name,
            address,
            description,
          },
      getAuthHeaders()
    ),
  sendImgOrganisation: (img, type) => {
    const { session } = store.getState();
    const formData = new FormData();
    formData.append(type, img);
    const auth = {
      headers: {
        Authorization: `Bearer ${session.token}`,
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    };
    return axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/main/organisation`,
      formData,
      auth
    );
  },
  getTransferSettings: (organisationId) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/organisation/${organisationId}/settings/getTransferSettings`,
      getAuthHeaders()
    ),
  changeTransferSettings: (flag, organisationId) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/organisation/${organisationId}/settings/changeTransferSettings?setting=${flag}`,
      {},
      getAuthHeaders()
    ),
};

export const menuAPI = {
  getMenuVariants: () =>
    axios.get(`${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/menumap`, getAuthHeaders()),
  getMenu: (onlyTakeawayMenu) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/menu/v1/web/menu/get`,
      {
        only_takeaway_menu: onlyTakeawayMenu,
      },
      getAuthHeaders()
    ),
  addMenuVariant: (name) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/menumap/add`,
      {
        name,
      },
      getAuthHeaders()
    ),
  editMenuVariant: (formData, menuMap_id) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/menumap/edit/${menuMap_id}`,
      formData,
      getAuthHeaders()
    ),
  deleteMenuVariant: (id, action) =>
    axios.delete(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/menumap/${id}?action=${action}`,
      getAuthHeaders()
    ),
  moveMenuVariant: (body) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/menusection/updatePositions`,
      body,
      getAuthHeaders()
    ),
  getOnlineMenu: (organisationId, menuId, page) =>
    axios.get(
      `${
        process.env.REACT_APP_TENQ_API
      }/api/v0/auth/organisations/dishes?organisation_id=${organisationId}&visible_all=true&menu_id=${menuId}&page=${
        page || 1
      }`,
      getAuthHeaders()
    ),
  getMenuCategories: (data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/menu/v1/web/sections/get`,
      data,
      getAuthHeaders()
    ),
  getMenuCategoriesDishes: (data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/menu/v1/web/dishes/get`,
      data,
      getAuthHeaders()
    ),
  getOnlineMenuForImport: (menuId) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/menusection/getMenuForImport?menu_id=${menuId}`,
      getAuthHeaders()
    ),
  partlyImportMenuFromRkeeper: (menu_sections, menu_id) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/menusection/importMenu`,
      {
        data: {
          menu_sections,
        },
        menu_id,
      },
      getAuthHeaders()
    ),
  getMenuSections: (menu_id) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/menusection?menu_id=${menu_id}`,
      getAuthHeaders()
    ),
  createMenuSection: (name, menuId) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/menusection/store?name=${name}&menu_id=${menuId}`,
      {},
      getAuthHeaders()
    ),
  editMenuSection: (formdata, chapterId) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/menusection/update/${chapterId}`,
      formdata,
      getAuthHeaders()
    ),
  deleteMenuSection: (menuSectionId, action) =>
    axios.delete(
      `${
        process.env.REACT_APP_TENQ_API
      }/api/v0/owner/settings/menusection/destroy/${menuSectionId}${action || ''}`,
      getAuthHeaders()
    ),
  createDish: (data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/dish/store`,
      data,
      getAuthHeaders()
    ),
  getDishInfo: (menuPositionId) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/dish/edit/${menuPositionId}`,
      getAuthHeaders()
    ),
  updateDish: (menuPositionId, data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/dish/update/${menuPositionId}`,
      data,
      getAuthHeaders()
    ),
  updateMainList: (data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/dish/updatePositions`,
      data,
      getAuthHeaders()
    ),
  updateSectionList: (data, menusectionId) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/menusection/updateList/${menusectionId}`,
      data,
      getAuthHeaders()
    ),
  changeStartListPositionPriority: (menuPositionId, data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/settings/group/menu/updateList/${menuPositionId}`,
      data,
      getAuthHeaders()
    ),
  getStartStopList: (typeMenuGroup, menu_id) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/settings/group/menu/${typeMenuGroup}?menu_id=${menu_id}`,
      getAuthHeaders()
    ),
  addStartStopListPosition: (typeMenuGroup, data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/settings/group/menu/${typeMenuGroup}`,
      data,
      getAuthHeaders()
    ),
  deleteStartStopListPosition: (menu_group_id) =>
    axios.delete(
      `${process.env.REACT_APP_TENQ_API}/api/v0/settings/group/menu/${menu_group_id}`,
      getAuthHeaders()
    ),
  deleteDish: (menuPositionId) =>
    axios.delete(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/dish/destroy/${menuPositionId}`,
      getAuthHeaders()
    ),
  editMenu: (use_app_link_menu, link_menu) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/organisation/update`,
      { use_app_link_menu, link_menu },
      getAuthHeaders()
    ),
  uploadMenuPdf: (data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/organisation/update`,
      data,
      getAuthHeaders()
    ),
  updateMenuView: (data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/organisation/update`,
      data,
      getAuthHeaders()
    ),
  getSearchResults: (value, table) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/dish/modifiers-allergens/${table}?search=${value}`,
      getAuthHeaders()
    ),
  syncRkeeper: () =>
    axios.put(
      `${process.env.REACT_APP_TENQ_API}/api/v0/sync/rkeeper/import_menu_v2`,
      {},
      getAuthHeaders()
    ),
  syncRkeeperDishes: () =>
    axios.put(
      `${process.env.REACT_APP_TENQ_API}/api/v0/sync/rkeeper/import_modifiers`,
      {},
      getAuthHeaders()
    ),
  getTakeAwayMenu: (menuId, organisationId) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/dish/?menu_id=${menuId}&organisation_id=${organisationId}&takeaway`,
      getAuthHeaders()
    ),
  editTakeAwayMenu: (data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/main/organisation`,
      data,
      getAuthHeaders()
    ),
  addNameToOrder: (dishId, data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/dish/update/${dishId}`,
      data,
      getAuthHeaders()
    ),
  updateTakeawayMenu: (data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/dish/updateTakeawayMultiple`,
      { data },
      getAuthHeaders()
    ),
};
