import { VKIcon, VKIconSmall } from '../../assets/Icons/constructor/VKIcon';
import { TGIcon, TGIconSmall } from '../../assets/Icons/constructor/TGIcon';
import { YouTubeIcon, YouTubeIconSmall } from '../../assets/Icons/constructor/YouTubeIcon';
import {
  CallAdministrator,
  CallWaiter,
  Loyality,
  MenuHendling,
  PayBill,
  PayTip,
  Responses,
  WiFi,
} from '../features/molecules/ConstructoTabs/ConstructoButtonWidgets/ConstructoButtonWidgets';

export const SOCIAL_MEDIA = [
  {
    icon: VKIcon,
    smallIcon: VKIconSmall,
    title: 'Вконтакте',
  },
  {
    icon: TGIcon,
    smallIcon: TGIconSmall,
    title: 'Telegram',
  },
  {
    icon: YouTubeIcon,
    smallIcon: YouTubeIconSmall,
    title: 'Youtube',
  },
];

export const APPLICATION_SET_UP = [
  {
    title: 'Добавьте сотрудников',
    href: '/management',
    id: 'addedEmployee',
  },
  {
    title: 'Составьте график работы, проставьте смены',
    href: '/management/shifts',
    id: 'addedShifts',
  },
  {
    title: 'Настройте отображения меню / списка услуг ',
    href: '/settings/menu',
    id: 'addedMenu',
  },
  {
    title: 'Заполните раздел “О компании”',
    href: '/organisation/constructor',
    id: 'addedDescriptionOrganisation',
  },
  {
    title: 'Заполните раздел “Система лояльности”',
    href: '/loyalty',
    id: 'addedLoyalCards',
  },
];

export const CONSTRUCTOR_BTNS_INFO = {
  is_enabled_btn_menu: {
    component: MenuHendling,
    id: 'is_enabled_btn_menu',
    title: 'Меню',
    info: 'Настройте раздел меню, чтобы включить эту кнопку у клиента',
  },
  is_enabled_btn_pay_tips: {
    component: PayTip,
    id: 'is_enabled_btn_pay_tips',
    title: 'Оставить чаевые',
    info: 'Оплата чаевых от клиента',
  },
  is_enabled_btn_pay_bill: {
    component: PayBill,
    id: 'is_enabled_btn_pay_bill',
    title: 'Оплатить счет',
    info: 'Оплата счета от клиента',
  },
  is_enabled_btn_admin_waiting_time: {
    component: CallAdministrator,
    id: 'is_enabled_btn_admin_waiting_time',
    title: 'Администратор - вызов',
    info: 'Укажите время, за которое администратор должен подойти по запросу клиента',
  },
  is_enabled_btn_waiter_waiting_time: {
    component: CallWaiter,
    id: 'is_enabled_btn_waiter_waiting_time',
    title: 'Официант - вызов',
    info: 'Укажите время, за которое сотрудник должен подойти по запросу клиента',
  },
  is_enabled_btn_feedback_allowed: {
    component: Responses,
    id: 'is_enabled_btn_feedback_allowed',
    title: 'Оставить отзыв',
    info: 'Укажите, кому могут оставлять отзывы ваши клиенты',
  },
  is_enabled_btn_get_loyalty_card: {
    component: Loyality,
    id: 'is_enabled_btn_get_loyalty_card',
    title: 'Получить карту лояльности',
    info: 'Выберите созданную карту для использования клиентом системы лояльности',
  },
  is_enabled_btn_wifi: {
    component: WiFi,
    id: 'is_enabled_btn_wifi',
    title: 'Wi-Fi',
    info: 'Укажите логин и пароль от Wi-Fi к точке доступа, чтобы клиенты могли пользоваться интернетом в вашем заведении',
  },
};
