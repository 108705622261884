import React from 'react';

export const SvgRedirectIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1466_17439)">
        <path
          d="M12.8632 9.22763V13.5913C12.8632 13.977 12.7099 14.347 12.4372 14.6198C12.1644 14.8926 11.7944 15.0458 11.4086 15.0458H3.40865C3.02288 15.0458 2.65291 14.8926 2.38013 14.6198C2.10735 14.347 1.9541 13.977 1.9541 13.5913V5.59126C1.9541 5.20549 2.10735 4.83553 2.38013 4.56275C2.65291 4.28997 3.02288 4.13672 3.40865 4.13672H7.77228"
          stroke="#FF6262"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6816 1.9541H15.0453V6.31774"
          stroke="#FF6262"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.0459 9.9541L15.0459 1.9541"
          stroke="#FF6262"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1466_17439">
          <rect width="16" height="16" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
