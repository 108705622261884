// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MessageToUser_container__\\+mw4v {
    max-width: 500px;
    border-radius: 8px;
    padding: 0px 10px 5px 10px;
    background-color: #ffe0e0;
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
}
.MessageToUser_message__hhy6x {
    font-family: "HelveticaNeueCyr";
    font-size: 12px;
    font-style: normal;
    font-weight: 550;
    line-height: 17px;
    color: #FF6464;
}
.MessageToUser_x__TB\\+SS {
    margin-right: 10px;
    cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/App/components/MessageToUser/MessageToUser.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,0BAA0B;IAC1B,yBAAyB;IACzB,eAAe;IACf,SAAS;IACT,SAAS;IACT,2BAA2B;IAC3B,aAAa;AACjB;AACA;IACI,+BAA+B;IAC/B,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".container {\n    max-width: 500px;\n    border-radius: 8px;\n    padding: 0px 10px 5px 10px;\n    background-color: #ffe0e0;\n    position: fixed;\n    top: 10px;\n    left: 50%;\n    transform: translateX(-50%);\n    z-index: 9999;\n}\n.message {\n    font-family: \"HelveticaNeueCyr\";\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 550;\n    line-height: 17px;\n    color: #FF6464;\n}\n.x {\n    margin-right: 10px;\n    cursor: default;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MessageToUser_container__+mw4v`,
	"message": `MessageToUser_message__hhy6x`,
	"x": `MessageToUser_x__TB+SS`
};
export default ___CSS_LOADER_EXPORT___;
