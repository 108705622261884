import React from 'react';

export const VKIcon = () => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.39001 2.39001C0 4.78002 0 8.62665 0 16.32V17.68C0 25.3733 0 29.22 2.39001 31.61C4.78002 34 8.62665 34 16.32 34H17.68C25.3733 34 29.22 34 31.61 31.61C34 29.22 34 25.3733 34 17.68V16.32C34 8.62665 34 4.78002 31.61 2.39001C29.22 0 25.3733 0 17.68 0H16.32C8.62665 0 4.78002 0 2.39001 2.39001ZM5.73758 10.3417C5.92175 19.1817 10.3417 24.4942 18.0909 24.4942H18.5301V19.4367C21.3776 19.7201 23.5308 21.8026 24.395 24.4942H28.4184C27.3134 20.4709 24.4091 18.2467 22.5958 17.3967C24.4091 16.3484 26.9591 13.7984 27.5683 10.3417H23.9132C23.1199 13.1467 20.7685 15.6967 18.5301 15.9376V10.3417H14.875V20.1451C12.6083 19.5784 9.74674 16.8301 9.61924 10.3417H5.73758Z"
        fill="#8672E8"
      />
    </svg>
  );
};

export const VKIconSmall = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.12471 1.12471C0 2.24942 0 4.0596 0 7.68V8.32C0 11.9404 0 13.7506 1.12471 14.8753C2.24942 16 4.0596 16 7.68 16H8.32C11.9404 16 13.7506 16 14.8753 14.8753C16 13.7506 16 11.9404 16 8.32V7.68C16 4.0596 16 2.24942 14.8753 1.12471C13.7506 0 11.9404 0 8.32 0H7.68C4.0596 0 2.24942 0 1.12471 1.12471ZM2.70004 4.8667C2.78671 9.0267 4.8667 11.5267 8.51337 11.5267H8.72007V9.1467C10.0601 9.28003 11.0733 10.26 11.48 11.5267H13.3734C12.8534 9.63337 11.4866 8.5867 10.6333 8.1867C11.4866 7.69337 12.6866 6.49337 12.9733 4.8667H11.2533C10.8799 6.1867 9.7734 7.3867 8.72007 7.50003V4.8667H7V9.48003C5.93333 9.21337 4.5867 7.92003 4.5267 4.8667H2.70004Z"
        fill="#8672E8"
      />
    </svg>
  );
};
