// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bonus_banner__lb\\+hd {
    border-radius: 0px;
    width: 265px;
    height: 265px;
    margin: 35px auto 50px;
}
.bonus_banner__lb\\+hd > img {
    object-fit: contain;
}
.bonus_name__DljvL {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
}
.bonus_descr__3ymML {
    color: #94A3B8;
    font-weight: 550;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
}
.bonus_linkTitle__wsE7g {
    font-weight: 550;
    font-size: 16px;
    margin-bottom: 10px;
}
.bonus_link__K5XY- {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 14px;
    background: #F2F5FA;
    border-radius: 10px;
    font-weight: 550;
    font-size: 14px;
    height: 40px;
}
@media (min-width: 1000px) {
    .bonus_header__-YuL9 {
        display: none;
    }
    .bonus_banner__lb\\+hd {
        width: 367px;
        height: 367px;
        margin: 35px auto 65px;
    }
    .bonus_name__DljvL {
        font-size: 26px;
        line-height: 36px;
    }
    .bonus_descr__3ymML {
        font-size: 16px;
        line-height: 26px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/App/features/organisms/Modals/Bonus/bonus.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;IACf,YAAY;AAChB;AACA;IACI;QACI,aAAa;IACjB;IACA;QACI,YAAY;QACZ,aAAa;QACb,sBAAsB;IAC1B;IACA;QACI,eAAe;QACf,iBAAiB;IACrB;IACA;QACI,eAAe;QACf,iBAAiB;IACrB;AACJ","sourcesContent":[".banner {\n    border-radius: 0px;\n    width: 265px;\n    height: 265px;\n    margin: 35px auto 50px;\n}\n.banner > img {\n    object-fit: contain;\n}\n.name {\n    font-weight: 700;\n    font-size: 20px;\n    line-height: 30px;\n    margin-bottom: 10px;\n}\n.descr {\n    color: #94A3B8;\n    font-weight: 550;\n    font-size: 16px;\n    line-height: 24px;\n    margin-bottom: 20px;\n}\n.linkTitle {\n    font-weight: 550;\n    font-size: 16px;\n    margin-bottom: 10px;\n}\n.link {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 0px 14px;\n    background: #F2F5FA;\n    border-radius: 10px;\n    font-weight: 550;\n    font-size: 14px;\n    height: 40px;\n}\n@media (min-width: 1000px) {\n    .header {\n        display: none;\n    }\n    .banner {\n        width: 367px;\n        height: 367px;\n        margin: 35px auto 65px;\n    }\n    .name {\n        font-size: 26px;\n        line-height: 36px;\n    }\n    .descr {\n        font-size: 16px;\n        line-height: 26px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": `bonus_banner__lb+hd`,
	"name": `bonus_name__DljvL`,
	"descr": `bonus_descr__3ymML`,
	"linkTitle": `bonus_linkTitle__wsE7g`,
	"link": `bonus_link__K5XY-`,
	"header": `bonus_header__-YuL9`
};
export default ___CSS_LOADER_EXPORT___;
