import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addSectionRequest,
  setCurrentSelectedTable,
  updateSectionRequest,
} from '../../../../../redux/actions/tables';
import {
  getCurrentSelectedTable,
  getTableList,
  getSections,
  getSameNaming,
} from '../../../../../redux/selectors/tables';
import TableSectionTableIcon from '../../../atoms/TableSectionTableIcon/TableSectionTableIcon';
import style from './addNewSection.module.css';
import { addAlert } from '../../../../../redux/actions/alerts';
import Input from '../../../atoms/Input';

const AddNewSection = ({ closeModal, currentModal }) => {
  const tables = useSelector(getTableList);
  const sections = useSelector(getSections);
  const sameNaming = useSelector(getSameNaming);
  const currentSelectedTable = useSelector(getCurrentSelectedTable);
  const infoSection = useMemo(() => {
    return sections && sections[0] && sections[0].find((item) => item.id === currentSelectedTable);
  }, [sections]);
  const [currentTables, setCurrentTables] = useState([]);
  const [allTables, setAllTables] = useState([]);
  const dispatch = useDispatch();
  const [creds, setCreds] = useState({
    name: infoSection?.name || '',
    checked: currentModal === 'add-new-section',
    tabeFinalCheckedList: currentTables,
  });

  useEffect(() => {
    setCurrentTables(tables);
    if (infoSection?.desks) setAllTables((prev) => [...prev, ...infoSection.desks]);
  }, []);

  useEffect(() => {
    setAllTables((prev) => [...prev, ...tables]);
  }, [tables]);

  useEffect(() => {
    if (infoSection && infoSection.desks) {
      setCurrentTables(infoSection.desks);
    }
  }, [infoSection]);

  useEffect(() => {
    setCreds({
      ...creds,
      checked: allTables.length === currentTables.length,
      tabeFinalCheckedList: currentTables,
    });
  }, [currentTables]);
  useEffect(() => {
    return () => {
      dispatch(setCurrentSelectedTable(''));
    };
  }, []);
  const handleChange = (event) => {
    if (event?.target?.value?.length <= 20) {
      setCreds({
        ...creds,
        tabeFinalCheckedList: currentTables,
        [event.target.name]: event.target.value,
      });
    }
  };
  const isReadyForSubmit = useMemo(() => {
    const ready = creds.name;
    return ready;
  }, [creds]);

  const addNewTablesForSection = (current) => {
    if (allTables.length === currentTables.length - 1) {
      setCreds((prev) => ({ ...prev, checked: true }));
    } else {
      setCreds((prev) => ({ ...prev, checked: false }));
    }
    if (currentTables.includes(current)) {
      const newTables = currentTables.filter((curr) => curr !== current);
      setCurrentTables(newTables);
    } else {
      setCurrentTables([...currentTables, current]);
    }
  };

  const handleAddAllTables = (event) => {
    if (allTables.length === currentTables.length) {
      setCurrentTables([]);
    } else {
      setCurrentTables(allTables);
    }
    setCreds({
      ...creds,
      tabeFinalCheckedList: currentTables,
      [event.target.name]: event.target.checked,
    });
  };

  const addSection = () => {
    if (!sameNaming) {
      const newTables = creds.tabeFinalCheckedList.map((curr) => curr.id);
      if (infoSection) {
        dispatch(updateSectionRequest(creds.name, currentSelectedTable, newTables, closeModal));
      } else {
        dispatch(addSectionRequest(creds.name, newTables, closeModal));
      }
    } else {
      const newTables = creds.tabeFinalCheckedList.map((curr) => curr.id);

      dispatch(addSectionRequest(creds.name, newTables, closeModal));
    }
  };

  return (
    <>
      <div className={style.wrapper}>
        <div className={style.subTitleContainer}>
          <div className={style.subTitle}>Название секции</div>
          <Input
            onChange={(e) => handleChange(e)}
            isBorder
            value={creds?.name}
            placeholder=""
            name="name"
            onClear={() => {
              setCreds((prev) => ({
                ...prev,
                tabeFinalCheckedList: currentTables,
                name: '',
              }));
            }}
            className={style.input}
            autoComplete="off"
            clearIconTop={21.5}
          />
        </div>
        {sameNaming && <div className={style.errorMessage}>Такое название секции уже есть</div>}
        <div>
          <div className={style.subTitle}>Объекты</div>
          <div className={style.tableSectionWrapper}>
            <div className={style.tableSectionRow}>
              {infoSection &&
                infoSection.desks.map((current) => {
                  return (
                    <TableSectionTableIcon
                      key={current.id}
                      isBordered={currentTables.includes(current)}
                      onCLick={addNewTablesForSection}
                      current={current}
                    />
                  );
                })}
              {tables.map((current) => {
                return (
                  <TableSectionTableIcon
                    key={current.id}
                    isBordered={currentTables.includes(current)}
                    onCLick={addNewTablesForSection}
                    current={current}
                  />
                );
              })}
            </div>
          </div>
          <div className={style.checbkoxItem}>
            <input
              type="checkbox"
              id="checkbox"
              checked={creds.checked}
              name="checked"
              onChange={handleAddAllTables}
            />
            <label htmlFor="checkbox">Выделить все</label>
          </div>
        </div>
      </div>
      <div className={style.buttonsWrapper}>
        <div
          className={`${style.buttonContent}`}
          onClick={
            isReadyForSubmit ? addSection : () => dispatch(addAlert('Введите название секции'))
          }
        >
          <button type="button" className={`${style.saveButton}`}>
            Сохранить
          </button>
        </div>
        <div className={style.buttonContent}>
          <button onClick={closeModal} type="button" className={style.backButton}>
            Отмена
          </button>
        </div>
      </div>
    </>
  );
};

export default React.memo(AddNewSection);
