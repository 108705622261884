import { configureStore } from '@reduxjs/toolkit';
import sessionReducer from './actions/session';
import profileReducer from './actions/profile';
import organisationReducer from './actions/organisation';
import qrcodeReducer from './actions/qrcodes';
import newQrcodeReducer from './actions/newQrcode';
import transactionsReducer from './actions/transactions';
import feedbacksReducer from './actions/feedbacks';
import settingReducer from './actions/setting';
import menuSettingReducer from './actions/settingMenu';
import messageToUserReducer from './actions/messageToUser';
import errorReducer from './actions/messageToUser';
import notificationsReducer from './actions/notifications';
import supportReducer from './actions/support';
import loyaltyReducer from './actions/loyalty';
import clubcardReducer from './actions/clubcard';
import groupsSettingReducer from './actions/groupsSettings';
import clubcardFormsReducer from './actions/clubcardForms';
import shiftsReducer from './actions/shifts';
import preloaderReducer from './actions/preloader';
import modalReducer from './actions/modal';
import tableReducer from './actions/tables';
import bookingReducer from './actions/booking';
import bonusesReducer from './actions/bonuses';
import managementReducer from './actions/management';
import statisticsReducer from './actions/statistics';
import constrtuctorReducer from './actions/constructor';
import inquiryReducer from './actions/inquiry';
import integrationRkeeperReducer from './actions/integrationRkeeper';
import ordersReducer from './actions/orders';
import alertsReducer from './actions/alerts';

export default configureStore({
  reducer: {
    modal: modalReducer,
    session: sessionReducer,
    profile: profileReducer,
    organisation: organisationReducer,
    qrcode: qrcodeReducer,
    newQrcode: newQrcodeReducer,
    transactions: transactionsReducer,
    feedbacks: feedbacksReducer,
    setting: settingReducer,
    menuSetting: menuSettingReducer,
    messageToUser: messageToUserReducer,
    error: errorReducer,
    notifications: notificationsReducer,
    support: supportReducer,
    loyalty: loyaltyReducer,
    clubcard: clubcardReducer,
    groupsSetting: groupsSettingReducer,
    clubcardForms: clubcardFormsReducer,
    shifts: shiftsReducer,
    preloader: preloaderReducer,
    tables: tableReducer,
    booking: bookingReducer,
    bonuses: bonusesReducer,
    management: managementReducer,
    statistics: statisticsReducer,
    constructorCreator: constrtuctorReducer,
    inquiry: inquiryReducer,
    integrationRkeeper: integrationRkeeperReducer,
    orders: ordersReducer,
    alerts: alertsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
