import get from 'lodash.get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setModal } from '../../../../../../redux/actions/modal';
import { getCurrentParamsForModalRedirection } from '../../../../../../redux/selectors/modal';
import styles from './redirectionModals.module.css';

const RedirectionModals = () => {
  const params = useSelector(getCurrentParamsForModalRedirection);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleRedirect = () => {
    if (params.route !== '') {
      history.push(params.route);
    }
    dispatch(setModal(false));
  };
  return (
    <div className={styles.modalContainer}>
      <h2 className={styles.modalTitle}>{get(params, 'title', '')}</h2>
      <div className={styles.modalBody}>{get(params, 'description', '')}</div>
      <div className={styles.modalButtons}>
        <div className={styles.modalButton}>
          <button
            onClick={() => dispatch(setModal(false))}
            type="button"
            className={styles.outlinedButton}
          >
            Назад
          </button>
        </div>
        <div onClick={handleRedirect} className={styles.modalButton}>
          <button type="button">Перейти и настроить</button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(RedirectionModals);
