import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import s from './Confirm.module.css';
import Button from '../Button';
import ButtonCancel from '../ButtonCancel';
import { FieldError } from '../../../components/MessageToUser/MessageToUser';

const Confirm = ({ title, paragraphs, onConfirm, onConfirmTitle, onCancel }) => {
  const serverError = useSelector((state) => state.messageToUser.errorMessage);
  return (
    <div className={s.wrapper}>
      {title && <div className={`${s.title} ${s.field}`}>{title}</div>}
      {paragraphs.map((paragraph, idx) => (
        <div key={idx} className={`${s.message} ${s.field}`}>
          {paragraph}
        </div>
      ))}
      <div className={`${s.btns} ${s.confirm}`}>
        <Button className={s.btnConfirm} onClick={onConfirm} title={onConfirmTitle} isDanger />
        <ButtonCancel onClick={onCancel} />
      </div>
      {serverError.type === 'confirm' && <FieldError message={serverError.message} y={3} />}
    </div>
  );
};

export default React.memo(Confirm);

Confirm.propTypes = {
  title: PropTypes.string,
  paragraphs: PropTypes.arrayOf(PropTypes.string),
  onConfirm: PropTypes.func.isRequired,
  onConfirmTitle: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
};

Confirm.defaultProps = {
  title: 'Подтверждение',
  paragraphs: [
    'Если вы закроете окно, все несохраненные данные будут утеряны.',
    'Вы уверены, что хотите закрыть окно?',
  ],
  onConfirmTitle: 'Закрыть',
};
