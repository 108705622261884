import React, { memo } from 'react';
import styles from './ConstructoButtonWidgets.module.css';

const RadioPickerWidget = ({ title, items = [], active, setActive }) => {
  return (
    <div className={`grayContainer ${styles.subIContent}`}>
      <h2 className="plainGrayText">{title}</h2>
      <div className={styles.themeItems}>
        {!!items?.length &&
          items.map((item) => (
            <button
              type="button"
              onClick={() => setActive(item)}
              className={`${item.value === active ? styles.active : ''} ${styles.themeItem}`}
            >
              {item.label}
              <span />
            </button>
          ))}
      </div>
    </div>
  );
};

export default memo(RadioPickerWidget);
