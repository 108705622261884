import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { PlusIcon } from '../../../../../assets/Icons/plusIcon';
import MinusIcon from '../../../../../assets/Icons/minusIcon';
import style from './addTable.module.css';
import { addUnsortedTables, setSteps } from '../../../../../redux/actions/tables';

const AddTable = ({ closeModal }) => {
  const [count, setCount] = useState(0);

  const dispatch = useDispatch();
  const handleIncrease = () => {
    setCount((prev) => {
      if (prev < 99) {
        return prev + 1;
      }
      return prev;
    });
  };
  const handleDecrease = () => {
    setCount((prev) => {
      if (prev > 0) {
        return prev - 1;
      }
      return prev;
    });
  };

  const handleUserKeyPress = (e) => {
    if (e.key === '+' || e.keyCode === 39) {
      handleIncrease();
    } else if (e.key === '-' || e.keyCode === 37) {
      handleDecrease();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, []);

  const handleAdd = () => {
    dispatch(setSteps([1]));

    dispatch(addUnsortedTables(count, closeModal));
  };

  const handleChange = (e) => {
    if (+e.target.value <= 99) {
      setCount(+e.target.value);
    }
  };

  return (
    <div>
      <div className={style.container}>
        <div className={style.text}>Укажите фактическое количество столов</div>
        <div className={style.counterWrapper}>
          <button
            type="button"
            className={`${!count ? style.counterDisabled : style.counter}`}
            onClick={handleDecrease}
          >
            <MinusIcon />
          </button>
          <input className={style.count} value={count} onChange={handleChange} />
          <button
            type="button"
            className={`${count === 99 ? style.counterDisabled : style.counter}`}
            onClick={handleIncrease}
          >
            <PlusIcon />
          </button>
        </div>
      </div>
      <div className={style.description}>
        Столы будут добавлены в резерв до распределения по залами и пронумерованы по порядку. Вы
        сможете настроить их позже.
      </div>
      <div className={style.buttonsWrapper}>
        <div className={style.buttonContent}>
          <button onClick={closeModal} type="button" className={style.backButton}>
            Отмена
          </button>
        </div>
        <div className={style.buttonContent}>
          <button
            type="button"
            onClick={handleAdd}
            disabled={!count}
            className={`${style.saveButton} ${!count ? style.buttonDisabled : ''}`}
          >
            Сохранить
          </button>
        </div>
      </div>
    </div>
  );
};

AddTable.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default memo(AddTable);
