import { useSelector } from 'react-redux';
import React from 'react';
import s from './UnsuccessModal.module.css';
import { getМessegeToUserOperationResultMessageSelector } from '../../../../../redux/selectors/messageToUser';
import Button from '../../../atoms/Button';
import { actionKeyDown } from '../../../../../utils/actionKeyDown';

const UnSuccessModal = ({ closeModal }) => {
  const message = useSelector(getМessegeToUserOperationResultMessageSelector);
  actionKeyDown(closeModal);

  return (
    <div onClick={closeModal}>
      <div className={s.text}>{message}</div>
      <Button title="OK" className={s.button} />
    </div>
  );
};

export default React.memo(UnSuccessModal);
